import { AuthState } from "./auth/state";
import { ActivityState } from "./activity/state";
import { CampusesState } from "./campuses/state";
import { ConfirmRegistrationState } from "./confirmRegistration/state";
import { CoursesState } from "./courses/state";
import { CreditsState } from "./credits/state";
import { LocationsState } from "./locations/state";
import { LoginState } from "./login/state";
import { MyJewishUState } from "./myJewishU/state";
import { ProfileState } from "./profile/state";
import { RecoverPassState } from "./recoverPass/state";
import { RegistrationState } from "./registration/state";
import { ResendConfirmationState } from "./resendRegistrationConfirmation/state";
import { ResetPassState } from "./resetPass/state";
import { SysState } from "./sys/state";
import { UIState } from "./ui/state";

export * from "./activity/actions";
export * from "./auth/actions";
export * from "./campuses/actions";
export * from "./courses/actions";
export * from "./credits/actions";
export * from "./locations/actions";
export * from "./myJewishU/actions";
export * from "./profile/actions";
export * from "./recoverPass/actions";
export * from "./resendRegistrationConfirmation/actions";
export * from "./resetPass/actions";
export * from "./sys/actions";

export * from "./auth/selectors";
export * from "./credits/selectors";
export * from "./myJewishU/selectors";

export default [
  ActivityState,
  AuthState,
  CampusesState,
  ConfirmRegistrationState,
  CoursesState,
  CreditsState,
  LocationsState,
  LoginState,
  MyJewishUState,
  ProfileState,
  RecoverPassState,
  RegistrationState,
  ResendConfirmationState,
  ResetPassState,
  SysState,
  UIState,
];
