import React from "react";
import { Navigation } from "../../../../lib";

export default ({ courseDetails: { categoryDisplay, imageURL, name } }) => (
  <div className="course-details-header">
    <div className="course-details-header-img-container">
      <img src={imageURL} alt={name} />
    </div>
    <div className="relative container">
      <p
        className="medium-text fw-500 flex flex-align-center link-text-secondary mb-64"
        onClick={() => Navigation.goBack()}
      >
        <i className="material-icons mr-8">arrow_back</i>Go back
      </p>
      <div>
        <p className="course-category">{categoryDisplay}</p>
        <p className="course-title inline-block" dir="auto">
          {name}
        </p>
      </div>
    </div>
  </div>
);
