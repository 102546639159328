import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { LocationsActions } from "../../state";
import LocationDetailsPage from "./LocationDetailsPage";

export default connect(
  function mapState(state) {
    return {
      ...state.locations.location,
      courses: state.locations.locationCourses,
      myJewishU: state.myJewishU,
      sys: state.sys,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...LocationsActions,
        },
        dispatch,
      ),
    };
  },
)(LocationDetailsPage);
