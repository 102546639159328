const newEmptyObject = {};
export const CreditsSelectors = {
  reward(state: any) {
    return state.credits.reward;
  },
  rewardRequest(state: any) {
    return state.credits.rewardRequest;
  },
  studentRewardData(state: any) {
    return state.credits.student?.data || newEmptyObject;
  },
  studentShluchimProfiles(state: any) {
    return state.credits.studentShluchimProfile || newEmptyObject;
  },
};
