import React from "react";
import ReactSelect from "react-select";

export default class Select extends React.Component {
  render() {
    const {
      autoComplete,
      className,
      clearable,
      defaultValue,
      disabled,
      getOptionLabel,
      menuIsOpen,
      name,
      noOptionsMessage,
      onChange,
      onInputChange,
      openMenuOnClick,
      options,
      placeholder,
      searchable,
      style,
      tabIndex,
      value,
    } = this.props;

    return (
      <div className={className || "custom-select"} style={style}>
        <ReactSelect
          autoComplete={autoComplete}
          classNamePrefix="input-container-select"
          getOptionLabel={getOptionLabel}
          isClearable={!!clearable}
          isDisabled={disabled}
          isSearchable={!!searchable}
          menuIsOpen={menuIsOpen}
          noOptionsMessage={noOptionsMessage}
          onChange={(selection) =>
            onChange(name, selection ? selection.value : "")
          }
          onInputChange={onInputChange}
          openMenuOnClick={openMenuOnClick}
          options={options}
          placeholder={placeholder}
          tabIndex={tabIndex}
          value={
            value && options
              ? options.find((o) => o.value === value)
              : defaultValue || null
          }
        />
      </div>
    );
  }
}
