import React from "react";
import { formatNumber, pluralizeText, PageLink } from "../../../../lib";
import Pages from "../../..";

export default class MyCourseCard extends React.PureComponent {
  render() {
    const {
      course: {
        categoryDisplay,
        courseID,
        courseName,
        courseScheduleID,
        courseSlug,
        creditsPerCourse,
        didClassesEnd,
        didCompleteAttendance,
        didCompleteSurvey,
        didSurveyDeadlinePass,
        eduCourseChabadHouseEnrollmentID,
        imageURL,
        levelDisplay,
        numOfClassesAttended,
        numOfClassesScheduled,
        studentCourseScheduleEnrollmentStatusDisplay,
      },
    } = this.props;

    const enrollmentStatusTag =
      studentCourseScheduleEnrollmentStatusDisplay && (
        <React.Fragment>
          <p
            className={`text-${studentCourseScheduleEnrollmentStatusDisplay.toLowerCase()}`}
          >
            {studentCourseScheduleEnrollmentStatusDisplay}
          </p>
          {studentCourseScheduleEnrollmentStatusDisplay === "Incomplete" && (
            <React.Fragment>
              {!didCompleteAttendance && (
                <p className="accent-text small-text mt-4">
                  Attendance Incomplete - {numOfClassesAttended || 0}/
                  {numOfClassesScheduled || 0} classes
                </p>
              )}
              {!didCompleteSurvey && (
                //TODO: Uncomment as part of COC-4404
                //&& !didSurveyDeadlinePass
                <p className="accent-text small-text mt-4">
                  Pending Review & Evaluation
                </p>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      );

    return (
      <div className="card my-course-card medium-text">
        <div className="gradient-img-container">
          <img src={imageURL} alt={courseName} />
        </div>
        <div className="my-course-card-info">
          <div className="flex flex-justify-space course-card-body">
            <div>
              <p className="uppercase-text small-text fw-500 mb-16">
                {categoryDisplay}
              </p>
              <p
                className="fw-600 xl-text mobile-mb-8 line-height-double"
                dir="auto"
              >
                {courseName}
              </p>
            </div>

            {enrollmentStatusTag && (
              <div className="text-right fw-500 mobile-hidden tablet-hidden">
                {enrollmentStatusTag}
              </div>
            )}
          </div>
          <div className="flex flex-align-center mt-32 course-card-footer tablet-block mobile-block">
            <div className="desktop-display-contents tablet-flex mobile-flex">
              {levelDisplay && (
                <div className="my-course-level flex flex-align-center">
                  <p className="accent-text">{levelDisplay}</p>
                  <div
                    className="ml-24 mr-24"
                    style={{
                      height: "22px",
                      width: "1px",
                      background: "rgba(0, 0, 0, .87)",
                    }}
                  />
                </div>
              )}
              <div className="my-course-credits flex flex-align-center">
                <p className="accent-text">
                  {formatNumber(creditsPerCourse)}{" "}
                  {pluralizeText("Credit", creditsPerCourse)}
                </p>
                <div
                  className="ml-24 mr-24 tablet-hidden mobile-hidden"
                  style={{
                    height: "22px",
                    width: "1px",
                    background: "rgba(0, 0, 0, .87)",
                  }}
                />
              </div>
            </div>
            <div className="my-course-details tablet-block mobile-block flex-justify-space mobile-mt-24">
              {enrollmentStatusTag && (
                <div className="desktop-hidden tablet-mt-8 mobile-mt-0 mr-40 fw-500">
                  {enrollmentStatusTag}
                </div>
              )}

              <PageLink
                className="link-text underline-text block tablet-mt-8 mobile-mt-0 mobile-ml-24"
                style={{ fontSize: "15px" }}
                to={Pages.courses.courseDetails}
                params={{ courseId: courseSlug || courseID }}
                query={{
                  loc: eduCourseChabadHouseEnrollmentID,
                  sched: courseScheduleID,
                }}
              >
                View course details
                {didClassesEnd &&
                  !didCompleteSurvey &&
                  //TODO: Uncomment as part of COC-4404
                  //!didSurveyDeadlinePass &&
                  " and complete review"}
              </PageLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
