import React from "react";
import { PageURL } from "../../../../lib";
import Pages from "../../../../pages";

export default class LocationPageRedirect extends React.PureComponent {
  componentDidMount() {
    const {
      pageRoute: { history, params },
    } = this.props;

    history.replace(PageURL.to(Pages.locations.locationDetails, params, null));
  }

  render() {
    return null;
  }
}
