import React from "react";
import ErrorDisplay from "./ErrorDisplay";
import Loader from "./Loader";
import { scrollUpToTargetRefAdjusted } from "../lib";

export default class PaginatedList extends React.PureComponent {
  componentDidMount() {
    this.props.getRecords();
  }

  getPaginationFooter() {
    const { loading, page, resultsView, totalCount } = this.props;

    let numberOfPages = Math.ceil((totalCount || 0) / resultsView);
    if (numberOfPages < 2) {
      return null;
    }

    let pagesArray = Array(numberOfPages)
      .fill()
      .map((_, i) => i + 1);
    if (pagesArray.length > 10) {
      let pagesArrayCopy = pagesArray.slice(0, pagesArray.length);
      let startPg = pagesArrayCopy.shift();
      let endPg = pagesArrayCopy.pop();
      if (page < 7 && page !== 6) {
        pagesArray = pagesArray.slice(0, 6);
        pagesArray.push("afterPages", endPg);
      } else if (page === 6) {
        pagesArray = pagesArray.slice(page - 3, page + 2);
        pagesArray.unshift(startPg, "beforePages");
        pagesArray.push("afterPages", endPg);
      } else if (page + 4 < endPg) {
        pagesArray = pagesArray.slice(page - 3, page + 2);
        pagesArray.unshift(startPg, "beforePages");
        pagesArray.push("afterPages", endPg);
      } else {
        pagesArray = pagesArray.slice(pagesArray.length - 6, pagesArray.length);
        pagesArray.unshift(startPg, "beforePages");
      }
    } else {
      pagesArray = pagesArray.slice(0, 10);
    }

    return (
      <div className="flex flex-justify-space flex-align-center pagination-footer">
        <p className="accent-text">{totalCount} Results</p>
        <ul className={`pagination ${loading ? "disabled" : ""}`}>
          {page > 1 ? (
            <li
              key="prev"
              className="pagination-arrow"
              onClick={() => this.paginate(page - 1)}
            >
              <i className="material-icons">keyboard_arrow_left</i>
            </li>
          ) : null}

          {pagesArray.map((pageNumber) =>
            typeof pageNumber === "number" ? (
              <li
                key={pageNumber}
                className={
                  page.toString() === pageNumber.toString() ? "active" : ""
                }
                onClick={() => this.paginate(pageNumber)}
              >
                {pageNumber}
              </li>
            ) : (
              <li key={pageNumber}>...</li>
            ),
          )}

          {page === numberOfPages ? null : (
            <li
              key="next"
              className="pagination-arrow"
              onClick={() => this.paginate(page + 1)}
            >
              <i className="material-icons">keyboard_arrow_right</i>
            </li>
          )}
        </ul>
      </div>
    );
  }

  paginate(pageNumber) {
    const { getRecords, scrollToRef } = this.props;

    getRecords(pageNumber);

    //if scrollToRef is specified, scroll to ref, otherwise scroll to top of page
    if (scrollToRef) {
      scrollUpToTargetRefAdjusted(scrollToRef);
    } else {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      className,
      error,
      loaderClass,
      loading,
      name,
      noRecordsView,
      records,
      renderRecord,
      renderRecords,
    } = this.props;

    return (
      <div>
        {loading ? (
          <div style={{ minHeight: "320px" }}>
            <Loader className={loaderClass} />
          </div>
        ) : error || !records ? (
          <ErrorDisplay />
        ) : !records.length ? (
          noRecordsView || (
            <div className="flex flex-justify-center flex-align-center">
              <div>
                <img
                  src="/images/no_results.svg"
                  alt="no results"
                  height="240"
                  style={{ maxWidth: "100%" }}
                />
                <p className="xxl-text mt-24 mb-16 text-center fw-500">
                  No {name} found
                </p>
                <p className="medium-text accent-text text-center">
                  Try adjusting your filters
                </p>
              </div>
            </div>
          )
        ) : (
          <div className={className}>
            {renderRecords ? renderRecords(records) : records.map(renderRecord)}
            {this.getPaginationFooter()}
          </div>
        )}
      </div>
    );
  }
}
