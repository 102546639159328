import Profile from "./Profile";

export const ProfilePages = {
  studentProfile: {
    anon: false,
    path: "/profile",
    title: "My Profile",
    type: "PAGE_PROFILE",
    view: Profile,
  },
};
export default ProfilePages;

export const ProfileArea = {
  pages: ProfilePages,
};
