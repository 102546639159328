import React from "react";
import { Prompt } from "react-router-dom";
import ConfirmationModal from "../../../components/ConfirmationModal";
import ErrorDisplay from "../../../components/ErrorDisplay";
import FadeOutMessage from "../../../components/FadeOutMessage";
import Loader from "../../../components/Loader";

import AddressInfo from "./AddressInfo";
import CurrentEducationInfo from "./CurrentEducationInfo";
import FamilyBackgroundInfo from "./FamilyBackgroundInfo";
import JewishEducationInfo from "./JewishEducationInfo";
import PersonalInfo from "./PersonalInfo";
import { isIncompleteProfile } from "../../../lib";
import {
  getFormattedValuesForForm,
  handleErrorMessage,
  handlePreventDefault,
  Navigation,
  replaceValuesInObject,
  PageURL,
} from "../../../lib";
import Pages from "../../../pages";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";

export default class ProfileForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      initialProfile: null,
      navigating: false,
      profile: null,
      profileValidationErrors: [],
      showFormValidation: false,
      showProfileForm: !props.isCompleteProfilePrompt,
      showSubmitSuccessMessage: false,
      showUpdateEmailConfirmationModal: false,
      submitFormAttempted: false,
      updateEmailConfirmed: false,
    };
  }

  componentDidMount() {
    this.getUserProfile();
    this.props.actions.getCampuses();
  }

  getUserProfile = async () => {
    const { studentId } = this.props;
    const profile = await this.props.actions.getProfile(studentId);

    const {
      isCompleteProfilePrompt,
      onProfileFetched,
      onProfileIsComplete,
      profile: { success },
      pageRoute: {
        query: { isRedirectToCompleteProfile },
      },
    } = this.props;

    let profileIsComplete;
    if (success && profile) {
      const formattedProfile = getFormattedValuesForForm(profile, [
        "person.dob",
      ]);
      //default marketing checkboxes to true if not yet set
      if (profile.didConsentToMarketingContact === null) {
        formattedProfile.didConsentToMarketingContact = true;
      }
      if (profile.didConsentToMarketingQuotes === null) {
        formattedProfile.didConsentToMarketingQuotes = true;
      }
      this.setState(
        {
          initialProfile: formattedProfile,
          profile: _cloneDeep(formattedProfile),
        },
        () => {
          if (isCompleteProfilePrompt || isRedirectToCompleteProfile) {
            const { profile, profileValidationErrors } = this.state;
            if (isIncompleteProfile(profile, profileValidationErrors)) {
              this.setState({
                showFormValidation: true,
                showProfileForm: true,
              });
              profileIsComplete = false;
            } else {
              isCompleteProfilePrompt && onProfileIsComplete(); //hide complete profile modal
              profileIsComplete = true;
            }
          }
        },
      );
    }

    if (isCompleteProfilePrompt) {
      onProfileFetched(success && profile, profileIsComplete);
    }
  };

  onChangeProfileEvt = (event) => {
    this.onChangeProfile(event.target.name, event.target.value);
  };

  onChangeProfile = (name, value, other) => {
    this.setState((prevState) => {
      // bec multiple immed subsequent setStates can be called (ie. if form inputs call setState on mount), we're using a state updater func to ensure that updates are executed in the order they are called and previous state updates are not overridden
      // see https://duncanleung.com/avoiding-react-setstate-pitfalls/#solution-use-the-updater-code-classlanguage-textfunctioncode-form-to-queue-state-updates
      let profile = _cloneDeep(prevState.profile);
      _set(profile, name, value);

      if (other) {
        Object.keys(other).forEach((update) =>
          _set(profile, update, other[update]),
        );
      }

      return { profile };
    });
  };

  onSaveProfile = handlePreventDefault(() => {
    this.setState(
      {
        showFormValidation: true,
        submitFormAttempted: true,
        showSubmitSuccessMessage: false,
      },
      async () => {
        const { profile, profileValidationErrors } = this.state;
        if (isIncompleteProfile(profile, profileValidationErrors)) {
          return;
        }

        const { initialProfile, updateEmailConfirmed } = this.state;

        if (profile.email !== initialProfile.email && !updateEmailConfirmed) {
          this.setState({
            showUpdateEmailConfirmationModal: true,
          });
          return;
        }

        const profileForSubmission = _cloneDeep(profile);
        replaceValuesInObject(profileForSubmission, (val) => val === "", null);

        const submittedProfile = await this.props.actions.submitProfile(
          profileForSubmission,
        );

        const {
          isCompleteProfilePrompt,
          onProfileIsComplete,
          submitProfile: { success },
          pageRoute: {
            query: { isRedirectToCompleteProfile },
          },
        } = this.props;

        if (success && submittedProfile) {
          if (isCompleteProfilePrompt) {
            onProfileIsComplete(); //hide complete profile modal
          } else if (Navigation.query.afterprofile) {
            const jewishULocation =
              submittedProfile.myJewishU?.eduChabadHouse?.slug ||
              submittedProfile.myJewishU?.eduChabadHouse?.id;

            this.setState({ navigating: true }, () => {
              if (
                isRedirectToCompleteProfile &&
                jewishULocation &&
                Navigation.query.afterprofile === "/locations"
              ) {
                Navigation.redirect(
                  PageURL.to(
                    Pages.locations.locationDetails.path,
                    { locationId: jewishULocation },
                    null,
                  ),
                );
              } else {
                Navigation.go(Navigation.query.afterprofile);
              }
            });
          } else {
            const formattedProfile = getFormattedValuesForForm(
              submittedProfile,
              ["person.dob"],
            );
            this.setState({
              initialProfile: formattedProfile,
              profile: _cloneDeep(formattedProfile),
              showSubmitSuccessMessage: true,
              submitFormAttempted: false,
              updateEmailConfirmed: false,
            });
          }
        }
      },
    );
  });

  updateProfileValidation = (name, isValid) => {
    const { profileValidationErrors } = this.state;
    this.setState({
      profileValidationErrors: isValid
        ? profileValidationErrors.filter((err) => err !== name)
        : [...profileValidationErrors, name],
    });
  };

  render() {
    const {
      campuses,
      pageRoute: {
        query: { isRedirectToCompleteProfile, sched: courseScheduleId },
      } = {},
      isCompleteProfilePrompt,
      eduStudentEnrollmentID,
      profile: { loading, error },
      studentId,
      submitProfile: {
        loading: submitProfileLoading,
        error: submitProfileError,
      },
      sys: {
        countries = [],
        hebrewLevels = [],
        jewishAffiliationTypes = [],
        jewishBackgroundTypes = [],
        jewishEducationTypes = [],
        majors = [],
        studentClasses = [],
      },
    } = this.props;

    const {
      initialProfile,
      navigating,
      profile,
      profileValidationErrors,
      showFormValidation,
      showProfileForm,
      showSubmitSuccessMessage,
      showUpdateEmailConfirmationModal,
      submitFormAttempted,
    } = this.state;

    const isNewEnrollmentForJU = courseScheduleId && !eduStudentEnrollmentID;

    return !showProfileForm ? (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 100,
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div>
          <Loader />
          <p className="mt-16 text-center accent-text">
            Verifying Profile Status...
          </p>
        </div>
      </div>
    ) : (
      <React.Fragment>
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : error ? (
          <ErrorDisplay />
        ) : (
          !!(profile && studentId) && ( //showing profile form conditioned on studentID present to ensure that on logout 'save changes' is not prompted
            <div className="relative">
              <Prompt
                when={!navigating && !_isEqual(profile, initialProfile)}
                message="Are you sure you want to leave this page before submitting?  Your changes will be lost."
              />
              <div className="flex flex-align-center flex-justify-space mb-24 profile-form-header">
                <p className="xxl-text fw-700">
                  {isCompleteProfilePrompt ||
                  (isRedirectToCompleteProfile && isNewEnrollmentForJU)
                    ? "Almost there!"
                    : "My Profile"}
                  {(isCompleteProfilePrompt || isRedirectToCompleteProfile) && (
                    <span
                      className="medium-text block fw-400 mt-16 line-height-double"
                      style={{ gridColumnStart: "span 2" }}
                    >
                      {isNewEnrollmentForJU
                        ? "Take a moment to complete your Chabad on Campus profile, and then you’ll be ready for a 60-second interactive JewishU application."
                        : "Take a moment to complete your Chabad on Campus profile! You can update your profile at any time."}
                    </span>
                  )}
                </p>
                <div className="save-btn-container relative flex flex-align-center mobile-hidden">
                  {!submitProfileLoading ? (
                    <React.Fragment>
                      <button
                        className="btn btn-large btn-accent"
                        disabled={_isEqual(initialProfile, profile)}
                        onClick={this.onSaveProfile}
                        style={{ width: "180px" }}
                        type="submit"
                      >
                        Save
                      </button>

                      <FadeOutMessage
                        className="success-text"
                        message={
                          showSubmitSuccessMessage
                            ? "Changes have been saved"
                            : ""
                        }
                        onTimeout={() =>
                          this.setState({ showSubmitSuccessMessage: false })
                        }
                        timeout={20000}
                      />

                      <span className="error-message">
                        {submitFormAttempted &&
                          (showFormValidation &&
                          isIncompleteProfile(profile, profileValidationErrors)
                            ? "Please complete required/incomplete fields"
                            : !!submitProfileError
                            ? handleErrorMessage(
                                submitProfileError,
                                "There was an error saving your profile. Please try again.",
                              )
                            : "")}
                      </span>
                    </React.Fragment>
                  ) : (
                    <div>
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
              <form className="profile-form">
                <PersonalInfo
                  countries={countries}
                  onChangeProfile={this.onChangeProfile}
                  onChangeProfileEvt={this.onChangeProfileEvt}
                  profile={profile}
                  profileValidationErrors={profileValidationErrors}
                  showFormValidation={showFormValidation}
                  updateProfileValidation={this.updateProfileValidation}
                  initialProfile={initialProfile}
                />

                <AddressInfo
                  countries={countries}
                  onChangeProfile={this.onChangeProfile}
                  onChangeProfileEvt={this.onChangeProfileEvt}
                  profile={profile}
                  showFormValidation={showFormValidation}
                />

                <CurrentEducationInfo
                  campuses={campuses}
                  majors={majors}
                  onChangeProfile={this.onChangeProfile}
                  onChangeProfileEvt={this.onChangeProfileEvt}
                  profile={profile}
                  showFormValidation={showFormValidation}
                  studentClasses={studentClasses}
                />

                <FamilyBackgroundInfo
                  jewishBackgroundTypes={jewishBackgroundTypes}
                  onChangeProfile={this.onChangeProfile}
                  profile={profile}
                  showFormValidation={showFormValidation}
                />

                <JewishEducationInfo
                  hebrewLevels={hebrewLevels}
                  jewishAffiliationTypes={jewishAffiliationTypes}
                  jewishEducationTypes={jewishEducationTypes}
                  onChangeProfile={this.onChangeProfile}
                  onChangeProfileEvt={this.onChangeProfileEvt}
                  profile={profile}
                  showFormValidation={showFormValidation}
                />
              </form>
              <div
                className="mobile-save-btn-container relative flex flex-align-center desktop-hidden tablet-hidden"
                style={{ marginTop: "-16px" }}
              >
                {!submitProfileLoading ? (
                  <React.Fragment>
                    <button
                      className="btn btn-large btn-accent full-width"
                      disabled={_isEqual(initialProfile, profile)}
                      onClick={this.onSaveProfile}
                      type="submit"
                    >
                      Save
                    </button>

                    <FadeOutMessage
                      className="success-text full-width text-center"
                      message={
                        showSubmitSuccessMessage
                          ? "Changes have been saved"
                          : ""
                      }
                      onTimeout={() =>
                        this.setState({ showSubmitSuccessMessage: false })
                      }
                      timeout={20000}
                    />

                    <span
                      className="error-message"
                      style={{
                        width: "100%",
                        textAlign: "center",
                        bottom: "-16px",
                      }}
                    >
                      {submitFormAttempted &&
                        (showFormValidation &&
                        isIncompleteProfile(profile, profileValidationErrors)
                          ? "Please complete required/incomplete fields"
                          : !!submitProfileError
                          ? handleErrorMessage(
                              submitProfileError,
                              "There was an error saving your profile. Please try again.",
                            )
                          : "")}
                    </span>
                  </React.Fragment>
                ) : (
                  <div className="full-width mt-24">
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          )
        )}

        <ConfirmationModal
          cancel={() =>
            this.setState({ showUpdateEmailConfirmationModal: false })
          }
          confirm={() => {
            this.setState(
              {
                showUpdateEmailConfirmationModal: false,
                updateEmailConfirmed: true,
              },
              this.onSaveProfile,
            );
          }}
          message="You changed your email address. This will change the email used for login. Are you sure you want to make this change?"
          show={showUpdateEmailConfirmationModal}
          title="Confirm Email Change"
        />
      </React.Fragment>
    );
  }
}
