import React, { forwardRef } from "react";
import { TextField, Typography } from "@mui/material";

export const TextInput = React.memo(
  forwardRef(function TextInput(
    {
      fullWidth = true,
      id,
      inputPrefix,
      InputProps,
      label,
      name,
      size = "small",
      ...passProps
    },
    ref,
  ) {
    return (
      <TextField
        fullWidth={fullWidth}
        id={id || name}
        label={label}
        name={name}
        ref={ref}
        size={size}
        InputProps={{
          startAdornment: inputPrefix ? (
            <Typography color="primary" style={{ marginRight: "8px" }}>
              {inputPrefix}
            </Typography>
          ) : null,
          ...InputProps,
        }}
        {...passProps}
      />
    );
  }),
);
