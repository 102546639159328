import Contact from "./Contact";

export const ContactPages = {
  contact: {
    anon: true,
    path: "/contact",
    title: "Contact",
    type: "PAGE_CONTACT",
    view: Contact,
  },
};
export default ContactPages;

export const ContactArea = {
  pages: ContactPages,
};
