import { LocationsActions } from "./actions";
import { AuthActions } from "../auth/actions";

export const LocationsState = {
  name: "locations",
  persist: false,
  defaults: {
    allLocations: {
      data: [],
      error: undefined,
      loading: false,
      success: undefined,
    },
    locations: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    locationCampuses: {
      data: [],
      error: undefined,
      loading: false,
      success: undefined,
    },
    location: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    locationCourses: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
  },
  handlers: {
    [LocationsActions.SET_GET_ALL_LOCATIONS_LOADING]: function (state, action) {
      return {
        ...state,
        allLocations: {
          ...state.allLocations,
          loading: !!action.payload,
        },
      };
    },
    [LocationsActions.GET_ALL_LOCATIONS_COMPLETE]: function (state, action) {
      return {
        ...state,
        allLocations: {
          ...state.allLocations,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [LocationsActions.GET_ALL_LOCATIONS_ERROR]: function (state, action) {
      return {
        ...state,
        allLocations: {
          ...state.allLocations,
          loading: false,
          success: false,
          error: action.payload,
          data: [],
        },
      };
    },
    [LocationsActions.RESET_ALL_LOCATIONS]: function (state, action) {
      return {
        ...state,
        allLocations: LocationsState.defaults.allLocations,
      };
    },

    [LocationsActions.SET_GET_LOCATIONS_LOADING]: function (state, action) {
      return {
        ...state,
        locations: {
          ...state.locations,
          loading: !!action.payload,
        },
      };
    },
    [LocationsActions.GET_LOCATIONS_COMPLETE]: function (state, action) {
      return {
        ...state,
        locations: {
          ...state.locations,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [LocationsActions.GET_LOCATIONS_ERROR]: function (state, action) {
      return {
        ...state,
        locations: {
          ...state.locations,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },
    [LocationsActions.RESET_LOCATIONS]: function (state, action) {
      return {
        ...state,
        locations: LocationsState.defaults.locations,
      };
    },

    [LocationsActions.SET_GET_LOCATION_CAMPUSES_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        locationCampuses: {
          ...state.locationCampuses,
          loading: !!action.payload,
        },
      };
    },
    [LocationsActions.GET_LOCATION_CAMPUSES_COMPLETE]: function (
      state,
      action,
    ) {
      return {
        ...state,
        locationCampuses: {
          ...state.locationCampuses,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [LocationsActions.GET_LOCATION_CAMPUSES_ERROR]: function (state, action) {
      return {
        ...state,
        locationCampuses: {
          ...state.locationCampuses,
          loading: false,
          success: false,
          error: action.payload,
          data: [],
        },
      };
    },
    [LocationsActions.RESET_LOCATION_CAMPUSES]: function (state, action) {
      return {
        ...state,
        locationCampuses: LocationsState.defaults.locationCampuses,
      };
    },

    [LocationsActions.SET_GET_LOCATION_LOADING]: function (state, action) {
      return {
        ...state,
        location: {
          ...state.location,
          loading: !!action.payload,
        },
      };
    },
    [LocationsActions.GET_LOCATION_COMPLETE]: function (state, action) {
      return {
        ...state,
        location: {
          ...state.location,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [LocationsActions.GET_LOCATION_ERROR]: function (state, action) {
      return {
        ...state,
        location: {
          ...state.location,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },
    [LocationsActions.RESET_LOCATION]: function (state, action) {
      return {
        ...state,
        location: LocationsState.defaults.location,
      };
    },

    [LocationsActions.SET_GET_LOCATION_COURSES_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        locationCourses: {
          ...state.locationCourses,
          loading: !!action.payload,
        },
      };
    },
    [LocationsActions.GET_LOCATION_COURSES_COMPLETE]: function (state, action) {
      return {
        ...state,
        locationCourses: {
          ...state.locationCourses,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [LocationsActions.GET_LOCATION_COURSES_ERROR]: function (state, action) {
      return {
        ...state,
        locationCourses: {
          ...state.locationCourses,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },
    [LocationsActions.RESET_LOCATION_COURSES]: function (state, action) {
      return {
        ...state,
        locationCourses: LocationsState.defaults.locationCourses,
      };
    },
    [AuthActions.DO_SIGNOUT]: function (state, action) {
      return LocationsState.defaults;
    },
  },
};
