export function frontChatInit(userEmail, userEmailHash) {
  if (window.FrontChat) {
    // we are storing front chat identity in session storage in order to avoid shutdown & clearing cookies when page is refreshed without an identity change, so unidentified conversations are not lost on refresh
    const frontChatIdentityKey = "front-chat-identification";
    const frontChatIdentity = userEmailHash || "not-identified";

    // shut down previous chat instance and clear cookies if identity has changed
    if (sessionStorage.getItem(frontChatIdentityKey) !== frontChatIdentity) {
      window.FrontChat("shutdown");
      removeCookiesOnShutdown();
    }

    // re-initialize front chat with/without user identity
    window.FrontChat("init", {
      chatId: "af168618cf86ff2337ce1bfca70f453a",
      email: userEmail,
      userHash: userEmailHash,
    });
    sessionStorage.setItem(frontChatIdentityKey, frontChatIdentity);
  }
}

// Per frontchat support cookies need to be manually removed when calling shutdown in order to properly clear user identity (there is an open feature request to clear cookies on 'shutdown')
function removeCookiesOnShutdown() {
  const frontChatCookies = ["fccid", "fcuid", "fcaid"];
  document.cookie
    .split(";")
    .filter((c) => frontChatCookies.some((fcc) => c.trim().indexOf(fcc) === 0))
    .forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
}
