import React from "react";
import { PageNavLink } from "../../../lib";
import Pages from "../../../pages";

export default ({ pageLinkQuery }) => (
  <div className="tabs">
    <PageNavLink
      to={Pages.account.login}
      query={pageLinkQuery}
      className="tab"
      activeClassName="active"
    >
      Log In
    </PageNavLink>
    <PageNavLink
      to={Pages.account.register}
      query={pageLinkQuery}
      className="tab"
      activeClassName="active"
    >
      Create Account
    </PageNavLink>
  </div>
);
