import { AuthRequest, Navigation } from "../../lib";
import querySerializer from "query-string";

export const ResendConfirmationActions = {
  RESEND_CONFIRMATION_ERROR: "RESEND_CONFIRMATION_ERROR",
  RESEND_CONFIRMATION_COMPLETE: "RESEND_CONFIRMATION_COMPLETE",
  SET_RESEND_CONFIRMATION_LOADING: "SET_RESEND_CONFIRMATION_LOADING",

  doResendRegistrationConfirmation(accountId, email, password) {
    return async function doingResendRegistrationConfirmation(
      dispatch,
      getState,
    ) {
      dispatch(ResendConfirmationActions.setResendConfirmationLoading(true));
      const response = await AuthRequest.post("Account/ResendConfirmation", {
        accountId,
        clientPortal: "JewishU",
        clientRedirectQueryString: Navigation.query.after
          ? querySerializer.stringify({ after: Navigation.query.after })
          : null,
        email,
        password,
      }).catch((err) => {
        dispatch(ResendConfirmationActions.resendConfirmationError(err));
        return undefined;
      });
      if (!response) {
        return;
      }
      dispatch(ResendConfirmationActions.resendConfirmationComplete());
    };
  },
  setResendConfirmationLoading(payload) {
    return {
      type: ResendConfirmationActions.SET_RESEND_CONFIRMATION_LOADING,
      payload,
    };
  },
  resendConfirmationComplete(payload) {
    return {
      type: ResendConfirmationActions.RESEND_CONFIRMATION_COMPLETE,
      payload,
    };
  },
  resendConfirmationError(payload) {
    return {
      type: ResendConfirmationActions.RESEND_CONFIRMATION_ERROR,
      payload,
    };
  },
};
