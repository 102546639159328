import axios from "axios";

const {
  /** The base URL for the API. */
  REACT_APP_API_URL,
} = process.env;

/** Token to apply to each request. */
let authToken;
let authExpirationDate;

/** Id of the interceptor used to apply auth headers. */
let authInterceptorId;
let resInterceptorId;
/** @type {() => Promise<void>} */
let authFailedHandler;

/** Axios instance to use for authenticated requests. */
export const AuthRequest = axios.create({
  baseURL: REACT_APP_API_URL,
});

/** Returns true if an auth token has been set and is not expired.
 * @returns {boolean}
 */
export function hasAuthRequestToken() {
  return !!authToken && authExpirationDate > new Date();
}

/** Assigns the token to be sent with each auth request.
 * @param {string} token Server token.
 * @param {string} expiration Date and Time in ISO 8601 format.
 */
export function setAuthRequestToken(token, expiration) {
  if (arguments.length < 2) {
    throw new Error("Token and expiration required.");
  }
  removeAuthRequestToken();
  if (token) {
    authToken = token;
    authExpirationDate = new Date(expiration);
    authInterceptorId = AuthRequest.interceptors.request.use(applyAuthHeaders);
    resInterceptorId = AuthRequest.interceptors.response.use(
      returnResponse,
      (err) => {
        let response = err.response;
        if (response && response.status === 401) {
          if (authFailedHandler) {
            return authFailedHandler(err);
          }
          // CONSIDER: To instead refresh the token and retry the request, do:
          // // Refresh
          // removeAuthRequestToken();
          // await refreshTokenMethod();
          // if (!authToken) {
          //   return Promise.reject(err);
          // }
          // // Retry
          // return AuthRequest(response.config);
        }
        return Promise.reject(err);
      },
    );
  }
}
/** Removes the token to be sent with each auth request. */
export function removeAuthRequestToken() {
  authToken = undefined;
  authExpirationDate = undefined;
  if (authInterceptorId !== undefined) {
    AuthRequest.interceptors.request.eject(authInterceptorId);
    authInterceptorId = undefined;
  }
  if (resInterceptorId !== undefined) {
    AuthRequest.interceptors.response.eject(resInterceptorId);
    resInterceptorId = undefined;
  }
}
/** @param {AxiosRequestConfig} config */
function applyAuthHeaders(config) {
  config.headers.Authorization = `Bearer ${authToken}`;
  return config;
}

function returnResponse(response) {
  return response;
}

/** @param {()=>Promise<void>} handler */
export function onAuthFailed(handler) {
  authFailedHandler = handler;
}

// #region Typedefs

/** @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig */

// #endregion
