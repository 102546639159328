import React from "react";
import { PageLink } from "../../../../lib";
import Pages from "../../../../pages";

export default () => (
  <div className="full-page-message flex flex-column flex-justify-center flex-align-center">
    <img src="/images/ju-trophy.svg" alt="" />
    <p className="large-text fw-700 mt-32 mb-32">
      Register for a course to get started with JewishU credits
    </p>
    <PageLink className="btn btn-accent" to={Pages.courses.courses}>
      Browse Courses
    </PageLink>
  </div>
);
