import { connect } from "react-redux";

import Home from "./Home";

export default connect(function mapState(state) {
  return {
    myJewishU: state.myJewishU,
    sys: state.sys,
    user: state.auth.person,
  };
})(Home);
