import React from "react";
import LocationCard from "./components/locations/LocationCard";
import PaginatedList from "../../components/PaginatedList";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";
import { Navigation, PageURL, PageLink } from "../../lib";
import Pages from "../../pages";

class LocationsListPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = this.getStateFromURL();
  }

  queryDefaults = {
    p: 1,
    r: 12,
  };

  getStateFromURL = () => {
    const {
      pageRoute: {
        query: { p, r },
      },
    } = this.props;

    return {
      page: p || this.queryDefaults.p,
      resultsView: r || this.queryDefaults.r,
    };
  };

  matchURLToState = () => {
    const {
      pageRoute: { page: routePage, params, query },
    } = this.props;
    const { page, resultsView } = this.state;

    Navigation.redirect(
      PageURL.to(routePage, params, {
        ...query,
        p: page && page !== this.queryDefaults.p ? page : undefined,
        r:
          resultsView && resultsView !== this.queryDefaults.r
            ? resultsView
            : undefined,
      }),
    );
  };

  getLocations = () => {
    const { filters, page, resultsView } = this.state;

    this.props.actions.getLocations(page, resultsView, filters);
  };

  paginateLocations = (page = this.state.page) => {
    this.setState({ page }, () => {
      this.matchURLToState();
      this.getLocations();
    });
  };

  render() {
    const {
      data: { results: locations = [], numberOfRows: totalLocations = 0 },
      error,
      loading,
      myJewishU: { data: { eduChabadHouse: myLocation } = {} },
    } = this.props;
    const { page, resultsView } = this.state;

    return (
      <div className="page">
        <div className="locations-header">
          <img src="/images/location-placeholder.jpg" alt="" />
          <div>
            <p className="locations-title mb-40">
              Ancient wisdom available near you
            </p>
            <p className="large-text line-height-double">
              Find your JewishU location below
              <br /> or{" "}
              <PageLink
                className="link-text-secondary large-text underline-text line-height-double"
                to={Pages.locations.locations}
              >
                click here
              </PageLink>{" "}
              to enter a University name to find a local JewishU
            </p>
          </div>
        </div>
        <div
          className="container locations-cards-grid-container"
          style={{ paddingBottom: 80 }}
        >
          <PaginatedList
            className="locations-cards-grid"
            error={error}
            getRecords={this.paginateLocations}
            loading={loading}
            loaderClass="locations-cards-grid-loader"
            name="locations"
            page={page}
            records={locations}
            renderRecord={(location, index) => (
              <LocationCard
                isMyLocation={myLocation && myLocation.id === location.id}
                key={index}
                location={location}
              />
            )}
            resultsView={resultsView}
            totalCount={totalLocations}
          />
        </div>
      </div>
    );
  }
}

export default withLayout()(withAppInsights(LocationsListPage));
