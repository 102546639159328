import React from "react";
import MyCourseCard from "./components/courses/MyCourseCard";
import NoEnrolledCourses from "./components/courses/NoEnrolledCourses";
import PaginatedList from "../../components/PaginatedList";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";
import { Navigation, PageURL, PageLink } from "../../lib";
import Pages from "../../pages";

class MyCoursesPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = this.getStateFromURL();
  }

  queryDefaults = {
    p: 1,
    r: 8,
  };

  getStateFromURL = () => {
    const {
      pageRoute: {
        query: { p, r },
      },
    } = this.props;

    return {
      page: p || this.queryDefaults.p,
      resultsView: r || this.queryDefaults.r,
    };
  };

  matchURLToState = () => {
    const {
      pageRoute: { page: routePage, params, query },
    } = this.props;
    const { page, resultsView } = this.state;

    Navigation.redirect(
      PageURL.to(routePage, params, {
        ...query,
        p: page && page !== this.queryDefaults.p ? page : undefined,
        r:
          resultsView && resultsView !== this.queryDefaults.r
            ? resultsView
            : undefined,
      }),
    );
  };

  getCourses = () => {
    const { page, resultsView } = this.state;

    this.props.actions.getCourses(page, resultsView, {}, true);
  };

  paginateCourses = (page = this.state.page) => {
    this.setState({ page }, () => {
      this.matchURLToState();
      this.getCourses();
    });
  };

  render() {
    const {
      data: { results: courses = [], numberOfRows: totalCourses = 0 },
      error,
      loading,
      myJewishU: {
        data: {
          eduStudentEnrollmentStatus: myJewishUStatus,
          eduChabadHouse: myLocation,
        } = {},
      },
      user,
    } = this.props;
    const { page, resultsView } = this.state;

    return (
      <div className="page container">
        <p className="fw-700 xxl-text mb-40">My courses</p>
        {!user ? (
          <div className="full-page-message flex flex-column flex-justify-center flex-align-center">
            <img src="/images/ju-award.svg" alt="" />
            <p className="medium-text fw-700 mt-32 mb-32">
              Log in to view My Courses
            </p>
            <PageLink
              className="btn btn-accent"
              style={{ width: "120px" }}
              to={Pages.account.login}
            >
              Log in
            </PageLink>
          </div>
        ) : myJewishUStatus === "Rejected" ? (
          <div className="text-center mt-32">
            <img src="/images/ju-award.svg" alt="" />
            <p className="medium-text fw-700 mt-32 mb-32">
              You are not registered for any courses
            </p>
          </div>
        ) : (
          <PaginatedList
            error={error}
            getRecords={this.paginateCourses}
            loading={loading}
            loaderClass="full-page-loader my-courses-loader"
            name="courses"
            page={page}
            noRecordsView={
              myLocation && <NoEnrolledCourses myLocation={myLocation} />
            }
            records={courses}
            renderRecord={(course, index) => (
              <MyCourseCard course={course} key={index} />
            )}
            resultsView={resultsView}
            totalCount={totalCourses}
          />
        )}
      </div>
    );
  }
}

export default withLayout()(withAppInsights(MyCoursesPage));
