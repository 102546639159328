import Credits from "./CreditsPage.vm";

export const CreditsPages = {
  credits: {
    anon: true,
    path: "/credits",
    title: "Credits",
    type: "PAGE_CREDITS",
    view: Credits,
  },
};
export default CreditsPages;

export const CreditsArea = {
  pages: CreditsPages,
};
