import React from "react";
import { PageLink } from "../../../../lib";
import Pages from "../../../../pages";

export default ({ myLocation }) => (
  <div className="full-page-message flex flex-column flex-justify-center flex-align-center">
    <img src="/images/ju-award.svg" alt="" />
    <p className="xl-text fw-600 mt-32 mb-16">
      Start learning from over 200 courses today
    </p>
    <p className="accent-text medium-text mb-32">
      When you register for a course, it will appear here
    </p>
    <PageLink
      to={Pages.locations.locationDetails}
      params={{ locationId: myLocation.slug || myLocation.id }}
    >
      Browse Courses
    </PageLink>
  </div>
);
