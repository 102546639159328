import React from "react";
import AccountPageWrapper from "./components/AccountPageWrapper.vm";
import Loader from "../../components/Loader";
import ValidatedInput from "../../components/ValidatedInput";

import {
  handleErrorMessage,
  handleInputState,
  handlePreventDefault,
  Navigation,
  validatePassword,
} from "../../lib";
import Pages from "../../pages";

export default class RecoverResetPass extends React.PureComponent {
  state = {
    confirmPassword: "",
    password: "",
    showFormValidation: false,
    submitFormAttempted: false,
  };

  isIncompleteResetPass = () => {
    const { confirmPassword, password } = this.state;

    return (
      !password ||
      !confirmPassword ||
      !validatePassword(password) ||
      confirmPassword !== password
    );
  };

  onChangeInput = handleInputState(this);

  onSubmit = handlePreventDefault(() => {
    this.setState(
      {
        showFormValidation: true,
        submitFormAttempted: true,
      },
      async () => {
        const {
          pageRoute: {
            query: { id, token },
          },
          actions: { doResetPass },
        } = this.props;

        if (this.isIncompleteResetPass()) {
          return;
        }

        const { password } = this.state;
        await doResetPass(password, id, token);

        if (!this.props.error) {
          Navigation.go(Pages.account.login.path, { resetPassRedirect: true });
        } else {
          this.setState({
            confirmPassword: "",
            password: "",
            showFormValidation: false,
          });
        }
      },
    );
  });

  render() {
    const { loading, error } = this.props;
    const {
      confirmPassword,
      password,
      showFormValidation,
      submitFormAttempted,
    } = this.state;

    return (
      <AccountPageWrapper>
        <h1 className="uppercase-text fw-600 xl-text text-center mb-40">
          Set Your Password
        </h1>
        <form className="account-form">
          <ValidatedInput
            label="Password"
            input={<input type="password" />}
            name="password"
            onChange={this.onChangeInput}
            required={true}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={(pw) => validatePassword(pw)}
            validationMessage="Password must be at least 8 characters long, and must contain an uppercase and lowercase letter and a digit."
            value={password}
          />
          <ValidatedInput
            input={<input type="password" />}
            label="Confirm Password"
            name="confirmPassword"
            onChange={this.onChangeInput}
            required={true}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={(pwConfirmation, pw) => pwConfirmation === pw}
            validationMessage="Passwords do not match"
            validationValue={password}
            value={confirmPassword}
          />

          {!loading ? (
            <React.Fragment>
              <button
                className="btn btn-large btn-fullWidth btn-accent mt-16"
                onClick={this.onSubmit}
                type="submit"
              >
                Set Password
              </button>

              <div className="error-message-container mt-4">
                <span className="error-message center">
                  {showFormValidation && this.isIncompleteResetPass()
                    ? "Please complete required/incomplete fields"
                    : !!error && submitFormAttempted
                    ? handleErrorMessage(error)
                    : ""}
                </span>
              </div>
            </React.Fragment>
          ) : (
            <div className="btn-large-loader btn-large mb-32">
              <Loader />
            </div>
          )}
        </form>
      </AccountPageWrapper>
    );
  }
}
