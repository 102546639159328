import React, { useEffect } from "react";
import FadeOutMessage from "../../../components/FadeOutMessage";
import Loader from "../../../components/Loader";
import QuestionnaireEnrollmentStep from "./QuestionnaireEnrollmentStep";

import {
  handleErrorMessage,
  PageLink,
  PageURL,
  Navigation,
} from "../../../lib";
import Pages from "../..";

export default function JewishUEnrollment(props: any) {
  const {
    enroll,
    myJewishU: {
      data: {
        eduChabadHouse = undefined,
        eduStudentEnrollmentID = undefined,
      } = {},
      enrollment: { loading = false, error = false, success = false } = {},
      loading: loadingMyJewishU,
    },
    pageRoute: { query: { sched: courseScheduleId = undefined } = {} },
  } = props;

  useEffect(() => {
    if (success && eduStudentEnrollmentID) {
      Navigation.redirect(Pages.myJewishU.enrollmentConfirmed.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, eduStudentEnrollmentID]);

  return (
    !loadingMyJewishU && (
      <div>
        {!eduChabadHouse ? (
          <div className="full-page-message flex flex-column flex-justify-center flex-align-center">
            <div className="text-center">
              <img src="/images/ju-award.svg" alt="" />
            </div>
            <p className="xl-text fw-700 mt-32 mb-32 text-center line-height-double">
              Your campus does not currently have a JewishU location
            </p>
            <p
              className="medium-text mb-16 text-center line-height-double"
              style={{ maxWidth: "600px" }}
            >
              You cannot enroll in JewishU at this time.{" "}
              <PageLink
                className="link-text"
                to={PageURL.to(Pages.profile.studentProfile.path, undefined, {
                  afterprofile: Navigation.locationURL,
                })}
              >
                View your profile
              </PageLink>{" "}
              to make sure that you've selected the correct campus. If your
              campus is correct, reach out to your local Chabad on Campus to
              find out about setting up JewishU courses on your campus. You can
              sign out to continue browsing publicly.
            </p>
          </div>
        ) : (
          //if not JU enrolled and enrolling in a course - show pop quiz instead of this page
          !eduStudentEnrollmentID &&
          courseScheduleId && (
            <div className="full-page-message flex flex-column flex-justify-center flex-align-center">
              <QuestionnaireEnrollmentStep
                enroll={enroll}
                courseId={courseScheduleId}
              />
              {loading ? (
                <div className="mt-24">
                  <Loader />
                </div>
              ) : (
                <React.Fragment>
                  {error && (
                    <FadeOutMessage
                      message={handleErrorMessage(
                        error,
                        "Sorry, something went wrong and your JewishU enrollment could not be completed.  Please try again.",
                      )}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          )
        )}
      </div>
    )
  );
}
