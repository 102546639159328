import React from "react";

export default class Modal extends React.Component {
  render() {
    const { children, className, renderChildren, show, style } = this.props;

    return (
      <div className={show ? "modal-container" : ""}>
        <div
          className={show ? `modal card ${className ? className : ""}` : ""}
          style={show ? style : {}}
        >
          {(renderChildren || show) && children}
        </div>
      </div>
    );
  }
}
