import React from "react";
import ReactCrop from "react-image-crop";
import Modal from "./Modal";

import "react-image-crop/lib/ReactCrop.scss";
import _cloneDeep from "lodash.clonedeep";

export default class ImageCropperModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      crop: {
        aspect: 1 / 1,
        unit: "%",
        width: 100,
      },
      croppedImageData: null,
    };
    state.initialState = _cloneDeep(state);
    this.state = state;
  }

  onClose = () => {
    this.props.submitCrop();
    this.setState(this.state.initialState);
  };

  onSave = () => {
    this.props.submitCrop(this.state.croppedImageData);
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageData = await this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImageData });
    }
  }

  getCroppedImg(imageRef, crop) {
    const scaleX = imageRef.naturalWidth / imageRef.width;
    const scaleY = imageRef.naturalHeight / imageRef.height;
    const tmpCanvas = document.createElement("canvas");
    tmpCanvas.width = crop.width * scaleX;
    tmpCanvas.height = crop.height * scaleY;

    const ctx = tmpCanvas.getContext("2d");
    ctx.drawImage(
      imageRef,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return new Promise((resolve, reject) => {
      tmpCanvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        resolve(blob);
        // alternatively can resolve with image url
        // blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        // this.fileUrl = window.URL.createObjectURL(blob);
        // resolve(this.fileUrl);
      }, "image/png");
    });
  }

  render() {
    const { imageName, imageURL, show } = this.props;
    const { crop } = this.state;

    return (
      <Modal className="small-modal" show={show}>
        <p className="large-text fw-700 mb-16">Crop {imageName || "Image"}</p>
        <div>
          {imageURL && (
            <ReactCrop
              crop={crop}
              crossorigin="anonymous"
              onChange={this.onCropChange}
              onComplete={this.onCropComplete}
              onImageLoaded={this.onImageLoaded}
              ruleOfThirds
              src={imageURL}
            />
          )}
        </div>
        <div className="modal-btns relative text-right">
          <p className="link-text uppercase-text" onClick={this.onClose}>
            Don't Crop
          </p>
          <p className="link-text uppercase-text ml-40" onClick={this.onSave}>
            Apply Crop
          </p>
        </div>
      </Modal>
    );
  }
}
