import React from "react";
import { PageNavLink } from "../../lib";

export default ({ className, dropdownMenu, title, to }) => (
  <li className={className || "nav-link"}>
    <PageNavLink activeClassName="active-nav-link" to={to || "/"}>
      {title}
      {!!dropdownMenu && (
        <span className="material-icons large-text ml-8">
          keyboard_arrow_down
        </span>
      )}
    </PageNavLink>
    {!!dropdownMenu && dropdownMenu}
  </li>
);
