import React from "react";
import { Navigation, PageLink } from "../../../lib";
import Pages from "../../../pages";

export default class AccountPageWrapper extends React.PureComponent {
  onContinueWithoutLogin = () => {
    const {
      actions: { doSignout },
      token,
    } = this.props;
    if (token) {
      //if there is a persisted auth token, call doSignout to ensure that expired auth (which would trigger an unauthorized response handler redirect) is cleared out
      doSignout();
    } else {
      Navigation.go(Pages.main.home.path);
    }
  };

  render() {
    const { logoLinkPage, children: view } = this.props;
    return (
      <div className="account-page">
        <PageLink to={logoLinkPage || Pages.account.login.path}>
          <img
            src="/images/jewish-u-logo.svg"
            alt="logo"
            className="logo mb-24"
          />
        </PageLink>
        <div className="account-card card flat-card">{view}</div>
        <div>
          <div className="accent-text text-center mt-24">
            <p
              className="mb-8 link-text-secondary"
              onClick={this.onContinueWithoutLogin}
              style={{ textDecoration: "underline" }}
            >
              Continue without Logging In
            </p>
            <p>You'll have limited access to the site prior to logging in</p>
          </div>
        </div>
      </div>
    );
  }
}
