import LocationDetailsPage from "./LocationDetailsPage.vm";
import LocationPageRedirect from "./components/locationDetails/LocationPageRedirect";
import LocationsListPage from "./LocationsListPage.vm";
import LocationsPage from "./LocationsPage.vm";

export const LocationsPages = {
  locations: {
    anon: true,
    path: "/locations",
    title: "Locations",
    type: "PAGE_LOCATIONS",
    view: LocationsPage,
  },
  locationsList: {
    anon: true,
    path: "/locations/all",
    title: "Locations",
    type: "PAGE_LOCATIONS_LIST",
    view: LocationsListPage,
  },
  locationDetails: {
    anon: true,
    path: "/locations/:locationId",
    title: "Location Details",
    type: "PAGE_LOCATION_DETAILS",
    view: LocationDetailsPage,
  },
  goToLocation: {
    anon: true,
    path: "/:locationId/learn",
    title: "Location Details",
    type: "PAGE_LOCATION_DETAILS_REDIRECT",
    view: LocationPageRedirect,
  },
};
export default LocationsPages;

export const LocationsArea = {
  pages: LocationsPages,
};
