import React from "react";
import { PageLink } from "../../lib";
import Pages from "../../pages";

export default function AccountDropdown(props) {
  const {
    doSignout,
    dropdownOpen,
    myCourses,
    myLocation,
    toggleDropdown,
    user,
  } = props;

  const { firstName, id, profileImageURL } = user || {};

  return (
    <div className="account-dropdown">
      <div className="account-dropdown-header" onClick={toggleDropdown}>
        {id ? (
          <React.Fragment>
            <img
              alt=""
              className="user-profile-pic"
              src={
                profileImageURL
                  ? profileImageURL
                  : "/images/profile-placeholder.svg"
              }
            />
            <p className="user-name fw-500">
              {firstName === null ? "Hello" : `Hi, ${firstName}`}
              <i className="material-icons">expand_more</i>
            </p>
          </React.Fragment>
        ) : (
          <i className="material-icons">menu</i>
        )}
      </div>
      <div
        className={
          dropdownOpen
            ? "account-dropdown-box dropdown-box open"
            : "account-dropdown-box dropdown-box"
        }
      >
        <div className="account-dropdown-box-body">
          <div className="mobile-menu desktop-hidden tablet-hidden">
            {id && myCourses && myCourses.length > 0 && (
              <React.Fragment>
                <p className="fw-700 mb-8">Courses</p>
                <PageLink to={Pages.courses.myCourses}>My courses</PageLink>
              </React.Fragment>
            )}
            <PageLink
              className={id && myCourses && myCourses.length > 0 ? "mb-24" : ""}
              to={Pages.courses.courses}
            >
              {id ? "Explore all courses" : "Courses"}
            </PageLink>

            {id && myLocation && (
              <React.Fragment>
                <p className="fw-700 mb-8">Locations</p>
                <PageLink
                  to={Pages.locations.locationDetails}
                  params={{ locationId: myLocation.slug || myLocation.id }}
                >
                  My location
                </PageLink>
              </React.Fragment>
            )}
            <PageLink
              className={id && myLocation && "mb-24"}
              to={Pages.locations.locations}
            >
              {id ? "Explore all locations" : "Locations"}
            </PageLink>

            <PageLink to={Pages.credits.credits}>Credits</PageLink>
            <PageLink to={Pages.grandTrip.grandTrip}>Trips</PageLink>
            <PageLink to={Pages.about.about}>About</PageLink>
            <PageLink to={Pages.contact.contact}>Contact</PageLink>
          </div>
          {id ? (
            <React.Fragment>
              <PageLink to={Pages.profile.studentProfile}>My Profile</PageLink>
              <p className="mt-16" onClick={doSignout}>
                Sign Out
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <PageLink className="block mb-16" to={Pages.account.login}>
                Log in
              </PageLink>
              <PageLink to={Pages.locations.locations}>Begin Today</PageLink>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}
