import React from "react";
import ValidatedInput from "../../../components/ValidatedInput";
import Select from "../../../components/form/Select";

export default class FamilyBackgroundInfo extends React.PureComponent {
  render() {
    const {
      jewishBackgroundTypes,
      onChangeProfile,
      profile: { fatherBackground, motherBackground } = {},
      showFormValidation,
    } = this.props;

    return (
      <div className="profile-form-section">
        <p className="section-title">Family background</p>
        <div className="profile-form-grid">
          <div className="relative">
            <ValidatedInput
              input={
                <Select
                  clearable={true}
                  options={jewishBackgroundTypes.map((bg) => ({
                    label: bg.displayValue,
                    value: bg.enumValue,
                  }))}
                />
              }
              label="Father's background"
              name="fatherBackground"
              onChange={onChangeProfile}
              required={true}
              showRequired={showFormValidation}
              value={fatherBackground}
            />
          </div>
          <div className="relative">
            <ValidatedInput
              input={
                <Select
                  clearable={true}
                  options={jewishBackgroundTypes.map((bg) => ({
                    label: bg.displayValue,
                    value: bg.enumValue,
                  }))}
                />
              }
              label="Mother's background"
              name="motherBackground"
              onChange={onChangeProfile}
              required={true}
              showRequired={showFormValidation}
              value={motherBackground}
            />
          </div>
        </div>
      </div>
    );
  }
}
