import React from "react";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";

class Contact extends React.PureComponent {
  render() {
    return (
      <div className="page container line-height-double">
        <h1 className="contact-page-title fw-700 text-center accent-text-secondary">
          Get in touch
        </h1>
        <h2 className="xl-text line-height-double text-center contact-page-subtitle">
          Want to get in touch? We’d love to hear from you. Here’s how you can
          reach us
        </h2>
        <div className="contact-methods">
          <div className="contact-method flex flex-column flex-align-center flex-justify-center">
            <div className="dashed-circle-icon mb-16">
              <i className="material-icons accent-text-secondary xxxl-text">
                phone
              </i>
            </div>
            <p className="fw-700 xl-text mb-8 text-center">Call</p>
            <a
              href="tel:7185108181"
              className="medium-text link-text-secondary text-center"
            >
              718-510-8181
            </a>
          </div>
          <div className="contact-method flex flex-column flex-align-center flex-justify-center">
            <div className="dashed-circle-icon mb-16">
              <i className="material-icons accent-text-secondary xxxl-text">
                email
              </i>
            </div>
            <p className="fw-700 xl-text mb-8 text-center">Email</p>
            <a
              href="mailto:help@jewishu.org"
              target="_blank"
              rel="noopener noreferrer"
              className="medium-text link-text-secondary text-center"
            >
              help@jewishu.org
            </a>
          </div>
          <div className="contact-method flex flex-column flex-align-center flex-justify-center">
            <div className="dashed-circle-icon mb-16">
              <i className="material-icons accent-text-secondary xxxl-text">
                markunread_mailbox
              </i>
            </div>
            <p className="fw-700 xl-text mb-8 text-center">Mail</p>
            <a
              className="medium-text link-text-secondary text-center line-height-double"
              href="https://www.google.com/maps/place/719+Eastern+Pkwy+%231,+Brooklyn,+NY+11213/data=!4m2!3m1!1s0x89c25b788677cc75:0x2478b5db16b3ac0c?sa=X&ved=2ahUKEwiH3ca8rejpAhXchXIEHR6FBPsQ8gEwAHoECAsQAQ"
              rel="noopener noreferrer"
              style={{ whiteSpace: "pre-line" }}
              target="_blank"
            >
              JewishU{"\n"}
              719 Eastern Parkway, Suite 1{"\n"}
              Brooklyn, NY 11213{"\n"}
            </a>
          </div>
          <div className="contact-method flex flex-column flex-align-center flex-justify-center">
            <div className="dashed-circle-icon mb-16">
              <i className="material-icons accent-text-secondary xxxl-text">
                chat_bubble
              </i>
            </div>
            <p className="fw-700 xl-text mb-8 text-center">Chat</p>
            <p
              className="medium-text link-text underline-text text-center"
              onClick={() => window.FrontChat("show")}
            >
              Chat Now
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withLayout()(withAppInsights(Contact));
