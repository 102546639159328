import React from "react";
import { pluralizeText, formatNumber } from "../../../../lib";

export default ({ courseDetails: { classes, isJli, levelDisplay } }) => (
  <div className="mb-40">
    <p className="accent-text small-text uppercase-text mb-16 fw-500">
      Course info
    </p>
    {levelDisplay && (
      <p className="mb-16 flex flex-align-center fw-600">
        <span className="material-icons mr-8 large-text accent-text-tertiary">
          class
        </span>
        {levelDisplay}
      </p>
    )}
    {classes && (
      <p className="flex flex-align-center fw-600">
        <span className="material-icons mr-8 large-text accent-text-tertiary">
          date_range
        </span>
        {formatNumber(classes.length) || "No"}{" "}
        {pluralizeText(classes.length, "class", "classes")}
      </p>
    )}
    {isJli && (
      <div className="mt-24">
        <img
          src="/images/jli_logo_with_text.svg"
          alt="jewish learning institute"
          height="100"
        />
      </div>
    )}
  </div>
);
