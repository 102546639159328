import React from "react";

export default ({ disabled, error, name, onChange, options, value }) => (
  <div
    className={`radio-container ${disabled ? "disabled" : ""} ${
      error ? "error" : ""
    }`}
  >
    {options.map((option) => (
      <React.Fragment key={option.value}>
        <input
          type="radio"
          id={`${name}-${option.display}`}
          name={name}
          value={option.value}
          checked={value === option.value}
          onChange={() => onChange(name, option.value)} //to avoid default toString value
          disabled={disabled || option.disabled}
        />
        <label
          className={disabled || option.disabled ? "disabled" : ""}
          htmlFor={`${name}-${option.display}`}
          style={{ width: "96px" }}
        >
          {option.display}
        </label>
      </React.Fragment>
    ))}
  </div>
);
