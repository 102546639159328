import React from "react";

export default (props) => (
  <div className={`loader-container ${props.className ? props.className : ""}`}>
    <div className="loader">
      <span className="one"></span>
      <span className="two"></span>
    </div>
  </div>
);
