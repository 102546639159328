import React from "react";
import FadeOutMessage from "./FadeOutMessage";

export default class ValidatedInput extends React.Component {
  constructor(props) {
    super(props);

    const { required, validate, validationValue, value } = props;
    //note - validationValue is the value that we are validating our input's value against

    this.state = {
      requiredError: required ? !this.hasValue(value) : false,
      validationError: validate
        ? value && !validate(value, validationValue)
        : false,
    };
  }

  componentDidUpdate(prevProps) {
    const { required, validate, validationValue, value } = this.props;
    if (
      prevProps.value !== value ||
      prevProps.validationValue !== validationValue
    ) {
      if (required) {
        this.require(value);
      }
      if (
        validate &&
        (!value ||
          this.state.validationError ||
          prevProps.validationValue !== validationValue)
      ) {
        //validate if clear out value, or if currently has validation error, or if validation dependant value changed
        this.validate(value, validationValue);
      }
    }
  }

  require = (value) => {
    const { required } = this.props;

    if (required) {
      this.setState({ requiredError: !this.hasValue(value) });
    }
  };

  hasValue = (value) => {
    let hasVal = value;
    if (value === "0") {
      hasVal = false;
    } else if (value instanceof Array) {
      hasVal = value.length;
    } else if (value instanceof Object) {
      hasVal = Object.keys(value).length || value instanceof Date;
    }
    return !!hasVal;
  };

  validate = (value, validationValue) => {
    const { validate } = this.props;

    if (validate) {
      this.setState({
        validationError: value && !validate(value, validationValue),
      });
    }
  };

  render() {
    const {
      className,
      onBlur,
      onChange,
      onKeyDown,
      onSubmit,
      label,
      labelClassName,
      input,
      name,
      value,
      required,
      showRequired,
      styles,
      validate,
      validationMessage,
      validationValue,
      showValidation,
    } = this.props;
    const { requiredError, validationError } = this.state;

    const requiredErr = !!(required && showRequired && requiredError);
    const validationErr = !!(validate && showValidation && validationError);

    return (
      <div
        className={`input-container ${className || ""} ${
          validationErr || requiredErr ? "error" : ""
        }`}
        style={styles}
      >
        {!!label && <label className={labelClassName}>{label}</label>}
        {React.cloneElement(input || <input />, {
          className: className,
          name,
          value,
          onChange,
          onBlur: (e) => {
            this.validate(e.target.value, validationValue);
            if (onBlur) onBlur(e);
          },
          onKeyDown: (e) => {
            if (e.key === "Enter") {
              this.validate(e.target.value, validationValue);
              if (onSubmit) onSubmit(e);
            }
            if (onKeyDown) onKeyDown(e);
            if (input && input.props.onKeyDown) input.props.onKeyDown(e);
          },
        })}
        <FadeOutMessage
          message={validationErr ? validationMessage || "Invalid entry" : ""}
        />
      </div>
    );
  }
}
