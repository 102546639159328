import React from "react";
import Modal from "../../../../../components/Modal";

export default function RewardRequestCongratsModal(props) {
  const { close, show, studentName } = props;

  return (
    <Modal
      show={show}
      className="reward-redeemed-modal flex flex-justify-center"
    >
      <div className="text-center">
        <img src="/images/ju-trophy.svg" alt="" />
        <p className="xxl-text fw-700 mt-24 mb-8">
          Congratulations{studentName ? `, ${studentName}` : ""}!
        </p>
        <p className="accent-text-dark line-height-double mb-16">
          Your request has been submitted. <br />
          Check your email for additional information.
        </p>
        <button
          className="btn btn-accent"
          onClick={close}
          style={{ width: "160px" }}
        >
          Ok
        </button>
      </div>
    </Modal>
  );
}
