import React from "react";
import Select from "../../../../components/form/Select";

export default class CoursesFilters extends React.PureComponent {
  filterByCategory = (category) => {
    this.props.filterCourses(
      { category },
      !this.props.showResponsive, //adjust scroll for non-mobile view
    );
  };

  render() {
    const { categoryFilter, courseCategories, showResponsive } = this.props;

    const defaultCategoryFilter = {
      label: "All Courses",
      value: undefined,
    };

    return (
      <React.Fragment>
        {showResponsive ? (
          <div className="flex flex-align-center courses-category-select mobile-mb-16">
            <p
              className="fw-500 mr-16 medium-text"
              style={{ whiteSpace: "nowrap" }}
            >
              Filter By
            </p>
            <Select
              onChange={(name, val) => this.filterByCategory(val)}
              options={[
                defaultCategoryFilter,
                ...(courseCategories &&
                  courseCategories.map((ctgry) => ({
                    label: ctgry.displayValue,
                    value: ctgry.enumValue,
                  }))),
              ]}
              searchable={true}
              style={{ maxWidth: "270px" }}
              value={categoryFilter}
              defaultValue={defaultCategoryFilter}
            />
          </div>
        ) : (
          <div className="course-category-filter">
            <p
              className={`link-text-secondary fw-500 medium-text mb-32 inline-block ${
                !categoryFilter ? "active" : ""
              }`}
              onClick={() => this.filterByCategory()}
            >
              All courses
            </p>
            {courseCategories && courseCategories.length > 0 && (
              <div>
                <p className="uppercase-text accent-text fw-500 small-text mb-24">
                  Filter By Category
                </p>
                {courseCategories.map((category, index) => (
                  <p key={index}>
                    <span
                      className={`link-text-secondary fw-500 medium-text mb-16 line-height-double inline-block ${
                        categoryFilter === category.enumValue ? "active" : ""
                      }`}
                      onClick={() =>
                        this.filterByCategory(
                          categoryFilter !== category.enumValue
                            ? category.enumValue
                            : undefined,
                        )
                      }
                    >
                      {category.displayValue}
                    </span>
                  </p>
                ))}
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}
