import React from "react";
import moment from "moment";
import { getAddressDisplay } from "../../../../lib";

export default class CourseClasses extends React.PureComponent {
  render() {
    const {
      classes,
      courseSchedule,
      courseSchedule: { notes } = {},
      isStudentEnrolledInCourseSchedule,
      hideDescription,
      title,
    } = this.props;
    return classes && classes.length > 0 ? (
      <div className="course-details-section">
        <p className="course-details-section-title inline-block" dir="auto">
          {title}
        </p>
        {isStudentEnrolledInCourseSchedule && notes && (
          <div className="mb-24">
            <p>{notes}</p>
          </div>
        )}
        {courseSchedule && (
          <p className="flex flex-align-center medium-text fw-600">
            <i className="material-icons mr-4 large-text accent-text-tertiary">
              place
            </i>
            {courseSchedule.locationAddress &&
            courseSchedule.locationAddress.address1
              ? `${
                  courseSchedule.locationName
                    ? `${courseSchedule.locationName} - `
                    : ""
                }${getAddressDisplay(
                  courseSchedule.locationAddress.address1,
                  courseSchedule.locationAddress.address2,
                  courseSchedule.locationAddress.city,
                )}`
              : courseSchedule.locationDisplay}
          </p>
        )}
        <div className="course-details-classes">
          {classes
            .sort((c1, c2) => c1.sortOrder - c2.sortOrder)
            .map((cls, index) => {
              const classSchedule =
                courseSchedule &&
                courseSchedule.classSchedules &&
                courseSchedule.classSchedules.find(
                  (cs) => cs.eduClassID === cls.id,
                );
              return (
                <div className="mb-32 relative flex" key={index}>
                  <p className="class-number">{index + 1}</p>
                  <div className="class-info full-width">
                    <div className="medium-text fw-700 mb-8 relative flex flex-align-center flex-justify-space tablet-block mobile-block">
                      <p className="class-title mobile-mb-16" dir="auto">
                        {cls.title || `Class ${cls.sortOrder}`}
                      </p>
                      {classSchedule && (
                        <div
                          className={`flex flex-align-center mobile-block small-text class-date ${
                            classSchedule.dateTime ? "grid" : ""
                          }`}
                        >
                          {!classSchedule.dateTime ? (
                            <p
                              className="flex flex-align-center medium-text accent-text-tertiary fw-600"
                              style={{ fontStyle: "italic" }}
                            >
                              <i className="material-icons mr-4 large-text accent-text-tertiary">
                                date_range
                              </i>
                              Date & Time to be determined
                            </p>
                          ) : (
                            <React.Fragment>
                              <p className="flex flex-align-center mr-16 medium-text fw-600 mobile-mb-8">
                                <i className="material-icons mr-4 large-text accent-text-tertiary">
                                  date_range
                                </i>
                                {moment(classSchedule.dateTime).format(
                                  "ddd, M/D/YYYY",
                                )}
                              </p>
                              <p className="flex flex-align-center medium-text fw-600">
                                <i className="material-icons mr-4 large-text accent-text-tertiary">
                                  access_time
                                </i>
                                {moment(classSchedule.dateTime).format(
                                  "h:mm a",
                                )}{" "}
                                {courseSchedule.timezoneName}
                              </p>
                            </React.Fragment>
                          )}
                        </div>
                      )}
                    </div>
                    {!hideDescription && (
                      <p
                        className="accent-text-dark medium-text line-height-double class-description relative"
                        dir="auto"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {cls.description}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    ) : null;
  }
}
