import MobileDetect from "mobile-detect";

const mobileDetect = new MobileDetect(window.navigator.userAgent);
const isPhone = !!mobileDetect.phone();
const isTablet = !!mobileDetect.tablet();

export const UIState = {
  name: "ui",
  persist: false,
  defaults: {
    isPhone,
    isTablet,
  },
};
