import React from "react";
import Layout from "./Layout.vm";

const withLayout =
  (configs = {}) =>
  (PageComponent) =>
  (props) => {
    const { pageRoute } = props;
    const { getTitle, ...layoutConfigs } = configs;

    return (
      <Layout
        {...layoutConfigs}
        pageRoute={props.pageRoute}
        title={(getTitle && getTitle(props)) || pageRoute.page.title}
      >
        <PageComponent {...props} />
      </Layout>
    );
  };

export default withLayout;

//Note: can use HOC wrapped in HOF like this, or can pass configs as additional prop - either is acceptable.
