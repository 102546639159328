import React from "react";
import Modal from "../../../../components/Modal";

export default class CourseSurveyLink extends React.PureComponent {
  state = {
    refreshCourseOnClose: false,
    showSurveyModal: false,
  };

  componentWillUnmount() {
    window.onSurveyCompleted = undefined;
    window.onSurveyCompletionTracked = undefined;
  }

  //method to get courseScheduleEnrollment for CourseSurveyCompletedPage's submission of didCompleteSurvey
  onSurveyCompleted = () => this.props.courseScheduleEnrollment;

  //method to set appropriate state to refresh course details when modal is closed after survey submission has been tracked (cannot be refreshed until modal is closed, otherwise link and modal will unmount because didCompleteSurvey is true)
  onSurveyCompletionTracked = () =>
    this.setState({ refreshCourseOnClose: true });

  toggleSurveyModal = () => {
    const { refreshCourseOnClose, showSurveyModal: prevShowSurveyModal } =
      this.state;
    const showSurveyModal = !prevShowSurveyModal;

    if (showSurveyModal) {
      window.onSurveyCompleted = this.onSurveyCompleted;
      window.onSurveyCompletionTracked = this.onSurveyCompletionTracked;
    } else {
      window.onSurveyCompleted = undefined;
      window.onSurveyCompletionTracked = undefined;
    }

    this.setState({ refreshCourseOnClose: false, showSurveyModal });

    const { refreshCourseDetails } = this.props;
    if (!showSurveyModal && refreshCourseOnClose && refreshCourseDetails) {
      refreshCourseDetails();
    }
  };

  render() {
    const {
      className,
      courseScheduleEnrollment: { courseSurveyURL },
      loading,
    } = this.props;
    const { showSurveyModal } = this.state;

    return (
      <div>
        <p
          className={`btn btn-accent ${className || ""}`}
          onClick={this.toggleSurveyModal}
          style={{ padding: 0, fontSize: 13 }}
        >
          Complete Course Review
        </p>
        <Modal
          show={showSurveyModal}
          style={{
            width: "96%",
            height: "96%",
            position: "relative",
            top: 40,
            maxHeight: "96vh",
            padding: 24,
          }}
        >
          {!loading && (
            <i
              className="flex flex-justify-end mb-8 material-icons link-text-secondary"
              onClick={this.toggleSurveyModal}
            >
              close
            </i>
          )}
          <iframe
            src={courseSurveyURL}
            style={{ width: "100%", height: "calc(100% - 36px)" }}
            title="Course Survey"
          />
        </Modal>
      </div>
    );
  }
}
