import EnrollConfirmationPage from "./EnrollConfirmationPage";
import JewishUEnrollmentPage from "./JewishUEnrollmentPage.vm";

export const MyJewishUPages = {
  enroll: {
    anon: false,
    path: "/enroll",
    title: "Enroll in JewishU",
    type: "PAGE_JU_ENROLLMENT",
    view: JewishUEnrollmentPage,
  },
  enrollmentConfirmed: {
    anon: false,
    path: "/enroll/confirm",
    title: "JewishU Enrollment",
    type: "PAGE_JU_ENROLLMENT_CONFIRM",
    view: EnrollConfirmationPage,
  },
};
export default MyJewishUPages;

export const MyJewishUArea = {
  pages: MyJewishUPages,
};

export * from "./utils";
