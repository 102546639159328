import { AuthActions } from "../auth/actions";

export const ConfirmRegistrationState = {
  name: "confirmRegistration",
  persist: false,
  defaults: {
    error: undefined,
    loading: false,
    success: undefined,
  },
  handlers: {
    [AuthActions.CONFIRM_REGISTRATION_ERROR]: function (state, action) {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response,
      };
    },
    [AuthActions.DO_CONFIRM_REGISTRATION]: function (state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    [AuthActions.CONFIRM_REGISTRATION_COMPLETED]: function (state, action) {
      return {
        ...state,
        loading: false,
        success: true,
        error: undefined,
      };
    },
  },
};
