import About from "./About";

export const AboutPages = {
  about: {
    anon: true,
    path: "/about",
    title: "About",
    type: "PAGE_ABOUT",
    view: About,
  },
};
export default AboutPages;

export const AboutArea = {
  pages: AboutPages,
};
