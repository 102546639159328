import React from "react";
import { PageNavLink } from "../../lib";
import Pages from "../../pages";

export default class MyCoursesMenu extends React.PureComponent {
  render() {
    const { menuOpen, myCourses } = this.props;

    if (!myCourses || !myCourses.length) {
      return null;
    }

    return (
      <div
        className={`nav-menu-dropdown-box dropdown-box ${
          menuOpen ? "open" : ""
        }`}
      >
        <PageNavLink
          activeClassName="active-nav-link"
          className="link-text-secondary fw-600"
          to={Pages.courses.myCourses}
        >
          My courses
        </PageNavLink>
        {myCourses.map((courseSched, index) => (
          <PageNavLink
            activeClassName="active-nav-link"
            className="link-text-secondary"
            dir="auto"
            key={index}
            to={Pages.courses.courseDetails}
            params={{
              courseId: courseSched.courseSlug || courseSched.eduCourseID,
            }}
            query={{
              loc: courseSched.eduChabadHouseEnrollmentID,
              sched: courseSched.id,
            }}
          >
            {courseSched.courseName}
          </PageNavLink>
        ))}
        <PageNavLink
          activeClassName="active-nav-link"
          className="link-text-secondary"
          exact
          to={Pages.courses.courses}
        >
          Explore all courses
        </PageNavLink>
      </div>
    );
  }
}
