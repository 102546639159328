const newEmptyObject = {};
export const JewishUSelectors = {
  myJewishU(state: any) {
    return state.myJewishU.data || newEmptyObject;
  },
  eduChabadHouse(state: any) {
    return state.myJewishU.data?.eduChabadHouse || newEmptyObject;
  },
  eduStudentEnrollmentID(state: any) {
    return state.myJewishU.data?.eduStudentEnrollmentID;
  },
};
