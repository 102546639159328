import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { CoursesActions } from "../../state";
import CourseSurveyCompletedPage from "./CourseSurveyCompletedPage";

export default connect(
  function mapState(state) {
    return {
      ...state.courses.enrollmentUpdate,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          doCourseEnrollmentUpdate: CoursesActions.doCourseEnrollmentUpdate,
        },
        dispatch,
      ),
    };
  },
)(CourseSurveyCompletedPage);
