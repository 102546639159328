import React from "react";
import ErrorDisplay from "../../components/ErrorDisplay";
import Loader from "../../components/Loader";

export default class CourseSurveyCompletedPage extends React.PureComponent {
  state = {
    didCompleteCourseAttendance: false,
    didTrackSurveyCompletion: false,
    surveyEnrollmentMismatchError: false,
    trackCompletionError: false,
    trackCompletionRetryCount: 0,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.trackSurveyCompletion();
  }

  trackSurveyCompletion = async () => {
    const {
      pageRoute: {
        query: { cse_id: courseScheduleEnrollmentID },
      },
    } = this.props;
    const { onSurveyCompleted, onSurveyCompletionTracked } = window.parent;

    const courseScheduleEnrollment = onSurveyCompleted && onSurveyCompleted();
    if (
      !courseScheduleEnrollment ||
      !courseScheduleEnrollmentID ||
      courseScheduleEnrollment.id.toString() !==
        courseScheduleEnrollmentID.toString()
    ) {
      this.setState({ surveyEnrollmentMismatchError: true }); //error matching survey courseScheduleEnrollmentID to current course sched: display msg for student to reach out to Admin to secure credits
      return;
    }

    await this.props.actions.doCourseEnrollmentUpdate({
      id: courseScheduleEnrollment.id,
      eduCourseScheduleID: courseScheduleEnrollment.eduCourseScheduleID,
      eduStudentEnrollmentID: courseScheduleEnrollment.eduStudentEnrollmentID,
      didCompleteSurvey: true,
    });

    if (this.props.success) {
      this.setState({
        didTrackSurveyCompletion: true,
        didCompleteCourseAttendance:
          courseScheduleEnrollment.didCompleteAttendance, //save to state whether attendance was completed to display accurate message regarding credits for surveyed course
      });

      if (onSurveyCompletionTracked) {
        onSurveyCompletionTracked();
      }
    } else {
      const { retryCount } = this.state;
      if (retryCount < 3) {
        this.setState(
          { retryCount: retryCount + 1 },
          this.trackSurveyCompletion,
        );
        return;
      }

      this.setState({ trackCompletionError: true }); //error tracking survey completion: display msg to students re processing time for completion status update
    }
  };

  render() {
    const {
      didCompleteCourseAttendance,
      didTrackSurveyCompletion,
      surveyEnrollmentMismatchError,
      trackCompletionError,
    } = this.state;

    return (
      <div className="page container">
        {surveyEnrollmentMismatchError ? (
          <ErrorDisplay
            errorMessage={
              <span className="line-height-double">
                We are unable to track your Course Review completion
                <br />
                Please notify{" "}
                <a href="mailto:help@jewishu.org" className="link-text">
                  help@jewishu.org
                </a>{" "}
                that you've completed the review so you can get credit for this
                course!
              </span>
            }
            errorTitle="Sorry, something went wrong!"
          />
        ) : !didTrackSurveyCompletion ? (
          <Loader />
        ) : (
          <div className="text-center">
            <img src="/images/ju-award.svg" alt="" />
            <p className="large-text fw-700 mt-24 mb-16">
              Thank you for submitting your Course Review and Evaluation!
            </p>
            <p className="medium-text accent-text line-height-double">
              Carry your newfound knowledge with you, proudly.
              <br />
              {trackCompletionError
                ? "Your submission is being processed. \nPlease check back shortly - once your course completion status is updated, credits from this course will be available for redemption."
                : `${
                    didCompleteCourseAttendance ? "You now have" : "You'll earn"
                  } an additional 4 JewishU Credits. Learn more, earn more.`}
            </p>
          </div>
        )}
      </div>
    );
  }
}
