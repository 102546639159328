import { LocationsActions } from "../locations/actions";
import { MyJewishUActions } from "../myJewishU/actions";
import { AuthRequest } from "../../lib";
import queryString from "query-string";

export const CoursesActions = {
  GET_COURSES_COMPLETE: "GET_COURSES_COMPLETE",
  GET_COURSES_ERROR: "GET_COURSES_ERROR",
  SET_GET_COURSES_LOADING: "SET_GET_COURSES_LOADING",
  RESET_COURSES: "RESET_COURSES",

  GET_ALL_COURSES_COMPLETE: "GET_ALL_COURSES_COMPLETE",
  GET_ALL_COURSES_ERROR: "GET_ALL_COURSES_ERROR",
  SET_GET_ALL_COURSES_LOADING: "SET_GET_ALL_COURSES_LOADING",
  RESET_ALL_COURSES: "RESET_ALL_COURSES",

  GET_COURSE_COMPLETE: "GET_COURSE_COMPLETE",
  GET_COURSE_ERROR: "GET_COURSE_ERROR",
  SET_GET_COURSE_LOADING: "SET_GET_COURSE_LOADING",
  RESET_COURSE: "RESET_COURSE",

  COURSE_ENROLLMENT_COMPLETE: "COURSE_ENROLLMENT_COMPLETE",
  COURSE_ENROLLMENT_ERROR: "COURSE_ENROLLMENT_ERROR",
  SET_COURSE_ENROLLMENT_LOADING: "SET_COURSE_ENROLLMENT_LOADING",
  RESET_COURSE_ENROLLMENT: "RESET_COURSE_ENROLLMENT",

  COURSE_ENROLLMENT_UPDATE_COMPLETE: "COURSE_ENROLLMENT_UPDATE_COMPLETE",
  COURSE_ENROLLMENT_UPDATE_ERROR: "COURSE_ENROLLMENT_UPDATE_ERROR",
  SET_COURSE_ENROLLMENT_UPDATE_LOADING: "SET_COURSE_ENROLLMENT_UPDATE_LOADING",
  RESET_COURSE_ENROLLMENT_UPDATE: "RESET_COURSE_ENROLLMENT_UPDATE",

  COURSE_ENROLLMENT_CANCELLATION_COMPLETE:
    "COURSE_ENROLLMENT_CANCELLATION_COMPLETE",
  COURSE_ENROLLMENT_CANCELLATION_ERROR: "COURSE_ENROLLMENT_CANCELLATION_ERROR",
  SET_COURSE_ENROLLMENT_CANCELLATION_LOADING:
    "SET_COURSE_ENROLLMENT_CANCELLATION_LOADING",
  RESET_COURSE_ENROLLMENT_CANCELLATION: "RESET_COURSE_ENROLLMENT_CANCELLATION",

  getCourseIdBySlug(courseSlug) {
    return async (dispatch, getState) => {
      if (
        courseSlug &&
        courseSlug.toString() !== parseInt(courseSlug, 10).toString()
      ) {
        let allCoursesList = getState().courses.allCourses;
        if (!allCoursesList || !allCoursesList.length) {
          allCoursesList = await dispatch(CoursesActions.getAllCourses());
        }

        const course = allCoursesList.find(
          (c) => c.slug === decodeURIComponent(courseSlug),
        );
        if (course) {
          return course.id;
        }
      }

      return courseSlug;
    };
  },

  getCourses(page, results, filters = {}, myCourses = false) {
    return async (dispatch, getState) => {
      dispatch(CoursesActions.setGetCoursesLoading(true));

      let error, response, success;
      try {
        const {
          myJewishU: { data: { eduStudentEnrollmentID } = {} },
          sys: { jewishUProgramID },
        } = getState();

        const params = {
          category: filters.category,
          eduProgramID: jewishUProgramID,
          eduStudentEnrollmentID,
          page,
          results,
          sortByOption: myCourses ? "date" : "currentlyOffered",
        };

        if (myCourses) {
          params.enrolledCoursesOnly = true;
        } else if (filters.currentlyOfferedAtMyLocation) {
          params.limitToCurrentCourseSchedules = true;
        }

        response = await AuthRequest.get(
          `EduStudents/Courses?${queryString.stringify(params)}`,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(CoursesActions.getCoursesError(error));
        return undefined;
      }

      dispatch(CoursesActions.getCoursesComplete(response.data.payload));

      return response.data.payload;
    };
  },
  setGetCoursesLoading(payload) {
    return {
      type: CoursesActions.SET_GET_COURSES_LOADING,
      payload,
    };
  },
  getCoursesComplete(payload) {
    return {
      type: CoursesActions.GET_COURSES_COMPLETE,
      payload,
    };
  },
  getCoursesError(payload) {
    return {
      type: CoursesActions.GET_COURSES_ERROR,
      payload,
    };
  },
  resetCourses() {
    return {
      type: CoursesActions.RESET_COURSES,
    };
  },

  getAllCourses() {
    return async (dispatch, getState) => {
      dispatch(CoursesActions.setGetAllCoursesLoading(true));

      let error, response, success;
      try {
        const { jewishUProgramID } = getState().sys;
        response = await AuthRequest.get(
          `EduPrograms/${jewishUProgramID}/Courses/Basic`,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(CoursesActions.getAllCoursesError(error));
        return undefined;
      }

      dispatch(CoursesActions.getAllCoursesComplete(response.data.payload));

      return response.data.payload;
    };
  },
  setGetAllCoursesLoading(payload) {
    return {
      type: CoursesActions.SET_GET_ALL_COURSES_LOADING,
      payload,
    };
  },
  getAllCoursesComplete(payload) {
    return {
      type: CoursesActions.GET_ALL_COURSES_COMPLETE,
      payload,
    };
  },
  getAllCoursesError(payload) {
    return {
      type: CoursesActions.GET_ALL_COURSES_ERROR,
      payload,
    };
  },
  resetAllCourses() {
    return {
      type: CoursesActions.RESET_ALL_COURSES,
    };
  },

  getCourse(courseId, locationId, hideLoading = false) {
    return async (dispatch, getState) => {
      if (!hideLoading) {
        dispatch(CoursesActions.setGetCourseLoading(true));
      }

      let error, response, success;
      try {
        //get proper course id if courseId is slug
        if (courseId.toString() !== parseInt(courseId, 10).toString()) {
          courseId = await dispatch(CoursesActions.getCourseIdBySlug(courseId));
        }

        const {
          myJewishU: { data: { eduStudentEnrollmentID } = {} },
        } = getState();

        const params = {
          eduStudentEnrollmentID,
        };
        if (locationId) {
          //get proper location id if locationId is slug
          if (locationId.toString() !== parseInt(locationId, 10).toString()) {
            locationId = await dispatch(
              LocationsActions.getLocationIdBySlug(locationId),
            );
          }

          params.eduChabadHouseEnrollmentID = locationId;
        }

        response = await AuthRequest.get(
          `EduStudents/Courses/${courseId}${
            params ? `?${queryString.stringify(params)}` : ""
          }`,
        );

        if (locationId) {
          try {
            const locationResponse = await AuthRequest.get(
              `EduChabadHouses/${locationId}/public`,
            );
            if (locationResponse) {
              response.data.payload.courseLocation =
                locationResponse.data.payload;
            }
          } catch (err) {
            // swallow location retrieval err if it is a Not Found err (relevant inactive chabad house)
            if (!err.response || err.response.status !== 404) {
              throw err;
            }
          }
        }

        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(CoursesActions.getCourseError(error));
        return undefined;
      }

      dispatch(CoursesActions.getCourseComplete(response.data.payload));

      return response.data.payload;
    };
  },
  setGetCourseLoading(payload) {
    return {
      type: CoursesActions.SET_GET_COURSE_LOADING,
      payload,
    };
  },
  getCourseComplete(payload) {
    return {
      type: CoursesActions.GET_COURSE_COMPLETE,
      payload,
    };
  },
  getCourseError(payload) {
    return {
      type: CoursesActions.GET_COURSE_ERROR,
      payload,
    };
  },
  resetCourse() {
    return {
      type: CoursesActions.RESET_COURSE,
    };
  },

  doCourseEnrollment(courseScheduleId, refreshCourseAction) {
    return async (dispatch, getState) => {
      dispatch(CoursesActions.setCourseEnrollmentLoading(true));

      let error, response, success;
      try {
        let {
          myJewishU: { data: { eduStudentEnrollmentID } = {} },
        } = getState();

        response = await AuthRequest.post("EduStudents/CourseSchedules", {
          eduCourseScheduleID: courseScheduleId,
          eduStudentEnrollmentID,
        });
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(CoursesActions.courseEnrollmentError(error));
        return undefined;
      }

      if (refreshCourseAction) {
        await refreshCourseAction();
      }
      dispatch(CoursesActions.courseEnrollmentComplete(response.data.payload));
      dispatch(MyJewishUActions.getMyJewishU());

      return response.data;
    };
  },
  setCourseEnrollmentLoading(payload) {
    return {
      type: CoursesActions.SET_COURSE_ENROLLMENT_LOADING,
      payload,
    };
  },
  courseEnrollmentComplete(payload) {
    return {
      type: CoursesActions.COURSE_ENROLLMENT_COMPLETE,
      payload,
    };
  },
  courseEnrollmentError(payload) {
    return {
      type: CoursesActions.COURSE_ENROLLMENT_ERROR,
      payload,
    };
  },
  resetCourseEnrollment() {
    return {
      type: CoursesActions.RESET_COURSE_ENROLLMENT,
    };
  },

  doCourseEnrollmentUpdate(courseEnrollment, refreshCourseAction) {
    return async (dispatch) => {
      dispatch(CoursesActions.setCourseEnrollmentUpdateLoading(true));

      let error, response, success;
      try {
        response = await AuthRequest.post(
          "EduStudents/CourseSchedules",
          courseEnrollment,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(CoursesActions.courseEnrollmentUpdateError(error));
        return undefined;
      }

      if (refreshCourseAction) {
        await refreshCourseAction();
      }
      dispatch(
        CoursesActions.courseEnrollmentUpdateComplete(response.data.payload),
      );

      return response.data;
    };
  },
  setCourseEnrollmentUpdateLoading(payload) {
    return {
      type: CoursesActions.SET_COURSE_ENROLLMENT_UPDATE_LOADING,
      payload,
    };
  },
  courseEnrollmentUpdateComplete(payload) {
    return {
      type: CoursesActions.COURSE_ENROLLMENT_UPDATE_COMPLETE,
      payload,
    };
  },
  courseEnrollmentUpdateError(payload) {
    return {
      type: CoursesActions.COURSE_ENROLLMENT_UPDATE_ERROR,
      payload,
    };
  },
  resetCourseEnrollmentUpdate() {
    return {
      type: CoursesActions.RESET_COURSE_ENROLLMENT_UPDATE,
    };
  },

  doCourseEnrollmentCancellation(
    courseScheduleEnrollmentId,
    refreshCourseAction,
  ) {
    return async (dispatch) => {
      dispatch(CoursesActions.setCourseEnrollmentCancellationLoading(true));

      let error, response, success;
      try {
        response = await AuthRequest.delete(
          `EduStudents/CourseSchedules/${courseScheduleEnrollmentId}`,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(CoursesActions.courseEnrollmentCancellationError(error));
        return undefined;
      }

      if (refreshCourseAction) {
        await refreshCourseAction();
      }
      dispatch(
        CoursesActions.courseEnrollmentCancellationComplete(
          response.data.payload,
        ),
      );
      dispatch(MyJewishUActions.getMyJewishU());

      return response.data;
    };
  },
  setCourseEnrollmentCancellationLoading(payload) {
    return {
      type: CoursesActions.SET_COURSE_ENROLLMENT_CANCELLATION_LOADING,
      payload,
    };
  },
  courseEnrollmentCancellationComplete(payload) {
    return {
      type: CoursesActions.COURSE_ENROLLMENT_CANCELLATION_COMPLETE,
      payload,
    };
  },
  courseEnrollmentCancellationError(payload) {
    return {
      type: CoursesActions.COURSE_ENROLLMENT_CANCELLATION_ERROR,
      payload,
    };
  },
  resetCourseEnrollmentCancellation() {
    return {
      type: CoursesActions.RESET_COURSE_ENROLLMENT_CANCELLATION,
    };
  },
};
