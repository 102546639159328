export { normalizeCountdownValue, stepImages };

const MIN = 0;
const MAX = 60;
const normalizeCountdownValue = (value: any) =>
  ((value - MIN) * 100) / (MAX - MIN);

const stepImages = [
  {
    left: { img: "sunrise", label: "Sunrise" },
    right: { img: "sunset", label: "Sunset" },
  },
  {
    left: { img: "purim", label: "Purim" },
    right: { img: "chanukah", label: "Chanukah" },
  },
  {
    left: { img: "speed", label: "Speed" },
    right: { img: "quality", label: "Quality" },
  },
  {
    left: { img: "master-teacher", label: "Master-Teacher" },
    right: { img: "group-discussion", label: "Group-Discussion" },
  },
  {
    left: { img: "salad", label: "Salad" },
    right: { img: "french-fries", label: "French Fries" },
  },
  {
    left: { img: "david-star", label: "Star of David" },
    right: { img: "chai", label: "Chai Symbol" },
  },
];
