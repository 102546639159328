import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { LocationsActions } from "../../state";
import LocationsPage from "./LocationsPage";

export default connect(
  function mapState(state) {
    return {
      ...state.locations.locationCampuses,
      myJewishU: state.myJewishU,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...LocationsActions,
        },
        dispatch,
      ),
    };
  },
)(LocationsPage);
