import { AuthActions } from "../auth/actions";

export const RegistrationState = {
  name: "registration",
  persist: false,
  defaults: {
    error: undefined,
    loading: false,
    success: undefined,
  },
  handlers: {
    [AuthActions.REGISTRATION_ERROR]: function (state, action) {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response,
      };
    },
    [AuthActions.DO_BASIC_REGISTRATION]: function (state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    [AuthActions.DO_SOCIAL_REGISTRATION]: function (state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    [AuthActions.REGISTRATION_COMPLETED]: function (state, action) {
      return {
        ...state,
        loading: false,
        success: true,
        error: undefined,
        data: action.payload,
      };
    },
  },
};
