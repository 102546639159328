import { AuthRequest } from "../../lib";
import queryString from "query-string";

export const CreditsActions = {
  GET_STUDENT_COMPLETE: "GET_STUDENT_COMPLETE",
  GET_STUDENT_ERROR: "GET_STUDENT_ERROR",
  SET_GET_STUDENT_LOADING: "SET_GET_STUDENT_LOADING",

  GET_STUDENT_SHLUCHIM_PROFILE: "GET_STUDENT_SHLUCHIM_PROFILE",
  STUDENT_UPDATE_COMPLETE: "STUDENT_UPDATE_COMPLETE",
  STUDENT_UPDATE_ERROR: "STUDENT_UPDATE_ERROR",
  SET_STUDENT_UPDATE_LOADING: "SET_STUDENT_UPDATE_LOADING",

  GET_REWARDS_COMPLETE: "GET_REWARDS_COMPLETE",
  GET_REWARDS_ERROR: "GET_REWARDS_ERROR",
  SET_GET_REWARDS_LOADING: "SET_GET_REWARDS_LOADING",

  GET_STUDENT_REWARDS_COMPLETE: "GET_STUDENT_REWARDS_COMPLETE",
  GET_STUDENT_REWARDS_ERROR: "GET_STUDENT_REWARDS_ERROR",
  SET_GET_STUDENT_REWARDS_LOADING: "SET_GET_STUDENT_REWARDS_LOADING",

  GET_REWARD_COMPLETE: "GET_REWARD_COMPLETE",
  GET_REWARD_ERROR: "GET_REWARD_ERROR",
  SET_GET_REWARD_LOADING: "SET_GET_REWARD_LOADING",

  REWARD_REQUEST_COMPLETE: "REWARD_REQUEST_COMPLETE",
  CLEAR_REWARDS_REQUEST: "CLEAR_REWARDS_REQUEST",
  REWARD_REQUEST_ERROR: "REWARD_REQUEST_ERROR",
  SET_REWARD_REQUEST_LOADING: "SET_REWARD_REQUEST_LOADING",

  getStudent(isRefresh = false) {
    return async (dispatch, getState) => {
      if (!isRefresh) {
        dispatch(CreditsActions.setGetStudentLoading(true));
      }

      let error, response, success;
      try {
        const { data: { eduStudentEnrollmentID } = {} } = getState().myJewishU;
        response = await AuthRequest.get(
          `EduStudents/${eduStudentEnrollmentID}`,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      success = true;

      if (!response || !success) {
        dispatch(CreditsActions.getStudentError(error));
        return undefined;
      }

      dispatch(CreditsActions.getStudentComplete(response.data.payload));

      return response.data.payload;
    };
  },
  getStudentShluchimProfiles() {
    return async (dispatch, getState) => {
      const { data: { eduProgramID } = {} } = getState().myJewishU;
      const { data, status, error } = await AuthRequest.get(
        `EduStudents/student/shluchimProfile?eduProgramId=${eduProgramID}`,
      );
      if (status !== 200) {
        dispatch(CreditsActions.getStudentError(error));
      }
      dispatch(CreditsActions.setStudentShluchimProfile(data.payload));
    };
  },
  setGetStudentLoading(payload) {
    return {
      type: CreditsActions.SET_GET_STUDENT_LOADING,
      payload,
    };
  },
  getStudentComplete(payload) {
    return {
      type: CreditsActions.GET_STUDENT_COMPLETE,
      payload,
    };
  },
  setStudentShluchimProfile(payload) {
    return {
      type: CreditsActions.GET_STUDENT_SHLUCHIM_PROFILE,
      payload,
    };
  },
  getStudentError(payload) {
    return {
      type: CreditsActions.GET_STUDENT_ERROR,
      payload,
    };
  },

  doStudentUpdate(student) {
    return async (dispatch) => {
      dispatch(CreditsActions.setStudentUpdateLoading(true));

      let error, response, success;
      try {
        response = await AuthRequest.post("EduStudents", student);
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(CreditsActions.studentUpdateError(error));
        return undefined;
      }

      await dispatch(CreditsActions.getStudent(true));

      dispatch(CreditsActions.studentUpdateComplete(response.data.payload));

      return response.data;
    };
  },
  setStudentUpdateLoading(payload) {
    return {
      type: CreditsActions.SET_STUDENT_UPDATE_LOADING,
      payload,
    };
  },
  studentUpdateComplete(payload) {
    return {
      type: CreditsActions.STUDENT_UPDATE_COMPLETE,
      payload,
    };
  },
  studentUpdateError(payload) {
    return {
      type: CreditsActions.STUDENT_UPDATE_ERROR,
      payload,
    };
  },
  getRewards(tripsOnly = false) {
    return async (dispatch, getState) => {
      dispatch(CreditsActions.setGetRewardsLoading(true));

      let error, response, success;
      try {
        const {
          myJewishU: { data: { eduStudentEnrollmentID } = {} },
          sys: { jewishUProgramID },
        } = getState();
        const params = {
          noPage: true,
          sortByOption: "tripStartDate",
          eduStudentEnrollmentID,
        };
        const requestURL = tripsOnly
          ? `EduPrograms/${jewishUProgramID}/Rewards/Trips`
          : `EduPrograms/${jewishUProgramID}/Rewards`;
        response = await AuthRequest.get(
          `${requestURL}?${queryString.stringify(params)}`,
        );
        success = true;
      } catch (err) {
        error = err;
      }
      success = true;

      if (!response || !success) {
        dispatch(CreditsActions.getRewardsError(error));
        return undefined;
      }

      const rewardResults =
        response.data.payload && response.data.payload.results;

      dispatch(CreditsActions.getRewardsComplete(rewardResults));

      return rewardResults;
    };
  },
  setGetRewardsLoading(payload) {
    return {
      type: CreditsActions.SET_GET_REWARDS_LOADING,
      payload,
    };
  },
  getRewardsComplete(payload) {
    return {
      type: CreditsActions.GET_REWARDS_COMPLETE,
      payload,
    };
  },
  getRewardsError(payload) {
    return {
      type: CreditsActions.GET_REWARDS_ERROR,
      payload,
    };
  },

  getStudentRewards(isRefresh) {
    return async (dispatch, getState) => {
      if (!isRefresh) {
        dispatch(CreditsActions.setGetStudentRewardsLoading(true));
      }

      let error, response, success;
      try {
        const {
          myJewishU: { data: { eduStudentEnrollmentID } = {} },
          sys: { jewishUProgramID },
        } = getState();

        const params = {
          eduStudentEnrollmentID,
          noPage: true,
          statuses: ["Approved", "Pending"],
        };
        response = await AuthRequest.get(
          `EduPrograms/${jewishUProgramID}/RewardRequests?${queryString.stringify(
            params,
          )}`,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(CreditsActions.getStudentRewardsError(error));
        return undefined;
      }

      const rewardResults =
        response.data.payload && response.data.payload.results;

      dispatch(CreditsActions.getStudentRewardsComplete(rewardResults));

      return rewardResults;
    };
  },
  setGetStudentRewardsLoading(payload) {
    return {
      type: CreditsActions.SET_GET_STUDENT_REWARDS_LOADING,
      payload,
    };
  },
  getStudentRewardsComplete(payload) {
    return {
      type: CreditsActions.GET_STUDENT_REWARDS_COMPLETE,
      payload,
    };
  },
  getStudentRewardsError(payload) {
    return {
      type: CreditsActions.GET_STUDENT_REWARDS_ERROR,
      payload,
    };
  },
  getReward(id) {
    return async (dispatch, getState) => {
      dispatch(CreditsActions.setGetRewardLoading(true));

      let error, response, success;
      try {
        const {
          myJewishU: { data: { eduStudentEnrollmentID } = {} },
          sys: { jewishUProgramID },
        } = getState();

        const requestURL = `EduPrograms/${jewishUProgramID}/Rewards/${id}?${queryString.stringify(
          { eduStudentEnrollmentID },
        )}`;
        response = await AuthRequest.get(`${requestURL}`);
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(CreditsActions.getRewardError(error));
        return;
      }

      const reward = response.data.payload;

      dispatch(CreditsActions.getRewardComplete(reward));

      return reward;
    };
  },
  setGetRewardLoading(payload) {
    return {
      type: CreditsActions.SET_GET_REWARD_LOADING,
      payload,
    };
  },
  getRewardComplete(payload) {
    return {
      type: CreditsActions.GET_REWARD_COMPLETE,
      payload,
    };
  },
  getRewardError(payload) {
    return {
      type: CreditsActions.GET_REWARD_ERROR,
      payload,
    };
  },
  doRewardRequest(rewardRequest) {
    return async (dispatch, getState) => {
      dispatch(CreditsActions.setRewardRequestLoading(true));

      let error, response, success;
      try {
        const {
          myJewishU: { data: { eduStudentEnrollmentID } = {} },
          sys: { jewishUProgramID },
        } = getState();
        response = await AuthRequest.post(
          `EduPrograms/${jewishUProgramID}/RewardRequests`,
          {
            ...rewardRequest,
            eduStudentEnrollmentID,
          },
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(CreditsActions.rewardRequestError(error));
        return undefined;
      }

      dispatch(CreditsActions.rewardRequestComplete(response.data.payload));

      return response.data;
    };
  },
  setRewardRequestLoading(payload) {
    return {
      type: CreditsActions.SET_REWARD_REQUEST_LOADING,
      payload,
    };
  },
  rewardRequestComplete(payload) {
    return {
      type: CreditsActions.REWARD_REQUEST_COMPLETE,
      payload,
    };
  },
  clearRewardsRequest(payload) {
    return {
      type: CreditsActions.CLEAR_REWARDS_REQUEST,
      payload,
    };
  },
  rewardRequestError(payload) {
    return {
      type: CreditsActions.REWARD_REQUEST_ERROR,
      payload,
    };
  },
};
