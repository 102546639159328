import React from "react";
import AccountPageWrapper from "./components/AccountPageWrapper.vm";
import Loader from "../../components/Loader";
import ValidatedInput from "../../components/ValidatedInput";

import {
  handleErrorMessage,
  handlePreventDefault,
  validateEmail,
  Navigation,
} from "../../lib";

export default class RecoverPass extends React.PureComponent {
  state = {
    recoveryEmail: "",
    showFormValidation: false,
    submitFormAttempted: false,
  };

  isIncompleteRecoverPass = () => {
    const { recoveryEmail } = this.state;

    return !recoveryEmail || !validateEmail(recoveryEmail);
  };

  onBlurInput = (e) => {
    const trimmedValue = e.target.value.trim();
    this.setState({ [e.target.name]: trimmedValue });
  };

  onChangeInput = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = handlePreventDefault(() => {
    this.setState(
      {
        showFormValidation: true,
        submitFormAttempted: true,
      },
      async () => {
        if (this.isIncompleteRecoverPass()) {
          return;
        }

        const { recoveryEmail } = this.state;
        await this.props.actions.doRecoverPass(recoveryEmail);

        if (!this.props.error) {
          this.toggleRecoveryEmailSent(recoveryEmail);
        } else {
          this.setState({ showFormValidation: false });
        }
      },
    );
  });

  onResendEmailClick = () => {
    this.setState(
      {
        recoveryEmail: "",
        showFormValidation: false,
        submitFormAttempted: false,
      },
      () => {
        this.toggleRecoveryEmailSent();
      },
    );
  };

  toggleRecoveryEmailSent = (recoveryEmail) => {
    const {
      pageRoute: {
        location: { pathname },
      },
    } = this.props;
    Navigation.redirect(pathname, { recoveryEmailSentTo: recoveryEmail });
  };

  render() {
    const {
      pageRoute: {
        location: { state: locationState },
      },
      loading,
      error,
    } = this.props;
    const { recoveryEmailSentTo } = locationState || {};
    const { recoveryEmail, showFormValidation, submitFormAttempted } =
      this.state;

    return (
      <AccountPageWrapper>
        {!recoveryEmailSentTo ? (
          <React.Fragment>
            <div>
              <h1 className="uppercase-text fw-600 xl-text text-center mb-16">
                Set Your Password
              </h1>
              <p
                className="medium-text line-height-double mb-40"
                style={{ letterSpacing: ".5px" }}
              >
                Type in your email address below and we'll send you an email
                with instructions on how to create a password
              </p>
            </div>
            <div>
              <form className="account-form">
                <ValidatedInput
                  label="Email"
                  name="recoveryEmail"
                  onBlur={this.onBlurInput}
                  onChange={this.onChangeInput}
                  required={true}
                  showRequired={showFormValidation}
                  showValidation={showFormValidation}
                  validate={(email) => validateEmail(email)}
                  validationMessage="Invalid email address"
                  value={recoveryEmail}
                />

                {!loading ? (
                  <React.Fragment>
                    <button
                      className="btn btn-large btn-fullWidth btn-accent mt-16"
                      onClick={this.onSubmit}
                      type="submit"
                    >
                      Set Password
                    </button>

                    <div className="error-message-container mt-4">
                      <span className="error-message center">
                        {showFormValidation && this.isIncompleteRecoverPass()
                          ? "Please complete required/incomplete fields"
                          : !!error && submitFormAttempted
                          ? handleErrorMessage(error)
                          : ""}
                      </span>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="btn-large-loader btn-large mb-32">
                    <Loader />
                  </div>
                )}
              </form>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              <h1 className="uppercase-text fw-600 xl-text text-center mb-16">
                Set Password Link Sent
              </h1>
              <p
                className="medium-text line-height-double mb-24"
                style={{ letterSpacing: ".5px" }}
              >
                We've sent a set password email to {recoveryEmailSentTo}
              </p>
              <p
                className="medium-text line-height-double mb-40"
                style={{ letterSpacing: ".5px" }}
              >
                To create your password click the link in the email we sent you
                – easy! Didn’t receive the email? Check your junk mail or have
                another one sent to you by clicking the button below.
              </p>
            </div>
            <div>
              <button
                className="btn btn-large btn-fullWidth btn-accent mt-16"
                onClick={this.onResendEmailClick}
              >
                Resend Email
              </button>
            </div>
          </React.Fragment>
        )}
      </AccountPageWrapper>
    );
  }
}
