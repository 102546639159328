import React from "react";

export default ({ className, errorMessage, errorTitle }) => (
  <div className={`text-center mb-32 ${className || ""}`}>
    <img
      src="/images/error.svg"
      alt="error-bot"
      height="240"
      style={{ maxWidth: "100%" }}
    />
    <p className="xxl-text mt-24 mb-16">
      {errorTitle || "An error has occured"}
    </p>
    <p className="medium-text accent-text fw-500">
      {errorMessage || "Our server is being stubborn, please try again"}
    </p>
  </div>
);
