import { AuthRequest } from "../../lib";

export const ResetPassActions = {
  RESET_PASS_ERROR: "RESET_PASS_ERROR",
  RESET_PASS_COMPLETE: "RESET_PASS_COMPLETE",
  SET_RESET_PASS_LOADING: "SET_RESET_PASS_LOADING",

  doResetPass(oldPassword, newPassword) {
    return async function doingResetPass(dispatch, getState) {
      dispatch(ResetPassActions.setResetPassLoading(true));
      const response = await AuthRequest.post("Account/ResetPw", {
        oldPW: oldPassword,
        newPW: newPassword,
      }).catch((err) => {
        dispatch(ResetPassActions.resetPassError(err));
        return undefined;
      });
      if (!response) {
        return;
      }
      dispatch(ResetPassActions.resetPassComplete());
    };
  },
  doRecoverResetPass(password, email, token) {
    return async function doingRecoverResetPass(dispatch, getState) {
      dispatch(ResetPassActions.setResetPassLoading(true));
      const response = await AuthRequest.post("Account/ForgotPwReset", {
        email,
        newPw: password,
        token,
      }).catch((err) => {
        dispatch(ResetPassActions.resetPassError(err));
        return undefined;
      });
      if (!response) {
        return;
      }
      dispatch(ResetPassActions.resetPassComplete());
    };
  },
  setResetPassLoading(payload) {
    return {
      type: ResetPassActions.SET_RESET_PASS_LOADING,
      payload,
    };
  },
  resetPassComplete(payload) {
    return {
      type: ResetPassActions.RESET_PASS_COMPLETE,
      payload,
    };
  },
  resetPassError(payload) {
    return {
      type: ResetPassActions.RESET_PASS_ERROR,
      payload,
    };
  },
};
