import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { defaultTheme } from "./themes/defaultTheme";

import {
  AppRouter,
  ErrorSentry,
  setSentryUser,
  frontChatInit,
  gaInit,
  hasAuthRequestToken,
  setAuthRequestToken,
  onAuthFailed,
  appInsightsInit,
  loadPolyfills,
  logRocketInit,
} from "./lib";

import { AppArea, AppPages } from "./pages";
import {
  activateStore,
  AuthActions,
  MyJewishUActions,
  SysActions,
  store,
} from "./state";
import * as serviceWorker from "./serviceWorker";
import serviceWorkerConfig from "./serviceWorkerConfig";

import "./assets/css/styles.scss";
import { ThemeProvider } from "@mui/material";

loadPolyfills();

appInsightsInit();

gaInit();

logRocketInit();

AppRouter.configure({
  loginCheck: hasAuthRequestToken,
  loginPath: AppPages.account.login.path,
  loginRedirectParam: "after",
  pageOptions: {
    anon: false,
    pathExact: true,
  },
  rootArea: AppArea,
});

async function main() {
  await activateStore();
  preloadAuthToken();
  render();
}

function isIframe() {
  try {
    if (window.self === window.top) {
      return false; // site is not in iframe
    }
    // return iframe origin type
    return window.self.location.origin === window.top.location.origin
      ? "same" // same = site within the iframe matches the top level (site containing the iframe) origin (practical usage is for the survey completion popup)
      : "other"; // other = site within the iframe does not match the top level origin
  } catch (e) {
    return "other"; // if there is an error accessing window.top.location, origin is 'other'
  }
}

function preloadAuthToken() {
  onAuthFailed(async (err) => {
    return store.dispatch(AuthActions.handleAuthFailure(err));
  });

  const {
    auth: {
      accountID,
      token,
      expiration,
      person,
      userEmail,
      userEmailHash,
    } = {},
  } = store.getState();

  if (token) {
    setAuthRequestToken(token, expiration);
    if (accountID) {
      setSentryUser(accountID, person, userEmail);
    }
  }

  frontChatInit(userEmail, userEmailHash);
  if (isIframe() === "same") {
    // don't display front chat icon within an iframe with 'same' origin
    const frontChatContainer = document.getElementById("front-chat-container");
    if (frontChatContainer) frontChatContainer.style.display = "none";
  }
}
// preloadAuthToken(); // removing this method invocation bec it is invoked in main();

function render() {
  const isSameOriginIframe = isIframe() === "same"; // don't load system or user data within an iframe with 'same' origin
  ReactDOM.render(
    <ErrorSentry>
      <ThemeProvider theme={defaultTheme}>
        <ReduxProvider store={store}>
          <AppRouter
            loadSystemData={
              !isSameOriginIframe
                ? () => store.dispatch(SysActions.getSystemData())
                : null
            }
            loadUserData={
              !isSameOriginIframe && store.getState().auth.person
                ? () => store.dispatch(MyJewishUActions.getMyJewishU())
                : null
            }
          />
        </ReduxProvider>
      </ThemeProvider>
    </ErrorSentry>,
    document.getElementById("root"),
  );
}

if (process.env.NODE_ENV === "production") {
  serviceWorker.register(serviceWorkerConfig);
} else {
  serviceWorker.unregister();
}

main();
