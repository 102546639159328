import React from "react";
import { formatNumber, pluralizeText, PageLink } from "../../../../lib";
import Pages from "../../../../pages";

export default class LocationCard extends React.PureComponent {
  render() {
    const {
      isMyLocation,
      location: {
        chabadHouseName,
        chabadHouseSlug,
        defaultImageURL,
        eduProgramImageURLOverride,
        id: locationId,
        numOfCurrentCourses,
        numOfUpcomingCourses,
      },
    } = this.props;

    return (
      <PageLink
        className="location-card card"
        to={Pages.locations.locationDetails}
        params={{ locationId: chabadHouseSlug || locationId }}
      >
        <img
          src={
            eduProgramImageURLOverride ||
            defaultImageURL ||
            "/images/location-placeholder.jpg"
          }
          alt={chabadHouseName}
        />
        <div className="full-width relative">
          <p className="fw-600 large-text mb-16">{chabadHouseName}</p>
          <p className="accent-text">
            {formatNumber(numOfCurrentCourses) || "No"} Current{" "}
            {pluralizeText("course", numOfCurrentCourses)}
          </p>
          {numOfUpcomingCourses > 0 && (
            <p className="accent-text mt-8">
              {formatNumber(numOfUpcomingCourses)} Upcoming{" "}
              {pluralizeText("course", numOfUpcomingCourses)}
            </p>
          )}
          {isMyLocation && (
            <div className="flex flex-justify-end">
              <p className="my-location-tag">
                <i className="material-icons mr-8">place</i>
                <span>My Location</span>
              </p>
            </div>
          )}
        </div>
      </PageLink>
    );
  }
}
