import React from "react";
import Select from "../../../../components/form/Select";
import { Navigation, PageURL } from "../../../../lib";

export default class CourseSchedules extends React.PureComponent {
  onSelectCourseSchedule = (name, value) => {
    const { page, params, query } = this.props.pageRoute;

    const url = PageURL.to(page, params, {
      ...query,
      sched: value,
    });
    Navigation.redirect(url);
  };

  render() {
    const { courseLocationName, courseScheduleId, courseSchedules } =
      this.props;

    const { notes } =
      (courseScheduleId &&
        courseSchedules.filter((s) => s.id === courseScheduleId)[0]) ||
      "";

    return (
      courseSchedules && (
        <div className="course-details-section mb-80">
          <p className="course-details-section-title">Schedules</p>
          {courseSchedules.length > 0 ? (
            <React.Fragment>
              <p>Select a schedule below to view the class date and times</p>
              <div className="input-container course-schedule-select">
                <label>Schedule</label>
                <Select
                  name="courseScheduleId"
                  options={courseSchedules.map((cs) => ({
                    label: cs.name,
                    value: cs.id,
                  }))}
                  onChange={this.onSelectCourseSchedule}
                  value={courseScheduleId}
                />
              </div>
            </React.Fragment>
          ) : (
            <p>
              This course is not currently offered at{" "}
              {courseLocationName || "this location"}
            </p>
          )}
          {notes && (
            <div className="mt-24">
              <p className="medium-text fw-700 mb-16">Course Notes:</p>
              <p>{notes}</p>
            </div>
          )}
        </div>
      )
    );
  }
}
