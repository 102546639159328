import { ActivityActions } from "./actions";
import { AuthActions } from "../auth/actions";

export const ActivityState = {
  name: "activity",
  persist: false,
  defaults: {
    loading: false,
    success: undefined,
  },
  handlers: {
    [ActivityActions.SET_ACTIVITY_LOADING]: function setActivityLoading(
      state,
      action,
    ) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [ActivityActions.UPDATE_ACTIVITY]: function updateActivity(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    [AuthActions.DO_SIGNOUT]: function (state, action) {
      return ActivityState.defaults;
    },
  },
};
