import React, { forwardRef, useMemo, useRef } from "react";
import parsePhoneNumber from "libphonenumber-js";
import { InputAdornment, MenuItem, Select, Typography } from "@mui/material";
import {
  defaultCountries,
  FlagEmoji,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import "react-international-phone/build/index.css";
import { TextInput } from "./TextInput";
import { getCountryIdByCode, getDefaultCountryCode } from "../../lib";

const PhoneInput = React.memo(
  forwardRef(function PhoneInput(
    {
      className,
      countries,
      countryId,
      defaultCountryName,
      disabled,
      error,
      id,
      label,
      name,
      onBlur,
      onChange,
      onChangeCountry,
      value,
      validate,
      ...passProps
    },
    ref,
  ) {
    const defaultCountryCode = useMemo(
      () => getDefaultCountryCode(countries, countryId, defaultCountryName),
      [countries, countryId, defaultCountryName],
    );

    const supportedCountries = useMemo(() => {
      const supportedCountryCodes = countries.map((c) => c.code.toLowerCase());
      return defaultCountries.filter((c) => {
        const code = c[1];
        return (
          code === defaultCountryCode || // the country corresponding to the defaultCountryCode must be included
          supportedCountryCodes.includes(code)
        );
      });
    }, [countries, defaultCountryCode]);

    // the onChange callback provided to usePhoneInput is called immediately upon input mount, without any manual input change
    // we therefore maintain this ref, which is set to true in the manual change handlers below, to ensure that the initial change callback is disregarded
    const changeActivated = useRef(false);

    const { country, handlePhoneValueChange, inputRef, phone, setCountry } =
      usePhoneInput({
        countries: supportedCountries,
        defaultCountry: defaultCountryCode,
        onChange: ({ country, phone }) => {
          if (changeActivated.current) {
            const phoneCountryId = country
              ? getCountryIdByCode(country, countries)
              : null;
            onChange(phone, phoneCountryId);
            if (error) validatePhone(phone, phoneCountryId);
          }
        },
        value,
        disableDialCodeAndPrefix: true,
      });

    const validatePhone = (phone, phoneCountryId = countryId) => {
      let isValid = true;
      if (phone) {
        if (!phoneCountryId) {
          isValid = false;
        } else {
          const parsedPhoneNumber = parsePhoneNumber(
            phone,
            country.toUpperCase(),
          );
          isValid = parsedPhoneNumber?.isValid();
        }
      }
      validate(isValid);
    };

    return (
      <div
        className={`phone-input
                    ${error ? "error" : ""}
                    ${disabled ? "disabled" : ""}
                    ${className || ""}
                `}
      >
        <TextInput
          id={id || name}
          disabled={disabled}
          label={label}
          onChange={(e) => {
            if (!changeActivated.current) changeActivated.current = true;
            handlePhoneValueChange(e);
          }}
          onBlur={(e) => validatePhone(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") validatePhone(e.target.value);
          }}
          inputRef={inputRef}
          variant="standard"
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment
                position="start"
                style={{ marginRight: "2px", marginLeft: "-8px" }}
              >
                <Select
                  disabled={disabled}
                  MenuProps={{
                    style: {
                      height: "300px",
                      width: "360px",
                      top: "10px",
                      left: "-34px",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                  sx={{
                    width: "max-content",
                    // Remove default outline
                    fieldset: {
                      display: "none",
                    },
                    // Override default spacing
                    ".MuiSelect-select": {
                      padding: "8px",
                      paddingRight: "24px !important",
                    },
                    svg: {
                      right: 0,
                    },
                  }}
                  value={country || ""}
                  onChange={(e) => {
                    if (!changeActivated.current)
                      changeActivated.current = true;
                    setCountry(e.target.value);
                  }}
                  renderValue={(value) => <FlagEmoji iso2={value} />}
                >
                  {supportedCountries.map((c) => {
                    const country = parseCountry(c);
                    return (
                      <MenuItem key={country.iso2} value={country.iso2}>
                        <FlagEmoji
                          iso2={country.iso2}
                          style={{
                            height: "24px",
                            marginRight: "8px",
                          }}
                        />
                        <Typography style={{ marginRight: "8px" }}>
                          {country.name}
                        </Typography>
                        <Typography color="gray">
                          +{country.dialCode}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </InputAdornment>
            ),
          }}
          type="tel"
          ref={ref}
          value={phone}
          helperText={
            error && value ? (
              <span className="phone-input-error">Invalid Phone Number</span>
            ) : (
              ""
            )
          }
          {...passProps}
        />
      </div>
    );
  }),
);
export default PhoneInput;
