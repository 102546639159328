import React from "react";
import Pages from "../../pages";
import { PageLink, trimTrailingSlash } from "../../lib";

export default class NotFound extends React.PureComponent {
  render() {
    //Handle Redirects
    const {
      sys: { portalRedirects },
    } = this.props;
    if (portalRedirects && portalRedirects.length) {
      const { pathname } = window.location;
      const redirect = portalRedirects.find(
        (r) =>
          r.pathFrom?.toLowerCase() ===
          trimTrailingSlash(pathname)?.toLowerCase(),
      );
      if (redirect) {
        window.location.replace(redirect.pathTo);
        return null;
      }
    }

    return (
      <div className="page-not-found">
        <div className="page-not-found-bg" />
        <div className="page-not-found-circles">
          <div>
            <div className="circle1" />
            <div className="circle2" />
          </div>
        </div>
        <div className="flex flex-column flex-justify-center flex-align-center">
          <div className="rocket-text">
            <p>4</p>
            <img src="/images/rocket.svg" alt="" />
            <p>4</p>
          </div>
          <p
            className="medium-text fw-500 text-center mt-32 mb-32"
            style={{
              maxWidth: "328px",
              color: "#fff",
              lineHeight: "1.5",
            }}
          >
            We have Chabad Houses worldwide but we didn't make it to space just
            yet!
          </p>
          <PageLink to={Pages.main.home} className="btn home-btn">
            Back To Home
          </PageLink>
        </div>
      </div>
    );
  }
}
