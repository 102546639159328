import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { SysActions } from "../state";

import Dropzone from "react-dropzone";
import ImageCropperModal from "./ImageCropperModal";
import Loader from "./Loader";

class ImageWithCropUpload extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      droppedImage: null,
      loading: false,
      showCropperModal: false,
      showErrorMessage: false,
    };

    this.dropzoneRef = React.createRef();
  }

  onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const fileWithPreview = Object.assign(acceptedFiles[0], {
        previewURL: URL.createObjectURL(acceptedFiles[0]),
      });
      this.setState({ droppedImage: fileWithPreview, showCropperModal: true });
    }
  };

  onDropRejected = () => {
    this.setState({ showErrorMessage: true });
  };

  onFinishCrop = async (croppedImgData) => {
    const {
      actions: { uploadFile },
      imageType,
      name,
      onChange,
    } = this.props;
    const { droppedImage } = this.state;

    this.setState({ loading: true });

    const file = croppedImgData
      ? new File([croppedImgData], droppedImage.name, { type: "image/png" })
      : droppedImage;

    const fileURL = await uploadFile(file, imageType);
    onChange(name, fileURL);

    this.setState({ loading: false, showCropperModal: false });
  };

  render() {
    const {
      disabled,
      error,
      imageName,
      imageURL,
      label,
      uploadedLabel,
      uploadLink,
    } = this.props;
    const { droppedImage, loading, showCropperModal, showErrorMessage } =
      this.state;

    return (
      <div
        className={`flex flex-align-center dropzone ${
          disabled ? "disabled" : ""
        }`}
      >
        <Dropzone
          accept={"image/png,image/jpeg,image/gif,image/bmp,image/jpg"}
          disabled={disabled}
          multiple={false}
          onDrop={this.onDrop}
          onDropRejected={this.onDropRejected}
          ref={this.dropzoneRef}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className="profile-pic-container flex flex-align-center"
            >
              <input {...getInputProps()} />
              {loading ? (
                <Loader />
              ) : (
                <img
                  src={
                    imageURL || droppedImage
                      ? imageURL || droppedImage.previewURL
                      : "/images/profile-placeholder.svg"
                  }
                  alt=""
                />
              )}
            </div>
          )}
        </Dropzone>
        <div>
          {!(imageURL || droppedImage) ? (
            <>
              {label}
              <div
                className={`link-text uppercase-text relative ${
                  error ? "error" : ""
                }`}
                onClick={() => this.dropzoneRef.current.open()}
              >
                {uploadLink}
                <span
                  className="error-message"
                  style={{
                    left: "0",
                    top: "16px",
                    whiteSpace: "nowrap",
                    textTransform: "none",
                  }}
                >
                  {showErrorMessage
                    ? "Something went wrong, please try again"
                    : ""}
                </span>
              </div>
            </>
          ) : (
            <>
              {uploadedLabel}
              <div
                className={`link-text uppercase-text relative`}
                onClick={() => this.dropzoneRef.current.open()}
              >
                {uploadLink}
                <span
                  className="error-message"
                  style={{
                    left: "0",
                    top: "16px",
                    whiteSpace: "nowrap",
                    textTransform: "none",
                  }}
                >
                  {showErrorMessage
                    ? "Something went wrong, please try again"
                    : ""}
                </span>
              </div>
            </>
          )}
        </div>
        {droppedImage && (
          <ImageCropperModal
            imageName={imageName}
            imageURL={droppedImage.previewURL}
            show={showCropperModal}
            submitCrop={this.onFinishCrop}
          />
        )}
      </div>
    );
  }
}
export default connect(null, function mapDispatch(dispatch) {
  return {
    actions: {
      ...bindActionCreators(SysActions, dispatch),
    },
  };
})(ImageWithCropUpload);
