import { AuthRequest } from "../../lib";

export const CampusesActions = {
  SET_GET_CAMPUSES_LOADING: "SET_GET_CAMPUSES_LOADING",
  GET_CAMPUSES_COMPLETE: "GET_CAMPUSES_COMPLETE",
  GET_CAMPUSES_ERROR: "GET_CAMPUSES_ERROR",

  getCampuses() {
    return async (dispatch) => {
      dispatch(CampusesActions.setGetCampusesLoading(true));

      let error, response, success;
      try {
        response = await AuthRequest.get("Campuses/Basic");
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(CampusesActions.getCampusesError(error));
        return undefined;
      }

      dispatch(CampusesActions.getCampusesComplete(response.data));

      return response.data;
    };
  },
  setGetCampusesLoading(payload) {
    return {
      type: CampusesActions.SET_GET_CAMPUSES_LOADING,
      payload,
    };
  },
  getCampusesComplete(payload) {
    return {
      type: CampusesActions.GET_CAMPUSES_COMPLETE,
      payload,
    };
  },
  getCampusesError(payload) {
    return {
      type: CampusesActions.GET_CAMPUSES_ERROR,
      payload,
    };
  },
};
