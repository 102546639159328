import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { PageLink, pluralizeText } from "../../lib";
import { withAppInsights } from "../../lib/AppInsights";
import {
  CreditsActions,
  CreditsSelectors,
  AuthSelectors,
  JewishUSelectors,
} from "../../state";
import ErrorDisplay from "../../components/ErrorDisplay";
import Loader from "../../components/Loader";
import withLayout from "../../components/layout/withLayout";
import RewardRequestModal from "../credits/components/myCredits/rewards/RewardRequestModal";
import Pages from "../../pages";
import { PlatinumTripsCredits } from "../credits/CreditsConsts";
const { REACT_APP_COCI_STUDENT_PORTAL_DOMAIN } = process.env;

const TripDetailsPage = ({
  pageRoute: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();

  //SELECTORS
  const user = useSelector(AuthSelectors.user);
  const eduStudentEnrollmentID = useSelector(
    JewishUSelectors.eduStudentEnrollmentID,
  );
  const studentRewardData = useSelector(CreditsSelectors.studentRewardData);
  const rewardRequest = useSelector(CreditsSelectors.rewardRequest);
  const { error } = useSelector(CreditsSelectors.reward);

  //STATE
  const [reward, setReward] = useState();
  const [showRewardRequestModal, setShowRewardRequestModal] = useState(false);

  useEffect(() => {
    const getReward = async () => {
      const response = await dispatch(CreditsActions.getReward(id));
      setReward(response);
    };
    if (!reward) {
      getReward();
    }
  }, [dispatch, reward, id]);

  useEffect(() => {
    if (eduStudentEnrollmentID) {
      if (user) {
        dispatch(CreditsActions.getStudentRewards());
      }
      dispatch(CreditsActions.getStudent(true));
    }
  }, [dispatch, user, eduStudentEnrollmentID]);

  const { numOfCreditsAvailable } = studentRewardData;
  const {
    creditsRestrictedFromDate,
    name,
    numOfQualifyingStudentCredits,
    shortDescription,
    tripEvent,
    tripType,
    typeDisplay,
  } = reward || {};
  const {
    hasTripStarted,
    importantInformation,
    isTripRegistrationOpen,
    tripEndDate,
    tripPriceInCredits,
    tripProgramScheduleID,
    tripPromotionalPriceInCredits,
    tripStartDate,
    tripRegistrationEndDate,
    imageURL,
    videoURL,
    tripItineraryHighlights = [],
    tripGalleryImages = [],
  } = tripEvent || {};

  const backgroundImage = imageURL || "/images/credits-background.jpg";

  const tripTypeDisplay =
    tripPriceInCredits === PlatinumTripsCredits ? "Platinum trip" : typeDisplay;
  const isTripRegistrationAvailable = !!user && isTripRegistrationOpen;
  const minTripPriceInCredits =
    tripPromotionalPriceInCredits || tripPriceInCredits;
  const numOfCreditsAvailableForReward = creditsRestrictedFromDate
    ? numOfQualifyingStudentCredits
    : numOfCreditsAvailable;
  const canRedeemTrip = numOfCreditsAvailableForReward >= minTripPriceInCredits;

  // redirect to trip application in student portal
  const tripApplicationUrl =
    `${REACT_APP_COCI_STUDENT_PORTAL_DOMAIN}trips/${tripType}` +
    (tripProgramScheduleID ? `/${tripProgramScheduleID}` : "");

  const tripNameDisplay = (
    <div className="xxxl-text fw-700 text-center">
      <div className="trip-reward-type">{tripTypeDisplay}</div>
      <p className="trip-reward-title">{name}</p>
    </div>
  );

  return error ? (
    <ErrorDisplay />
  ) : reward ? (
    <div className="text-center">
      {isTripRegistrationAvailable && (
        <div className="banner fw-500 medium-text">
          Registration for the {name} {tripTypeDisplay} is open through{" "}
          {moment(tripRegistrationEndDate).format("MMMM Do")}.
        </div>
      )}
      {videoURL ? (
        <div className="video-container mb-16">
          <video
            className="background-video"
            poster={backgroundImage}
            width="100%"
            height="360"
            controls
            playsInline
            autoPlay
            muted
            loop
          >
            <source src={videoURL} type="video/mp4" />
          </video>
          <div className="title">{tripNameDisplay}</div>
        </div>
      ) : (
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
          className="trip-details-header xxxl-text fw-700 title"
        >
          {tripNameDisplay}
        </div>
      )}
      {(shortDescription ||
        (!hasTripStarted &&
          (tripStartDate || tripPriceInCredits || importantInformation))) && (
        <div className="top-section-header-container mb-48">
          <div className="sub-title">{shortDescription}</div>

          {!hasTripStarted && (
            <div className="flex flex-justify-center full-width mobile-block">
              {tripStartDate && tripEndDate && (
                <div className="trip-reward-dates mr-40 flex flex-align-center">
                  <i className="material-icons mr-8  trip-icon-img  large-text">
                    calendar_today
                  </i>

                  {moment(tripStartDate).format("MMMM Do")}
                  {" - "}
                  {moment(tripEndDate).format("MMMM Do")}
                </div>
              )}
              {!!tripPriceInCredits && (
                <div className="trip-reward-dates mr-40 flex flex-align-center">
                  <i className="material-icons mr-8 trip-icon-img large-text">
                    card_giftcard
                  </i>
                  {"Cost: "}
                  {tripPromotionalPriceInCredits ? (
                    <p className="ml-8 mr-8">
                      <img
                        src={`/images/JU_Strikethrough_red.svg`}
                        alt="jewish learning institute"
                        height={"16"}
                        style={{
                          marginRight: -28,
                          marginBottom: -2,
                          position: "relative",
                        }}
                      />
                      {tripPriceInCredits} {tripPromotionalPriceInCredits}*
                    </p>
                  ) : (
                    tripPriceInCredits
                  )}
                  {pluralizeText(" Credit", tripPriceInCredits)} (
                  {creditsRestrictedFromDate
                    ? `Earned since ${moment(creditsRestrictedFromDate).format(
                        "M/D/YYYY",
                      )}`
                    : "Earned within one school year"}
                  )
                </div>
              )}
            </div>
          )}
          {!hasTripStarted && importantInformation && (
            <div className="mt-48 medium-text">{importantInformation}</div>
          )}
        </div>
      )}
      <div>
        {tripItineraryHighlights.length ? (
          <div className="mt-64 mb-64">
            <p className="xxxl-text accent-text-secondary fw-700 mb-24">
              Itinerary Highlights
            </p>
            {!hasTripStarted && (
              <p className="mb-24 medium-text">
                Dates and details subject to change
              </p>
            )}
            <div className="trip-itinerary-background">
              {tripItineraryHighlights
                .sort((a, b) => a.dayNumber - b.dayNumber)
                .map((highlight, i) => (
                  <div className="trip-reward-info trip-itinerary-grid" key={i}>
                    <div className="trip-itinerary-container">
                      <div className="trip-itinerary">
                        <div className="day-info">
                          <div className="day">DAY</div>
                          <div className="number">{highlight.dayNumber}</div>
                        </div>
                        <div>
                          <div className="trip-itinerary-title accent-text-secondary mb-8">
                            {highlight.title}
                          </div>
                          <div className="trip-itinerary-description medium-text mobile-hidden">
                            {highlight.description}
                          </div>
                        </div>
                      </div>
                      <div className="trip-itinerary-description medium-text desktop-hidden tablet-hidden">
                        {highlight.description}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="mb-64 mt-64">
          <PageLink to={Pages.grandTrip.tripFaqs}>
            <button className="btn btn-accent btn-medium">View FAQs</button>
          </PageLink>

          {isTripRegistrationAvailable && (
            <button
              className="btn btn-accent btn-medium ml-16 mt-40 mb-48"
              onClick={() =>
                canRedeemTrip
                  ? (window.location = tripApplicationUrl)
                  : setShowRewardRequestModal(true)
              }
            >
              Redeem Now
            </button>
          )}
        </div>

        {tripGalleryImages.length ? (
          <div>
            <p className="xxxl-text accent-text-secondary fw-700 mt-64 mb-64">
              Gallery
            </p>
            <div className="gallery-grid">
              {tripGalleryImages.map((img) => (
                <div key={img.url} className="gallery-image mr-40 mb-40">
                  <img src={img.url} alt="trip-gallery-item" />
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {showRewardRequestModal && (
        <RewardRequestModal
          close={() => setShowRewardRequestModal(false)}
          numOfCreditsAvailable={numOfCreditsAvailableForReward}
          requestReward={CreditsActions.doRewardRequest}
          reward={reward}
          rewardRequest={rewardRequest}
          currencyCode="USD" // rewards redeemed for trips always use USD currency
        />
      )}
    </div>
  ) : (
    <Loader className="full-page-loader" />
  );
};

export default memo(withLayout()(withAppInsights(TripDetailsPage)));
