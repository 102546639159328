import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { LocationsActions } from "../../state";
import LocationsListPage from "./LocationsListPage";

export default connect(
  function mapState(state) {
    return {
      ...state.locations.locations,
      myJewishU: state.myJewishU,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...LocationsActions,
        },
        dispatch,
      ),
    };
  },
)(LocationsListPage);
