import { AuthRequest } from "../../lib";
import { CoursesActions } from "../states";

export const MyJewishUActions = {
  SET_GET_MY_JEWISH_U_LOADING: "SET_GET_MY_JEWISH_U_LOADING",
  GET_MY_JEWISH_U_COMPLETE: "GET_MY_JEWISH_U_COMPLETE",
  GET_MY_JEWISH_U_ERROR: "GET_MY_JEWISH_U_ERROR",
  UPDATE_MY_JEWISH_U: "UPDATE_MY_JEWISH_U",

  JEWISH_U_ENROLLMENT_COMPLETE: "JEWISH_U_ENROLLMENT_COMPLETE",
  JEWISH_U_ENROLLMENT_ERROR: "JEWISH_U_ENROLLMENT_ERROR",
  SET_JEWISH_U_ENROLLMENT_LOADING: "SET_JEWISH_U_ENROLLMENT_LOADING",

  getMyJewishU() {
    return async (dispatch, getState) => {
      dispatch(MyJewishUActions.setGetMyJewishULoading(true));

      let error, response, success;
      try {
        const {
          sys: { jewishUProgramID },
        } = getState();
        response = await AuthRequest.get(
          `EduStudents/student?eduProgramId=${jewishUProgramID}`,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(MyJewishUActions.getMyJewishUError(error));
        return undefined;
      }

      dispatch(MyJewishUActions.getMyJewishUComplete(response.data.payload));

      return response.data;
    };
  },
  setGetMyJewishULoading(payload) {
    return {
      type: MyJewishUActions.SET_GET_MY_JEWISH_U_LOADING,
      payload,
    };
  },
  getMyJewishUComplete(payload) {
    return {
      type: MyJewishUActions.GET_MY_JEWISH_U_COMPLETE,
      payload,
    };
  },
  getMyJewishUError(payload) {
    return {
      type: MyJewishUActions.GET_MY_JEWISH_U_ERROR,
      payload,
    };
  },
  updateMyJewishU(payload) {
    return {
      type: MyJewishUActions.UPDATE_MY_JEWISH_U,
      payload,
    };
  },

  doJewishUEnrollment(courseScheduleId) {
    return async (dispatch, getState) => {
      dispatch(MyJewishUActions.setJewishUEnrollmentLoading(true));

      let error, response, success;
      try {
        const {
          sys: { jewishUProgramID },
        } = getState();

        response = await AuthRequest.post("EduStudents", {
          eduProgramID: jewishUProgramID,
        });
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(MyJewishUActions.jewishUEnrollmentError(error));
        return undefined;
      }
      //refresh my jewishU info
      await dispatch(MyJewishUActions.getMyJewishU());
      await dispatch(CoursesActions.doCourseEnrollment(courseScheduleId));
      dispatch(
        MyJewishUActions.jewishUEnrollmentComplete(response.data.payload),
      );

      return response.data;
    };
  },
  setJewishUEnrollmentLoading(payload) {
    return {
      type: MyJewishUActions.SET_JEWISH_U_ENROLLMENT_LOADING,
      payload,
    };
  },
  jewishUEnrollmentComplete(payload) {
    return {
      type: MyJewishUActions.JEWISH_U_ENROLLMENT_COMPLETE,
      payload,
    };
  },
  jewishUEnrollmentError(payload) {
    return {
      type: MyJewishUActions.JEWISH_U_ENROLLMENT_ERROR,
      payload,
    };
  },
};
