export default {
  /** @param {ServiceWorkerRegistration} reg */
  onUpdate(reg) {
    reg.unregister().then(() => {
      window.location.reload();
    });
  },
  /** @param {ServiceWorkerRegistration} reg */
  onSuccess(reg) {
    console.info("Service worker registered successfully.");
  },
};
