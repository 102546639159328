import React from "react";
import ProfileForm from "./components/ProfileForm.vm";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";
import { Navigation } from "../../lib";

class Profile extends React.PureComponent {
  render() {
    const { previousLocationPath, previousLocationTitle } =
      Navigation.location.state || {};
    const showBackToPrevious = previousLocationPath && previousLocationTitle;
    const { pageRoute } = this.props;
    return (
      <div className="page container my-profile-page">
        <div className="flex flex-align-center">
          {showBackToPrevious ? (
            <span
              className="link-text flex flex-align-center mb-16"
              style={{ width: "fit-content" }}
              onClick={() => Navigation.goBack()}
            >
              <i className="material-icons xl-text mr-8">arrow_back</i>
              Back To {previousLocationTitle}
            </span>
          ) : null}
        </div>
        <ProfileForm pageRoute={pageRoute} />
      </div>
    );
  }
}

export default withLayout()(withAppInsights(Profile));
