import React from "react";
import AccountDropdown from "./AccountDropdown";
import MobileMenuLink from "./MobileMenuLink";
import MenuLink from "./MenuLink";
import MyCoursesMenu from "./MyCoursesMenu";
import MyLocationMenu from "./MyLocationMenu";
import Pages from "../../pages";
import {
  getVersionedImgSrc,
  isIncompleteProfile,
  Navigation,
  PageLink,
  PageURL,
} from "../../lib";

export default class Layout extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      myJewishU: { data: { eduStudentEnrollmentID } = {} },
      pageRoute: {
        page,
        query: { sched: courseSchedId, enroll: isEnrollingInCourse } = {},
      },
      user,
    } = props;

    this.state = {
      accountDropdownOpen: false,
      isMobile: this.isMobile(),
      mobileMenuOpen: false,
      myCoursesDropdownOpen: false,
      myLocationDropdownOpen: false,

      //ju enrollment required to access page for logged-in user who is (not enrolled in ju and signing up for course)
      //aside from on actual enrollment pg (obviously!), and profile pg (to enable students to update profile to correct campus to enable enrollment)
      jewishUEnrollmentRedirectRequired:
        user &&
        courseSchedId &&
        isEnrollingInCourse &&
        !eduStudentEnrollmentID &&
        page.path !== Pages.myJewishU.enroll.path &&
        page.path !== Pages.profile.studentProfile.path,
    };
  }

  checkForProfileRedirect() {
    const {
      profile,
      user,
      myJewishU: { data: { eduStudentEnrollmentID } = {} },
      pageRoute: { page, query } = {},
    } = this.props;
    const isEnrollingInCourse = query?.enroll && query?.sched;
    if (
      !!Object.keys(profile).length &&
      user &&
      page.path !== Pages.profile.studentProfile.path
    ) {
      //always redirect to profile page if profile incomplete except when enrolling for JU
      if (
        isIncompleteProfile(profile, []) &&
        !(isEnrollingInCourse && !eduStudentEnrollmentID)
      ) {
        this.redirectToProfilePage();
      }
    }
  }

  componentDidMount() {
    const { studentId, profile, actions } = this.props;
    if (this.state.jewishUEnrollmentRedirectRequired) {
      this.redirectToJewishUEnrollment();
    }

    if (studentId) {
      if (Object.keys(profile).length === 0) {
        actions.getProfile(studentId);
      }
    }

    this.checkForProfileRedirect();

    window.addEventListener("resize", this.checkIsMobile);
  }

  componentDidUpdate() {
    const {
      user,
      myJewishU: { data: { eduStudentEnrollmentID } = {} },
      pageRoute: { page, query } = {},
    } = this.props;

    const isEnrollingInCourse = query?.enroll && query?.sched;

    const jewishUEnrollmentRedirectRequired =
      user &&
      isEnrollingInCourse &&
      !eduStudentEnrollmentID &&
      page.path !== Pages.myJewishU.enroll.path &&
      page.path !== Pages.profile.studentProfile.path;

    this.checkForProfileRedirect();

    if (jewishUEnrollmentRedirectRequired) {
      this.redirectToJewishUEnrollment();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkIsMobile);
  }

  isMobile = () => window.innerWidth <= 600;
  checkIsMobile = () => {
    if (this.isMobile()) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  redirectToJewishUEnrollment = () => {
    const {
      pageRoute: { query },
    } = this.props;

    const newQuery =
      this.props.pageRoute.page !== Pages.main.home ? { ...query } : null;

    Navigation.redirect(PageURL.to(Pages.myJewishU.enroll, null, newQuery));
  };

  redirectToProfilePage = () => {
    const {
      pageRoute: { query, page },
      myJewishU: { data: { eduChabadHouse: myLocation } = {} },
    } = this.props;

    const _query = {
      ...query,
      isRedirectToCompleteProfile: true,
      afterprofile:
        page !== Pages.main.home
          ? Navigation.locationURL
          : myLocation
          ? Pages.locations.locationDetails.path
          : Pages.locations.locations.path,
    };

    Navigation.redirect(
      PageURL.to(
        Pages.profile.studentProfile,
        myLocation
          ? {
              locationId: myLocation.slug || myLocation.id,
            }
          : null,
        _query,
      ),
    );
  };

  toggleAccountDropdown = () => {
    this.setState({ accountDropdownOpen: !this.state.accountDropdownOpen });
  };

  toggleMobileMenu = (open = null) => {
    this.setState({
      mobileMenuOpen: open != null ? open : !this.state.mobileMenuOpen,
    });
  };

  render() {
    const {
      actions,
      goBack,
      myJewishU: {
        data: {
          eduChabadHouse: myLocation,
          eduCourseSchedules: myCourses,
        } = {},
      },
      pageRoute,
      title,
      ui: { isPhone },
      user,
    } = this.props;
    const { id: userId } = user || {};
    const {
      accountDropdownOpen,
      isMobile,
      jewishUEnrollmentRedirectRequired,
      mobileMenuOpen,
      myCoursesDropdownOpen,
      myLocationDropdownOpen,
    } = this.state;

    return (
      <React.Fragment>
        <header className="header">
          <div className="container">
            <div className="nav-container">
              <PageLink to={Pages.main.home} className="mr-16">
                <img src="/images/jewish-u-logo.svg" alt="logo" />
              </PageLink>

              <React.Fragment>
                <div className="desktop-hidden tablet-hidden mobile-hidden flex flex-align-center">
                  {goBack ? (
                    <i
                      className="material-icons accent-text-secondary pointer"
                      style={{ position: "absolute" }}
                      onClick={pageRoute.history.goBack}
                    >
                      arrow_back
                    </i>
                  ) : (
                    <div
                      className={
                        mobileMenuOpen ? "mobile-menu menu-open" : "mobile-menu"
                      }
                    >
                      <span
                        className="menu-icon-wrapper"
                        onClick={() => this.toggleMobileMenu()}
                      >
                        <span className="menu-icon" />
                      </span>
                      <div className="mobile-menu-content">
                        <ul className="mobile-nav-links">
                          <MobileMenuLink
                            onClick={() => this.toggleMobileMenu(false)}
                            title="Home"
                            to={Pages.main.home}
                          />
                          {!userId || !myCourses || !myCourses.length ? (
                            <MobileMenuLink
                              onClick={() => this.toggleMobileMenu(false)}
                              title="Courses"
                              to={Pages.courses.courses}
                            />
                          ) : (
                            <MobileMenuLink
                              dropdownMenu={
                                <MyCoursesMenu
                                  menuOpen={myCoursesDropdownOpen}
                                  myCourses={myCourses}
                                />
                              }
                              dropdownOpen={myCoursesDropdownOpen}
                              onClick={() => this.toggleMobileMenu(false)}
                              title="Courses"
                              to={Pages.courses.courses}
                              toggleDropdown={() =>
                                this.setState({
                                  myCoursesDropdownOpen: !myCoursesDropdownOpen,
                                })
                              }
                            />
                          )}
                          {!userId || !myLocation ? (
                            <MobileMenuLink
                              onClick={() => this.toggleMobileMenu(false)}
                              title="Locations"
                              to={Pages.locations.locations}
                            />
                          ) : (
                            <MobileMenuLink
                              dropdownMenu={
                                <MyLocationMenu
                                  menuOpen={myLocationDropdownOpen}
                                  myLocation={myLocation}
                                />
                              }
                              dropdownOpen={myLocationDropdownOpen}
                              onClick={() => this.toggleMobileMenu(false)}
                              title="Locations"
                              to={Pages.locations.locations}
                              toggleDropdown={() =>
                                this.setState({
                                  myLocationDropdownOpen:
                                    !myLocationDropdownOpen,
                                })
                              }
                            />
                          )}
                          <MobileMenuLink
                            onClick={() => this.toggleMobileMenu(false)}
                            title="Credits"
                            to={Pages.credits.credits}
                          />
                          <MobileMenuLink
                            onClick={() => this.toggleMobileMenu(false)}
                            title="About"
                            to={Pages.about.about}
                          />
                          <MobileMenuLink
                            onClick={() => this.toggleMobileMenu(false)}
                            title="Contact"
                            to={Pages.contact.contact}
                          />
                        </ul>
                      </div>
                    </div>
                  )}
                  <p className="ml-40 fw-700 medium-text">{title}</p>
                </div>
                <nav className="mobile-hidden">
                  <ul className="nav-links">
                    {!userId || !myCourses || !myCourses.length ? (
                      <MenuLink title="Courses" to={Pages.courses.courses} />
                    ) : (
                      <MenuLink
                        className="nav-link no-hover program-dropdown-container"
                        dropdownMenu={
                          <MyCoursesMenu
                            menuOpen={myCoursesDropdownOpen}
                            myCourses={myCourses}
                          />
                        }
                        title="Courses"
                        to={Pages.courses.courses}
                      />
                    )}
                    {!userId || !myLocation ? (
                      <MenuLink
                        title="Locations"
                        to={Pages.locations.locations}
                      />
                    ) : (
                      <MenuLink
                        className="nav-link no-hover program-dropdown-container"
                        dropdownMenu={
                          <MyLocationMenu
                            menuOpen={myLocationDropdownOpen}
                            myLocation={myLocation}
                          />
                        }
                        title="Locations"
                        to={Pages.locations.locations}
                      />
                    )}
                    <MenuLink title="Credits" to={Pages.credits.credits} />
                    <MenuLink title="Trips" to={Pages.grandTrip.grandTrip} />
                    <MenuLink title="About" to={Pages.about.about} />
                    <MenuLink title="Contact" to={Pages.contact.contact} />
                  </ul>
                </nav>
              </React.Fragment>

              <p className="page-title desktop-hidden tablet-hidden medium-text fw-700">
                {title}
              </p>
            </div>
            <div className="header-right">
              {userId || isMobile ? (
                <div className="flex">
                  <AccountDropdown
                    doSignout={actions.doSignout}
                    dropdownOpen={accountDropdownOpen}
                    myCourses={myCourses}
                    myLocation={myLocation}
                    toggleDropdown={this.toggleAccountDropdown}
                    user={user}
                  />
                  <p className="bh xs-text ml-16">ב״ה</p>
                </div>
              ) : (
                <div className="flex">
                  <div className="flex flex-align-center">
                    <PageLink
                      className="link-text-secondary fw-500"
                      to={Pages.account.login}
                    >
                      Log in
                    </PageLink>
                    <PageLink
                      className="btn btn-accent ml-24 mr-16"
                      to={Pages.locations.locations}
                    >
                      Begin Today
                    </PageLink>
                  </div>
                  <p className="bh xs-text">ב״ה</p>
                </div>
              )}
            </div>
          </div>
        </header>
        <div
          className={
            !isPhone ? "page-wrapper" : "page-wrapper page-wrapper-mobile"
          }
          accountdropdownopen={accountDropdownOpen ? "true" : null}
          mobilemenuopen={mobileMenuOpen ? "true" : null}
        >
          {/* will not render content if redirect is required because can potentially cause memory leaks by state setting triggered in mount on the imediately unmounted component */}
          {!jewishUEnrollmentRedirectRequired && this.props.children}
        </div>
        <footer className="footer">
          <div className="footer-top">
            <div className="container">
              <PageLink to={Pages.main.home}>
                <img
                  src="/images/footer/logo-footer.svg"
                  alt="logo"
                  // height="56"
                />
              </PageLink>
              <p className="line-height-double">
                Jewish wisdom is enriching and empowering. JewishU makes it
                accessible, structured, and rewarding for Jewish college
                students. It is a most effective platform for high-quality
                Jewish education during the formative years of higher education.
              </p>
              <div>
                <p className="footer-column-title mb-32 fw-600 medium-text">
                  Contact Info
                </p>
                <p className="line-height-double mb-8">
                  719 Eastern Parkway, Suite 1 <br />
                  Brooklyn, NY 11213
                </p>
                <a
                  href="tel:7185108181"
                  className="line-height-double block mb-8"
                >
                  718-510-8181
                </a>
                <a
                  href="mailto:help@jewishu.org"
                  className="line-height-double block"
                >
                  help@jewishu.org
                </a>
              </div>
              <div>
                <p className="footer-column-title mb-32 fw-600 medium-text">
                  Helpful Links
                </p>
                <ul className="footer-nav-links">
                  <MenuLink title="Courses" to={Pages.courses.courses} />
                  <MenuLink title="Locations" to={Pages.locations.locations} />
                  <MenuLink title="Credits" to={Pages.credits.credits} />
                  <MenuLink title="About" to={Pages.about.about} />
                  <MenuLink title="Contact" to={Pages.contact.contact} />
                </ul>
              </div>
              <div className="social-links">
                <a
                  href="https://instagram.com/chabadoncampus/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src="/images/footer/instagram.png" alt="instagram" />
                </a>
                <a
                  href="https://facebook.com/ChabadOnCampus/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src="/images/footer/facebook.png" alt="facebook" />
                </a>
                <a
                  href="https://twitter.com/chabadoncampus/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src="/images/footer/twitter.png" alt="twitter" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-bottom flex flex-justify-space flex-align-center container">
            <div className="flex flex-align-center">
              <img
                src={getVersionedImgSrc("footer/coci-logo-small.png")}
                alt="Chabad on Campus"
                height="32"
              />
              <p className="fw-500 ml-16">
                &copy; {new Date().getFullYear()} JewishU
              </p>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
