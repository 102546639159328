import React from "react";
import AccountPageWrapper from "./components/AccountPageWrapper.vm";
import GoogleIdentitySignin from "./components/GoogleIdentitySignin";
import Loader from "../../components/Loader";
import LoginRegisterHeader from "./components/LoginRegisterHeader";
import ValidatedInput from "../../components/ValidatedInput";

import { withAppInsights } from "../../lib/AppInsights";
import {
  handleErrorMessage,
  handleInputState,
  handlePreventDefault,
  validateEmail,
  PageLink,
} from "../../lib";
import Pages from "../../pages";

class Login extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      email: "",
      error: null,
      password: "",
      showFormValidation: false,
      submitFormAttempted: false,
    };

    this.state = {
      ...state,
      initialState: state,
    };

    this.handleChangeInput = handleInputState(this);
  }

  isIncompleteSignin = () => {
    const { email, password } = this.state;

    return !email || !password || !this.validateLoginEmail(email);
  };

  onChangeInput = (e) => {
    this.handleChangeInput(e);

    if (this.state.error) {
      this.setState({ error: null });
    }
  };

  onBasicSignin = handlePreventDefault(() => {
    this.setState(
      {
        error: null,
        showFormValidation: true,
        submitFormAttempted: true,
      },
      async () => {
        if (this.isIncompleteSignin()) {
          return;
        }

        const { email, password } = this.state;

        await this.props.actions.doBasicSignin({
          email,
          password,
        });

        if (this.props.error) {
          this.setState({
            error: this.props.error,
            password: "",
            showFormValidation: false,
          });
        }
      },
    );
  });

  // onFacebookSignin = async fbUser => {
  //   await this.props.actions.doFacebookSignin(
  //     fbUser,
  //     this.state.submitFormAttempted
  //   ); //for login tracking purposes

  //   if (this.props.error) {
  //     this.setState({
  //       error: this.props.error
  //     });
  //   }
  // };

  onGoogleSignin = () => {
    if (this.props.error) {
      this.setState({
        error: this.props.error,
        showFormValidation: false,
        submitFormAttempted: true,
      });
    }
  };

  validateLoginEmail = (email) => {
    const isLoginImpersonation = email.indexOf("||") > 0;
    if (isLoginImpersonation) {
      return true;
    }

    return validateEmail(email);
  };

  handleLogin401ErrorMessage = (error) => {
    if (error.data) {
      const {
        defaultMessage,
        email,
        isInvalidEmail,
        isInvalidPassword,
        isMissingPassword,
        isRestrictedUser,
      } = error.data;

      if (isInvalidEmail) {
        return (
          <span>
            No account found for {email}. Click{" "}
            <PageLink
              className="link-text"
              query={this.props.pageRoute.query}
              to={Pages.account.register}
            >
              here
            </PageLink>{" "}
            to create an account.
          </span>
        );
      } else if (isInvalidPassword) {
        return "Password is incorrect";
      } else if (isMissingPassword) {
        return (
          <span>
            A password has not been set up for your account. Click{" "}
            <PageLink className="link-text" to={Pages.account.recoverPass}>
              here
            </PageLink>{" "}
            to set an account password.
          </span>
        );
      } else if (isRestrictedUser) {
        return `User ${email} does not have access to this portal`;
      } else if (defaultMessage) {
        return defaultMessage;
      }
    }

    return "Email and/or password is incorrect";
  };

  render() {
    const {
      pageRoute: {
        location: { state: locationState },
        query,
      },
      loading,
    } = this.props;
    const { email, error, password, showFormValidation, submitFormAttempted } =
      this.state;

    const { resetPassRedirect } = locationState || {};
    const registerForCourse =
      query && query.after && query.after.indexOf("course") >= 0;

    return (
      <AccountPageWrapper logoLinkPage={Pages.main.home}>
        <LoginRegisterHeader pageLinkQuery={query} />

        {resetPassRedirect ? (
          <p className="flex reset-password-success line-height-double">
            <i className="material-icons mr-8">check_circle</i>Your password has
            been set successfully. Log in again and you're good to go.
          </p>
        ) : registerForCourse ? (
          <div className="flex flex-align-center flex-justify-center accent-text">
            <p>
              To register for a course please{" "}
              <PageLink
                className="link-text"
                query={query}
                to={Pages.account.register}
              >
                create an account
              </PageLink>{" "}
              or log in below
            </p>
          </div>
        ) : null}

        <p className="mt-40 mb-24 text-center medium-text fw-600 line-height-double">
          Log in with your Chabad on Campus Account
        </p>

        <div className="login-btns">
          <GoogleIdentitySignin onSubmit={this.onGoogleSignin} />
        </div>
        <p className="login-options-text fw-600 medium-text">or</p>
        <p className="mt-24 mb-24 text-center medium-text">log in with email</p>
        <form className="account-form">
          <ValidatedInput
            label="Email"
            name="email"
            onChange={this.onChangeInput}
            required={true}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={this.validateLoginEmail}
            validationMessage="Invalid email address"
            value={email}
          />
          <ValidatedInput
            label="Password"
            input={<input type="password" />}
            name="password"
            onChange={this.onChangeInput}
            required={true}
            showRequired={showFormValidation}
            value={password}
          />
          {!loading ? (
            <React.Fragment>
              <button
                className="btn btn-large btn-fullWidth btn-accent mt-16"
                onClick={this.onBasicSignin}
                type="submit"
              >
                Log in
              </button>

              <div className="mt-4" style={{ minHeight: "32px" }}>
                <span
                  className="error-message center block"
                  style={{ position: "static" }}
                >
                  {showFormValidation && this.isIncompleteSignin()
                    ? "Please complete required/incomplete fields"
                    : !!error && submitFormAttempted
                    ? error.status === 401
                      ? this.handleLogin401ErrorMessage(error)
                      : handleErrorMessage(
                          error,
                          "There was an error logging in. Please try again",
                        )
                    : ""}
                </span>
              </div>
            </React.Fragment>
          ) : (
            <div className="btn-large-loader btn-large mb-32">
              <Loader />
            </div>
          )}

          <p className="text-center line-height-double">
            Existing Messaging User?
            <br />
            You can set your password{" "}
            <PageLink
              to={Pages.account.recoverPass}
              className="link-text"
              style={{ textDecoration: "underline" }}
            >
              here
            </PageLink>
          </p>
          <PageLink
            to={Pages.account.recoverPass}
            className="link-text block text-center mt-32"
            style={{ textDecoration: "underline" }}
          >
            Forgot password?
          </PageLink>
          <p className="text-center mt-32">
            Don't have an account?
            <PageLink
              to={Pages.account.register}
              className="link-text ml-8"
              style={{ textDecoration: "underline" }}
            >
              Create one here
            </PageLink>
          </p>
        </form>
      </AccountPageWrapper>
    );
  }
}

export default withAppInsights(Login);
