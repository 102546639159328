import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ResendConfirmationActions } from "../../state";
import ResendConfirmation from "./ResendConfirmation";

export default connect(
  function mapState(state) {
    return {
      ...state.resendRegistrationConfirmation,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(ResendConfirmationActions, dispatch),
    };
  },
)(ResendConfirmation);
