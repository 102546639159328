import React from "react";
import Select from "../../components/form/Select";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";
import { Navigation, PageURL, PageLink } from "../../lib";
import Pages from "../../pages";

class LocationsPage extends React.PureComponent {
  state = {
    campusId: null,
  };

  componentDidMount() {
    this.getLocationCampuses();
  }

  getLocationCampuses = () => {
    this.props.actions.getLocationCampuses();
  };

  goToCampusLocation = () => {
    const { campusId } = this.state;
    var campus =
      campusId && this.props.data.find((c) => c.campusID === campusId);

    if (!campus) {
      return;
    }

    var locationUrl = PageURL.to(
      Pages.locations.locationDetails,
      {
        locationId: campus.chabadHouseSlug || campus.eduChabadHouseEnrollmentID,
      },
      null,
    );
    Navigation.go(locationUrl);
  };

  onSelectCampus = (campusId) => {
    this.setState({ campusId });
  };

  render() {
    const {
      data: locationCampuses = [],
      error,
      loading,
      myJewishU: { data: { eduChabadHouse: myLocation } = {} },
    } = this.props;
    const { campusId } = this.state;

    return (
      <div className="page locations-search-page">
        <div className="locations-header">
          <img src="/images/location-placeholder.jpg" alt="" />
          <div>
            <p className="locations-title">Ancient wisdom available near you</p>
            <p className="xl-text line-height-double mt-24 mb-40">
              Enter a University name to find a local JewishU
            </p>
            <div className="search-input mb-40">
              <div className="flex flex-align-center" style={{ flex: 1 }}>
                <i className="material-icons accent-text-secondary mr-8">
                  search
                </i>
                <Select
                  clearable={true}
                  disabled={loading}
                  getOptionLabel={(o) =>
                    o.isMyLocation ? `${o.label}  (My Location)` : o.label
                  }
                  onChange={(name, val) => this.onSelectCampus(val)}
                  options={
                    locationCampuses &&
                    locationCampuses.map((c) => ({
                      value: c.campusID,
                      label: c.campusName,
                      isMyLocation:
                        myLocation &&
                        c.eduChabadHouseEnrollmentID === myLocation.id,
                    }))
                  }
                  placeholder={
                    loading ? "Loading Campuses..." : "Search Campuses..."
                  }
                  searchable={true}
                  value={campusId}
                />
              </div>

              {!!campusId && (
                <button
                  className="btn btn-accent"
                  onClick={this.goToCampusLocation}
                >
                  GO
                </button>
              )}

              {error && (
                <p className="error-text">
                  Sorry, something went wrong and we could not retrieve search
                  JewishU campuses. Please refresh the page to try again.
                </p>
              )}
            </div>
            <PageLink
              className="link-text-secondary medium-text underline-text"
              to={Pages.locations.locationsList}
            >
              View All Locations
            </PageLink>
            {myLocation && (
              <div className="mt-80 tablet-mt-40">
                <p className="mb-16 fw-700 large-text">
                  Or visit your location
                </p>
                <PageLink
                  className="link-text-secondary medium-text underline-text"
                  to={Pages.locations.locationDetails}
                  params={{
                    locationId: myLocation.slug || myLocation.id,
                  }}
                >
                  {myLocation.name}
                </PageLink>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withLayout()(withAppInsights(LocationsPage));
