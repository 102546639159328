import { CoursesActions } from "./actions";
import { AuthActions } from "../auth/actions";

export const CoursesState = {
  name: "courses",
  persist: false,
  defaults: {
    allCourses: {
      data: [],
      error: undefined,
      loading: false,
      success: undefined,
    },
    courses: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    course: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    enrollment: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    enrollmentUpdate: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    enrollmentCancellation: {
      error: undefined,
      loading: false,
      success: undefined,
    },
  },
  handlers: {
    [CoursesActions.SET_GET_ALL_COURSES_LOADING]: function (state, action) {
      return {
        ...state,
        allCourses: {
          ...state.allCourses,
          loading: !!action.payload,
        },
      };
    },
    [CoursesActions.GET_ALL_COURSES_COMPLETE]: function (state, action) {
      return {
        ...state,
        allCourses: {
          ...state.allCourses,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [CoursesActions.GET_ALL_COURSES_ERROR]: function (state, action) {
      return {
        ...state,
        allCourses: {
          ...state.allCourses,
          loading: false,
          success: false,
          error: action.payload,
          data: [],
        },
      };
    },
    [CoursesActions.RESET_ALL_COURSES]: function (state, action) {
      return {
        ...state,
        allCourses: CoursesState.defaults.allCourses,
      };
    },

    [CoursesActions.SET_GET_COURSES_LOADING]: function (state, action) {
      return {
        ...state,
        courses: {
          ...state.courses,
          loading: !!action.payload,
        },
      };
    },
    [CoursesActions.GET_COURSES_COMPLETE]: function (state, action) {
      return {
        ...state,
        courses: {
          ...state.courses,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [CoursesActions.GET_COURSES_ERROR]: function (state, action) {
      return {
        ...state,
        courses: {
          ...state.courses,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },
    [CoursesActions.RESET_COURSES]: function (state, action) {
      return {
        ...state,
        courses: CoursesState.defaults.courses,
      };
    },

    [CoursesActions.SET_GET_COURSE_LOADING]: function (state, action) {
      return {
        ...state,
        course: {
          ...state.course,
          loading: !!action.payload,
        },
      };
    },
    [CoursesActions.GET_COURSE_COMPLETE]: function (state, action) {
      return {
        ...state,
        course: {
          ...state.course,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [CoursesActions.GET_COURSE_ERROR]: function (state, action) {
      return {
        ...state,
        course: {
          ...state.course,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },
    [CoursesActions.RESET_COURSE]: function (state, action) {
      return {
        ...state,
        course: CoursesState.defaults.course,
        enrollment: CoursesState.defaults.enrollment,
        enrollmentCancellation: CoursesState.defaults.enrollmentCancellation,
      };
    },

    [CoursesActions.SET_COURSE_ENROLLMENT_LOADING]: function (state, action) {
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          loading: !!action.payload,
        },
      };
    },
    [CoursesActions.COURSE_ENROLLMENT_COMPLETE]: function (state, action) {
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [CoursesActions.COURSE_ENROLLMENT_ERROR]: function (state, action) {
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },
    [CoursesActions.RESET_COURSE_ENROLLMENT]: function (state, action) {
      return {
        ...state,
        enrollment: CoursesState.defaults.enrollment,
      };
    },

    [CoursesActions.SET_COURSE_ENROLLMENT_UPDATE_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollmentUpdate: {
          ...state.enrollmentUpdate,
          loading: !!action.payload,
        },
      };
    },
    [CoursesActions.COURSE_ENROLLMENT_UPDATE_COMPLETE]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollmentUpdate: {
          ...state.enrollmentUpdate,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [CoursesActions.COURSE_ENROLLMENT_UPDATE_ERROR]: function (state, action) {
      return {
        ...state,
        enrollmentUpdate: {
          ...state.enrollmentUpdate,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },
    [CoursesActions.RESET_COURSE_ENROLLMENT_UPDATE]: function (state, action) {
      return {
        ...state,
        enrollmentUpdate: CoursesState.defaults.enrollment,
      };
    },

    [CoursesActions.SET_COURSE_ENROLLMENT_CANCELLATION_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollmentCancellation: {
          ...state.enrollmentCancellation,
          loading: !!action.payload,
        },
      };
    },
    [CoursesActions.COURSE_ENROLLMENT_CANCELLATION_COMPLETE]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollmentCancellation: {
          ...state.enrollmentCancellation,
          loading: false,
          success: true,
          error: undefined,
        },
      };
    },
    [CoursesActions.COURSE_ENROLLMENT_CANCELLATION_ERROR]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollmentCancellation: {
          ...state.enrollmentCancellation,
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    },
    [CoursesActions.RESET_COURSE_ENROLLMENT_CANCELLATION]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollmentCancellation: CoursesState.defaults.enrollmentCancellation,
      };
    },
    [AuthActions.DO_SIGNOUT]: function (state, action) {
      return CoursesState.defaults;
    },
  },
};
