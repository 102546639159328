import React from "react";
import JewishUEnrollment from "./components/JewishUEnrollment";
import ProfileForm from "../profile/components/ProfileForm.vm";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";
import { Navigation, PageURL } from "../../lib";
import Pages from "../../pages";

class JewishUEnrollmentPage extends React.PureComponent {
  state = {
    checkProfileStatus: true,
    profileStatusCheckError: false,
    showCompleteProfileForm: false,
  };

  componentDidMount() {
    const {
      myJewishU: { data: { eduChabadHouse, eduStudentEnrollmentID } = {} },
      pageRoute: {
        query: { sched: courseScheduleId },
      },
    } = this.props;
    //if already enrolled in jewishu and not registering for course on pg mount (via direct link, o/w should not get here), redirect to location pg / home
    if (!courseScheduleId && eduStudentEnrollmentID) {
      Navigation.redirect(
        eduChabadHouse
          ? PageURL.to(
              Pages.locations.locationDetails,
              {
                locationId: eduChabadHouse.slug || eduChabadHouse.id,
              },
              null,
            )
          : Pages.main.home.path,
      );
    }
  }

  onProfileIsComplete = (success = true) => {
    if (this.state.showCompleteProfileForm) {
      this.setState({
        showCompleteProfileForm: false,
      });
    }

    if (!success) {
      this.setState({
        profileStatusCheckError: true,
      });
    }
  };

  render() {
    const {
      actions: { doJewishUEnrollment },
      myJewishU,
      pageRoute,
    } = this.props;
    const {
      checkProfileStatus,
      profileStatusCheckError,
      showCompleteProfileForm,
    } = this.state;

    return (
      <div className="page container">
        {profileStatusCheckError ? (
          <div className="flex flex-align-center flex-justify-center flex-column mt-40 mb-40">
            <img src="/images/error.svg" alt="error" height="200" />
            <p
              className="medium-text fw-500 line-height-double mb-16 text-center"
              style={{ maxWidth: 400 }}
            >
              Sorry, something went wrong and we could not retrieve your profile
              information for JewishU enrollment.
            </p>
            <p>Please reload the page to try again.</p>
          </div>
        ) : checkProfileStatus || showCompleteProfileForm ? (
          <ProfileForm
            pageRoute={pageRoute}
            isCompleteProfilePrompt={true}
            onProfileFetched={(success, isProfileComplete) => {
              this.setState({
                checkProfileStatus: false,
                showCompleteProfileForm: !isProfileComplete,
              });
              if (!success) {
                //need to show error msg if profile retrieval failed
                this.onProfileIsComplete(false);
              }
            }}
            onProfileIsComplete={() => this.onProfileIsComplete()}
          />
        ) : (
          <JewishUEnrollment
            enroll={doJewishUEnrollment}
            myJewishU={myJewishU}
            pageRoute={pageRoute}
          />
        )}
      </div>
    );
  }
}

export default withLayout()(withAppInsights(JewishUEnrollmentPage));
