import React from "react";
import { NavLink } from "react-router-dom";

import { PageURL } from "./PageURL";

export function PageNavLink(props) {
  const {
    activeClassName: _activeClassName,
    activeStyle: _activeStyle,
    children,
    exact: _exact,
    isActive: _isActive,
    location: _location,
    params,
    query,
    story,
    strict: _strict,
    to: page,
    ...otherProps
  } = props;
  const linkTo = PageURL.link(page, params, query);
  const navProps = {
    activeClassName: _activeClassName,
    activeStyle: _activeStyle,
    exact: _exact,
    isActive: _isActive,
    location: _location,
    story,
    strict: _strict,
  };
  return (
    <NavLink to={linkTo} {...navProps} {...otherProps}>
      {children}
    </NavLink>
  );
}
export default PageNavLink;
