import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { CoursesActions } from "../../state";
import MyCoursesPage from "./MyCoursesPage";

export default connect(
  function mapState(state) {
    return {
      ...state.courses.courses,
      myJewishU: state.myJewishU,
      sys: state.sys,
      user: state.auth.person,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...CoursesActions,
        },
        dispatch,
      ),
    };
  },
)(MyCoursesPage);
