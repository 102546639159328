import { AuthRequest } from "../../lib";
import queryString from "query-string";

export const LocationsActions = {
  GET_ALL_LOCATIONS_COMPLETE: "GET_ALL_LOCATIONS_COMPLETE",
  GET_ALL_LOCATIONS_ERROR: "GET_ALL_LOCATIONS_ERROR",
  SET_GET_ALL_LOCATIONS_LOADING: "SET_GET_ALL_LOCATIONS_LOADING",
  RESET_ALL_LOCATIONS: "RESET_ALL_LOCATIONS",

  GET_LOCATIONS_COMPLETE: "GET_LOCATIONS_COMPLETE",
  GET_LOCATIONS_ERROR: "GET_LOCATIONS_ERROR",
  SET_GET_LOCATIONS_LOADING: "SET_GET_LOCATIONS_LOADING",
  RESET_LOCATIONS: "RESET_LOCATIONS",

  GET_LOCATION_CAMPUSES_COMPLETE: "GET_LOCATION_CAMPUSES_COMPLETE",
  GET_LOCATION_CAMPUSES_ERROR: "GET_LOCATION_CAMPUSES_ERROR",
  SET_GET_LOCATION_CAMPUSES_LOADING: "SET_GET_LOCATION_CAMPUSES_LOADING",
  RESET_LOCATION_CAMPUSES: "RESET_LOCATION_CAMPUSES",

  GET_LOCATION_COMPLETE: "GET_LOCATION_COMPLETE",
  GET_LOCATION_ERROR: "GET_LOCATION_ERROR",
  SET_GET_LOCATION_LOADING: "SET_GET_LOCATION_LOADING",
  RESET_LOCATION: "RESET_LOCATION",

  GET_LOCATION_COURSES_COMPLETE: "GET_LOCATION_COURSES_COMPLETE",
  GET_LOCATION_COURSES_ERROR: "GET_LOCATION_COURSES_ERROR",
  SET_GET_LOCATION_COURSES_LOADING: "SET_GET_LOCATION_COURSES_LOADING",
  RESET_LOCATION_COURSES: "RESET_LOCATION_COURSES",

  getLocationIdBySlug(locationSlug) {
    return async (dispatch, getState) => {
      if (locationSlug.toString() !== parseInt(locationSlug, 10).toString()) {
        let allLocationsList = getState().locations.allLocations.data;
        if (!allLocationsList || !allLocationsList.length) {
          allLocationsList = await dispatch(LocationsActions.getAllLocations());
        }

        const location =
          allLocationsList &&
          allLocationsList.find(
            (l) => l.chabadHouseSlug === decodeURIComponent(locationSlug),
          );
        if (location) {
          return location.id;
        }
      }

      return locationSlug;
    };
  },

  getAllLocations() {
    return async (dispatch, getState) => {
      dispatch(LocationsActions.setGetAllLocationsLoading(true));

      let error, response, success;
      try {
        const { jewishUProgramID } = getState().sys;
        response = await AuthRequest.get(
          `EduChabadHouses/Basic?eduProgramId=${jewishUProgramID}`,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(LocationsActions.getAllLocationsError(error));
        return undefined;
      }

      dispatch(LocationsActions.getAllLocationsComplete(response.data.payload));

      return response.data.payload;
    };
  },
  setGetAllLocationsLoading(payload) {
    return {
      type: LocationsActions.SET_GET_ALL_LOCATIONS_LOADING,
      payload,
    };
  },
  getAllLocationsComplete(payload) {
    return {
      type: LocationsActions.GET_ALL_LOCATIONS_COMPLETE,
      payload,
    };
  },
  getAllLocationsError(payload) {
    return {
      type: LocationsActions.GET_ALL_LOCATIONS_ERROR,
      payload,
    };
  },
  resetAllLocations() {
    return {
      type: LocationsActions.RESET_ALL_LOCATIONS,
    };
  },

  getLocations(page, results, filters = {}) {
    return async (dispatch, getState) => {
      dispatch(LocationsActions.setGetLocationsLoading(true));

      let error, response, success;
      try {
        const params = {
          eduProgramId: getState().sys.jewishUProgramID,
          page,
          results,
          sortByOption: "chabadHouseName",
          text: filters.search,
        };

        response = await AuthRequest.get(
          `EduChabadHouses/public?${queryString.stringify(params)}`,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(LocationsActions.getLocationsError(error));
        return undefined;
      }

      dispatch(LocationsActions.getLocationsComplete(response.data.payload));

      return response.data.payload;
    };
  },
  setGetLocationsLoading(payload) {
    return {
      type: LocationsActions.SET_GET_LOCATIONS_LOADING,
      payload,
    };
  },
  getLocationsComplete(payload) {
    return {
      type: LocationsActions.GET_LOCATIONS_COMPLETE,
      payload,
    };
  },
  getLocationsError(payload) {
    return {
      type: LocationsActions.GET_LOCATIONS_ERROR,
      payload,
    };
  },
  resetLocations() {
    return {
      type: LocationsActions.RESET_LOCATIONS,
    };
  },

  getLocationCampuses() {
    return async (dispatch, getState) => {
      dispatch(LocationsActions.setGetLocationCampusesLoading(true));

      let error, response, success;
      try {
        const params = {
          eduProgramId: getState().sys.jewishUProgramID,
          excludePending: true,
        };

        response = await AuthRequest.get(
          `EduChabadHouses/campuses?${queryString.stringify(params)}`,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(LocationsActions.getLocationCampusesError(error));
        return undefined;
      }

      dispatch(
        LocationsActions.getLocationCampusesComplete(response.data.payload),
      );

      return response.data.payload;
    };
  },
  setGetLocationCampusesLoading(payload) {
    return {
      type: LocationsActions.SET_GET_LOCATION_CAMPUSES_LOADING,
      payload,
    };
  },
  getLocationCampusesComplete(payload) {
    return {
      type: LocationsActions.GET_LOCATION_CAMPUSES_COMPLETE,
      payload,
    };
  },
  getLocationCampusesError(payload) {
    return {
      type: LocationsActions.GET_LOCATION_CAMPUSES_ERROR,
      payload,
    };
  },
  resetLocationCampuses() {
    return {
      type: LocationsActions.RESET_LOCATION_CAMPUSES,
    };
  },

  getLocation(locationId) {
    return async (dispatch, getState) => {
      dispatch(LocationsActions.setGetLocationLoading(true));

      let error, response, success;
      try {
        //get proper location id if locationId is slug
        if (locationId.toString() !== parseInt(locationId, 10).toString()) {
          locationId = await dispatch(
            LocationsActions.getLocationIdBySlug(locationId),
          );
        }

        response = await AuthRequest.get(
          `EduChabadHouses/${locationId}/public`,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(LocationsActions.getLocationError(error));
        return undefined;
      }

      dispatch(LocationsActions.getLocationComplete(response.data.payload));

      return response.data.payload;
    };
  },
  setGetLocationLoading(payload) {
    return {
      type: LocationsActions.SET_GET_LOCATION_LOADING,
      payload,
    };
  },
  getLocationComplete(payload) {
    return {
      type: LocationsActions.GET_LOCATION_COMPLETE,
      payload,
    };
  },
  getLocationError(payload) {
    return {
      type: LocationsActions.GET_LOCATION_ERROR,
      payload,
    };
  },
  resetLocation() {
    return {
      type: LocationsActions.RESET_LOCATION,
    };
  },

  getLocationCourses(locationId, page, results, filters = {}) {
    return async (dispatch, getState) => {
      dispatch(LocationsActions.setGetLocationCoursesLoading(true));

      let error, response, success;
      try {
        const {
          myJewishU: { data: { eduStudentEnrollmentID } = {} },
          sys: { jewishUProgramID },
        } = getState();

        const params = {
          availableToEduChabadHouseEnrollmentID: locationId,
          category: filters.category,
          eduProgramID: jewishUProgramID,
          eduStudentEnrollmentID,
          limitToCurrentCourseSchedules: filters.currentlyOffered,
          page,
          results,
          sortByOption: "currentlyOffered",
        };

        response = await AuthRequest.get(
          `EduStudents/Courses?${queryString.stringify(params)}`,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(LocationsActions.getLocationCoursesError(error));
        return undefined;
      }

      dispatch(
        LocationsActions.getLocationCoursesComplete(response.data.payload),
      );

      return response.data.payload;
    };
  },
  setGetLocationCoursesLoading(payload) {
    return {
      type: LocationsActions.SET_GET_LOCATION_COURSES_LOADING,
      payload,
    };
  },
  getLocationCoursesComplete(payload) {
    return {
      type: LocationsActions.GET_LOCATION_COURSES_COMPLETE,
      payload,
    };
  },
  getLocationCoursesError(payload) {
    return {
      type: LocationsActions.GET_LOCATION_COURSES_ERROR,
      payload,
    };
  },
  resetLocationCourses() {
    return {
      type: LocationsActions.RESET_LOCATION_COURSES,
    };
  },
};
