import React from "react";
import { PageLink } from "../../../../lib";

export default class CourseLocations extends React.PureComponent {
  state = {
    showMoreLocations: false,
  };

  toggleShowMoreLocations = () =>
    this.setState({
      showMoreLocations: !this.state.showMoreLocations,
    });

  render() {
    const {
      locations,
      myLocation,
      pageRoute: { page, params },
    } = this.props;
    const { showMoreLocations } = this.state;

    return (
      <div className="course-details-section mb-80">
        <p className="course-details-section-title">Locations</p>
        {!locations || !locations.length ? (
          <p>This course is not currently being offered at any locations.</p>
        ) : (
          <div>
            {locations
              .slice(0, showMoreLocations ? locations.length : 5)
              .map((loc, index) => (
                <div
                  className="flex flex-align-center tablet-block mb-8"
                  key={index}
                >
                  <PageLink
                    className="link-text underline-text medium-text"
                    to={page}
                    params={params}
                    query={{ loc: loc.id }}
                  >
                    {loc.chabadHouseName}
                  </PageLink>
                  {myLocation && myLocation.id === loc.id && (
                    <p className="my-location-tag ml-16">
                      <i className="material-icons mr-4 large-text">place</i> My
                      Location
                    </p>
                  )}
                </div>
              ))}
            {locations.length > 5 && (
              <p
                className="link-text underline-text"
                onClick={this.toggleShowMoreLocations}
              >
                {showMoreLocations ? "Show less" : "Show more"}
              </p>
            )}
          </div>
        )}
      </div>
    );
  }
}
