import { ResendConfirmationActions } from "./actions";

export const ResendConfirmationState = {
  name: "resendRegistrationConfirmation",
  persist: false,
  defaults: {
    error: undefined,
    loading: false,
    success: undefined,
  },
  handlers: {
    [ResendConfirmationActions.RESEND_CONFIRMATION_ERROR]: function (
      state,
      action,
    ) {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response,
      };
    },
    [ResendConfirmationActions.SET_RESEND_CONFIRMATION_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [ResendConfirmationActions.RESEND_CONFIRMATION_COMPLETE]: function (
      state,
      action,
    ) {
      return {
        ...state,
        loading: false,
        success: true,
        error: undefined,
      };
    },
  },
};
