import React from "react";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import CourseSurveyLink from "./CourseSurveyLink";
import FadeOutMessage from "../../../../components/FadeOutMessage";
import {
  handleErrorMessage,
  PageLink,
  Navigation,
  PageURL,
} from "../../../../lib";
import Pages from "../../../../pages";
import moment from "moment";
import { Button } from "@mui/material";

export default class CourseEnrollmentActions extends React.PureComponent {
  state = {
    showLeaveCourseConfirmationModal: false,
  };

  render() {
    const {
      courseEnrollmentCancellation: {
        error: enrollmentCancellationError,
        loading: enrollmentCancellationLoading,
      },
      courseEnrollmentUpdate: { loading: enrollmentUpdateLoading },
      courseSchedule,
      courseScheduleEnrollment,
      isStudentEnrolledInCourse,
      isStudentEnrolledInCourseSchedule,
      myJewishUStatus,
      myLocation,
      onCancelCourseEnrollment,
      onEnrollInCourse,
      onRefreshCourseDetails,
      pageRoute: { page, params, query },
      studentEnrollmentAvailability: {
        isEnrollmentAvailable,
        isBlockedByCourseSchedule,
        isBlockedByStudentLocation,
      },
    } = this.props;

    const { showLeaveCourseConfirmationModal } = this.state;

    if (myJewishUStatus === "Rejected") {
      return null;
    }

    if (isStudentEnrolledInCourse && !isStudentEnrolledInCourseSchedule) {
      //student is registered for this course at another location
      return myLocation &&
        myLocation.id !== query.loc &&
        myLocation.slug !== query.loc ? (
        <p className="mt-16 accent-text-light-secondary fw-500 line-height-double">
          You {!courseScheduleEnrollment.didCompleteCourse && "are"} registered
          for this course at{" "}
          {courseScheduleEnrollment.eduCourseChabadHouseName ||
            "another location"}
          . View your course registration{" "}
          <PageLink
            className="link-text"
            query={{
              loc: courseScheduleEnrollment.eduCourseChabadHouseEnrollmentID,
              sched: courseScheduleEnrollment.eduCourseScheduleID,
            }}
            params={params}
            to={page}
          >
            there.
          </PageLink>
        </p>
      ) : null;
    }

    if (isBlockedByCourseSchedule) {
      //no course schedule selected
      return null;
    }

    if (isStudentEnrolledInCourseSchedule) {
      return (
        <div>
          {courseSchedule.canCancelRegistration && (
            <div>
              <button
                className="btn btn-accent-secondary mobile-full-width mb-8"
                onClick={() =>
                  this.setState({ showLeaveCourseConfirmationModal: true })
                }
                disabled={enrollmentCancellationLoading}
              >
                {!enrollmentCancellationLoading
                  ? "Leave course"
                  : "Leaving course..."}
              </button>
              {enrollmentCancellationError && (
                <FadeOutMessage
                  message={handleErrorMessage(
                    enrollmentCancellationError,
                    "Sorry, something went wrong and your course registration could not be cancelled.  Please try again.",
                  )}
                  style={{
                    position: "absolute",
                    marginTop: 8,
                    fontSize: 12,
                    display: "block",
                    lineHeight: 1.5,
                    maxWidth: 200,
                  }}
                />
              )}
            </div>
          )}
          <div>
            <p className="accent-text small-text uppercase-text mt-32 mb-16 fw-500">
              Registration Status
            </p>
            {courseScheduleEnrollment.eduCourseScheduleEnrollmentStatus ===
            "Pending" ? (
              "Pending"
            ) : courseScheduleEnrollment.eduCourseScheduleEnrollmentStatus ===
              "Rejected" ? (
              "Ineligible"
            ) : !courseSchedule.didClassesEnd ? (
              "Registered"
            ) : courseScheduleEnrollment.didCompleteCourse ? (
              "Course Completed"
            ) : (
              <div>
                <p>Course Incomplete</p>
                {!courseScheduleEnrollment.didCompleteAttendance && (
                  <p className="accent-text small-text mt-4">
                    Attendance Incomplete
                  </p>
                )}
                {!courseScheduleEnrollment.didCompleteSurvey && (
                  //TODO: Uncomment as part of COC-4404
                  // !courseSchedule.didSurveyDeadlinePass &&
                  <p className="accent-text small-text mt-4">
                    Pending Review & Evaluation
                  </p>
                )}
              </div>
            )}
          </div>
          {courseSchedule.didClassesEnd &&
            courseScheduleEnrollment.eduCourseScheduleEnrollmentStatus ===
              "Approved" &&
            !courseScheduleEnrollment.didCompleteSurvey && (
              //TODO: Uncomment as part of COC-4404
              //!courseSchedule.didSurveyDeadlinePass &&
              <CourseSurveyLink
                className="mt-16"
                courseScheduleEnrollment={courseScheduleEnrollment}
                loading={enrollmentUpdateLoading}
                refreshCourseDetails={onRefreshCourseDetails}
              />
            )}
          <ConfirmationModal
            cancel={() =>
              this.setState({
                showLeaveCourseConfirmationModal: false,
              })
            }
            cancelText="No"
            confirm={() => {
              this.setState({ showLeaveCourseConfirmationModal: false });
              onCancelCourseEnrollment();
            }}
            confirmText="Yes"
            message={`Are you sure you want to cancel your registration for ${
              courseSchedule.name || "this course"
            }?`}
            show={showLeaveCourseConfirmationModal}
          />
        </div>
      );
    }

    return (
      <div>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          sx={{ mb: 1 }}
          disabled={
            !isEnrollmentAvailable || !courseSchedule.isRegistrationOpen
          }
          onClick={onEnrollInCourse}
        >
          Register
        </Button>

        {isBlockedByStudentLocation ? (
          <>
            {myLocation ? (
              <p className="accent-text-light-secondary">
                Registration is only available for courses at{" "}
                <PageLink
                  className="link-text"
                  params={{ locationId: myLocation.slug || myLocation.id }}
                  to={Pages.locations.locationDetails}
                >
                  your location
                </PageLink>
              </p>
            ) : (
              <p className="accent-text-light-secondary">
                Your campus does not currently have a JewishU location. Review
                your campus in{" "}
                <PageLink
                  className="link-text"
                  to={PageURL.to(Pages.profile.studentProfile, null, {
                    afterprofile: Navigation.locationURL,
                  })}
                >
                  your profile.
                </PageLink>
              </p>
            )}
          </>
        ) : courseSchedule.isRegistrationOpen ? (
          courseSchedule.registrationEndDate && (
            <p className="text-center accent-text-secondary fw-700 line-height-double">
              Registration Closing on
              <br />
              {moment(courseSchedule.registrationEndDate).format(
                "MMMM D @ h:mm a",
              )}{" "}
              {courseSchedule.timezoneName}
            </p>
          )
        ) : courseSchedule.didRegistrationClose ? (
          <p className="text-center accent-text">Registration is closed</p>
        ) : courseSchedule.registrationStartDate ? (
          <p className="text-center accent-text-secondary fw-700 line-height-double">
            Registration Opens on
            <br />
            {moment(courseSchedule.registrationStartDate).format(
              "MMMM D @ h:mm a",
            )}{" "}
            {courseSchedule.timezoneName}
          </p>
        ) : (
          <p className="text-center accent-text">
            Registration is not yet open
          </p>
        )}
      </div>
    );
  }
}
