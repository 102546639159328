import React from "react";
import Checkbox from "../../../../components/form/Checkbox";
import CourseCard from "../../../courses/components/courses/CourseCard";
import PaginatedList from "../../../../components/PaginatedList";
import Select from "../../../../components/form/Select";
import { Navigation, PageURL } from "../../../../lib";

export default class LocationCourses extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = this.getStateFromURL();
    this.listTitleRef = React.createRef();
  }

  queryDefaults = {
    c: "",
    cu: false,
    p: 1,
    r: 12,
  };

  getStateFromURL = () => {
    const {
      pageRoute: {
        query: { c, cu, p, r },
      },
    } = this.props;

    return {
      filters: {
        category: c || this.queryDefaults.c,
        currentlyOffered: !!cu || this.queryDefaults.cu,
      },
      page: p || this.queryDefaults.p,
      resultsView: r || this.queryDefaults.r,
    };
  };

  matchURLToState = () => {
    const {
      pageRoute: { page: routePage, params, query },
    } = this.props;
    const {
      filters: { category, currentlyOffered },
      page,
      resultsView,
    } = this.state;

    Navigation.redirect(
      PageURL.to(routePage, params, {
        ...query,
        c: category && category !== this.queryDefaults.c ? category : undefined,
        cu: currentlyOffered ? true : undefined,
        p: page && page !== this.queryDefaults.p ? page : undefined,
        r:
          resultsView && resultsView !== this.queryDefaults.r
            ? resultsView
            : undefined,
      }),
    );
  };

  getCourses = () => {
    const { filters, page, resultsView } = this.state;

    this.props.getCourses(page, resultsView, filters);
  };

  paginateCourses = (page = this.state.page) => {
    this.setState({ page }, () => {
      this.matchURLToState();
      this.getCourses();
    });
  };

  filterCourses = (filter) => {
    this.setState(
      {
        filters: { ...this.state.filters, ...filter },
        page: 1,
      },
      () => {
        this.matchURLToState();
        this.getCourses();
      },
    );
  };

  getCategorizedCourses = (courses) => {
    const categorizedCourses = {};

    courses.forEach((course) => {
      var category = course.isCurrentlyOfferedByChabadHouse
        ? "Currently Offering"
        : course.isUpcomingByChabadHouse
        ? "Upcoming"
        : "Course Catalog";
      if (!categorizedCourses.hasOwnProperty(category)) {
        categorizedCourses[category] = [];
      }
      categorizedCourses[category].push(course);
    });

    return categorizedCourses;
  };

  renderCategorizedCourses = (courses) => {
    const categorizedCourses = this.getCategorizedCourses(courses);

    return Object.keys(categorizedCourses).map((categoryKey, categoryIndex) => (
      <React.Fragment key={categoryIndex}>
        <p className="courses-category-header">{categoryKey}</p>
        {this.renderCourses(categorizedCourses[categoryKey])}
      </React.Fragment>
    ));
  };

  renderCourses = (courses) => {
    const { locationId } = this.props;

    return courses.map((course, index) => (
      <CourseCard course={course} key={index} locationId={locationId} />
    ));
  };

  render() {
    const {
      courseCategories,
      data: { results: courses = [], numberOfRows: totalCourses = 0 },
      error,
      loading,
    } = this.props;
    const {
      filters: { category, currentlyOffered },
      page,
      resultsView,
    } = this.state;

    const courseCategoryOptions = [
      {
        label: "All courses",
        value: undefined,
      },
      ...(courseCategories
        ? courseCategories.map((ctgry) => ({
            label: ctgry.displayValue,
            value: ctgry.enumValue,
          }))
        : []),
    ];

    return (
      <div className="location-details-section">
        <p className="location-details-section-title" ref={this.listTitleRef}>
          JewishU Courses
        </p>
        <div className="flex flex-align-center flex-justify-space mobile-block mb-40">
          <div className="input-container course-category-select mr-16 mobile-mb-16">
            <label>Category</label>
            <Select
              defaultValue={courseCategoryOptions[0]}
              onChange={(name, val) => this.filterCourses({ category: val })}
              options={courseCategoryOptions}
              searchable={true}
              value={category}
            />
          </div>
          <Checkbox
            checked={currentlyOffered}
            label="Currently offering"
            labelClassName="flex-align-center"
            name="currentlyOffered"
            onChange={(name, val) => this.filterCourses({ [name]: val })}
          />
        </div>
        <PaginatedList
          className={`location-courses-cards-grid ${
            courses.length === 1 ? "single-card-grid" : ""
          }`}
          error={error}
          getRecords={this.paginateCourses}
          loaderClass="mt-80"
          loading={loading}
          name="courses"
          page={page}
          records={courses}
          renderRecords={
            currentlyOffered
              ? this.renderCourses // don't categorize courses if list is filtered to currentlyOffered
              : this.renderCategorizedCourses
          }
          resultsView={resultsView}
          scrollToRef={this.listTitleRef}
          totalCount={totalCourses}
        />
      </div>
    );
  }
}
