import React from "react";
import AccountPageWrapper from "./components/AccountPageWrapper.vm";
import { Navigation } from "../../lib";

export default class ConfirmedForSms extends React.PureComponent {
  render() {
    const { accountConfirmed } = Navigation.location.state || {}; // show confirmation success text if navigation state is present so that random navigation to this route doesn't show success msg

    return (
      <AccountPageWrapper>
        {!!accountConfirmed && (
          <div className="text-center fw-500">
            <img alt="" src="/images/check-circle.svg" className="mb-32" />
            <h1 className="uppercase-text fw-700 xxl-text mb-32">
              Good to Go!
            </h1>
            <p
              className="medium-text line-height-double mb-16"
              style={{ letterSpacing: ".5px" }}
            >
              Your email has been succesfully confirmed!
            </p>
            <p
              className="medium-text line-height-double"
              style={{ letterSpacing: ".5px" }}
            >
              You can now return to your text conversation to proceed
            </p>
          </div>
        )}
      </AccountPageWrapper>
    );
  }
}
