import React from "react";
import { PageNavLink } from "../../lib";
import Pages from "../../pages";

export default class MyLocationMenu extends React.PureComponent {
  render() {
    const { menuOpen, myLocation } = this.props;

    if (!myLocation) {
      return null;
    }

    return (
      <div
        className={`nav-menu-dropdown-box dropdown-box ${
          menuOpen ? "open" : ""
        }`}
      >
        <p className="fw-600">My Location</p>
        <PageNavLink
          activeClassName="active-nav-link"
          className="link-text-secondary"
          to={Pages.locations.locationDetails}
          params={{
            locationId: myLocation.slug || myLocation.id,
          }}
        >
          <p className="link-text-secondary mt-16">{myLocation.name}</p>
        </PageNavLink>
        <PageNavLink
          activeClassName="active-nav-link"
          className="link-text-secondary"
          exact
          to={Pages.locations.locations}
        >
          Explore all locations
        </PageNavLink>
      </div>
    );
  }
}
