import Confirm from "./Confirm.vm";
import ConfirmedForSms from "./ConfirmedForSms";
import Login from "./Login.vm";
import RecoverPass from "./RecoverPass.vm";
import RecoverResetPass from "./RecoverResetPass.vm";
import Register from "./Register.vm";
import ResendConfirmation from "./ResendConfirmation.vm";
import ResetPass from "./ResetPass.vm";

const accountPrefix = "/account";

export const AccountPages = {
  confirm: {
    anon: true,
    path: `${accountPrefix}/register/confirm`,
    title: "Confirm Email",
    type: "PAGE_CONFIRM_REGISTRATION",
    view: Confirm,
  },
  confirmedForSms: {
    anon: true,
    path: `${accountPrefix}/register/confirmed-sms`,
    title: "Email Confirmed",
    type: "PAGE_EMAIL_CONFIRMED_FOR_SMS",
    view: ConfirmedForSms,
  },
  login: {
    anon: true,
    path: `${accountPrefix}/login`,
    title: "Login Required",
    type: "PAGE_LOGIN",
    view: Login,
  },
  recoverPass: {
    anon: true,
    path: `${accountPrefix}/recover-password`,
    title: "Set Password",
    type: "PAGE_RECOVER_PASS",
    view: RecoverPass,
  },
  recoverResetPass: {
    anon: true,
    path: `${accountPrefix}/recover-password/reset`,
    title: "Set Password",
    type: "PAGE_RECOVER_RESET_PASS",
    view: RecoverResetPass,
  },
  register: {
    anon: true,
    path: `${accountPrefix}/register`,
    title: "Create Account",
    type: "PAGE_REGISTER",
    view: Register,
  },
  resendConfirmation: {
    anon: true,
    path: `${accountPrefix}/register/resend-confirmation`,
    title: "Resend Confirmation",
    type: "PAGE_RESEND_CONFIRMATION",
    view: ResendConfirmation,
  },
  resetPass: {
    anon: true,
    path: `${accountPrefix}/reset-password`,
    title: "Reset Password",
    type: "PAGE_RESET_PASS",
    view: ResetPass,
  },
};
export default AccountPages;

export const AccountArea = {
  pages: AccountPages,
};
