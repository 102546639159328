import React from "react";
import Checkbox from "../../../../components/form/Checkbox";
import CourseClasses from "./CourseClasses";
import FadeOutMessage from "../../../../components/FadeOutMessage";
import { handleErrorMessage } from "../../../../lib";

export default class CourseEnrollmentReview extends React.PureComponent {
  state = {
    didAcceptCourseTerms: false,
  };

  render() {
    const { didAcceptCourseTerms } = this.state;

    const {
      courseClasses,
      courseEnrollment: { error: enrollmentError, loading: enrollmentLoading },
      courseSchedule,
      confirmEnrollment,
    } = this.props;
    const { id: courseScheduleId, name: courseScheduleName } = courseSchedule;

    return (
      <div>
        <div className="course-details-section mb-80">
          <p className="course-details-section-title">Registration Review</p>
          <p className="line-height-double medium-text">
            Please review the details of the course you would like to register
            for. You are registering in {courseScheduleName}. This exciting
            course takes place on the dates listed below.
          </p>
        </div>

        <CourseClasses
          classes={courseClasses}
          courseSchedule={courseSchedule}
          hideDescription={true}
          title={`${courseScheduleName} Course Schedule`}
        />

        <div className="mt-48">
          <div className="medium-text accept-course-terms-checkbox">
            <Checkbox
              checked={didAcceptCourseTerms}
              label={`I agree and have reviewed the details of the ${courseScheduleName} course`}
              labelClassName="line-height-double"
              name="didAcceptCourseTerms"
              onChange={(name, value) => this.setState({ [name]: value })}
            />
            <button
              className="btn btn-accent mt-32 mobile-full-width"
              disabled={!didAcceptCourseTerms || enrollmentLoading}
              onClick={() => confirmEnrollment(courseScheduleId)}
            >
              {!enrollmentLoading ? "Confirm registration" : "Confirming..."}
            </button>
            {enrollmentError && (
              <FadeOutMessage
                message={handleErrorMessage(
                  enrollmentError,
                  "Sorry, something went wrong and your course registration could not be completed.  Please try again.",
                )}
                style={{
                  position: "absolute",
                  marginTop: 8,
                  fontSize: 12,
                  display: "block",
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
