import React from "react";
import { formatNumber, getCurrencySymbol } from "../../../../../lib";

export default class RewardCard extends React.PureComponent {
  render() {
    const {
      onRequestReward,
      reward: {
        creditValueUSD,
        creditValueGBP,
        creditValueCAD,
        name,
        type,
        typeDisplay,
      },
      currencyCode,
    } = this.props;

    const creditValue = () => {
      switch (currencyCode) {
        case "GBP":
          return creditValueGBP;
        case "CAD":
          return creditValueCAD;
        default:
          return creditValueUSD;
      }
    };

    return (
      <div
        className={`card link-text-secondary reward-card ${
          type && type.toLowerCase()
        }-reward`}
        onClick={onRequestReward}
      >
        <p className="uppercase-text xs-text fw-500 mb-8">{typeDisplay}</p>
        <p className="medium-text fw-600">{name}</p>

        <div className="reward-card-footer mt-32 flex flex-align-center flex-justify-space">
          <p className="fw-500">
            {getCurrencySymbol(currencyCode)}
            {formatNumber(creditValue())} per credit
          </p>
          <i className="material-icons xl-text">arrow_forward</i>
        </div>
      </div>
    );
  }
}
