import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { CoursesActions } from "../../state";
import CourseDetailsPage from "./CourseDetailsPage";

export default connect(
  function mapState(state) {
    return {
      ...state.courses.course,
      courseEnrollment: state.courses.enrollment,
      courseEnrollmentCancellation: state.courses.enrollmentCancellation,
      courseEnrollmentUpdate: state.courses.enrollmentUpdate,
      myJewishU: state.myJewishU,
      sys: state.sys,
      user: state.auth.person,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...CoursesActions,
        },
        dispatch,
      ),
    };
  },
)(CourseDetailsPage);
