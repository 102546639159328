import React from "react";
import MyCredits from "./components/MyCredits";
import PublicCredits from "./components/PublicCredits";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";

class CreditsPage extends React.PureComponent {
  render() {
    const { user } = this.props;

    return (
      <div className="page">
        {!user ? <PublicCredits /> : <MyCredits {...this.props} />}
      </div>
    );
  }
}

export default withLayout()(withAppInsights(CreditsPage));
