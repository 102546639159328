import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { RecoverPassActions } from "../../state";
import RecoverPass from "./RecoverPass";

export default connect(
  function mapState(state) {
    return {
      ...state.recoverPass,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          doRecoverPass: RecoverPassActions.doRecoverPass,
        },
        dispatch,
      ),
    };
  },
)(RecoverPass);
