import { CampusesActions } from "./actions";

export const CampusesState = {
  name: "campuses",
  persist: false,
  defaults: {
    data: [],
    error: undefined,
    loading: false,
    success: undefined,
  },
  handlers: {
    [CampusesActions.SET_GET_CAMPUSES_LOADING]: function (state, action) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [CampusesActions.GET_CAMPUSES_COMPLETE]: function (state, action) {
      return {
        ...state,
        loading: false,
        success: true,
        error: undefined,
        data: action.payload,
      };
    },
    [CampusesActions.GET_CAMPUSES_ERROR]: function (state, action) {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response,
        data: [],
      };
    },
  },
};
