import React from "react";
import {
  getCurrencySymbol,
  formatNumber,
  pluralizeText,
} from "../../../../../lib";
import { QuasiTripRewardName, TripRewardType } from "../../../CreditsConsts";

export default class StudentRewardCard extends React.PureComponent {
  render() {
    let {
      rewardRequest: {
        creditsToRedeem,
        currencyCode,
        creditValue,
        rewardName,
        rewardType,
        rewardTypeDisplay,
        status,
      },
    } = this.props;

    if (rewardName === QuasiTripRewardName) {
      rewardType = TripRewardType;
      rewardTypeDisplay = "Grand Trip";
    }

    return (
      <div className={`card reward-card ${rewardType?.toLowerCase()}-reward`}>
        <p className="uppercase-text xs-text fw-700 mb-8">
          {rewardTypeDisplay}
        </p>
        <p className="medium-text fw-700">{rewardName}</p>

        <div className="reward-card-footer mt-32 flex flex-align-center flex-justify-space">
          <p>
            {formatNumber(creditsToRedeem)}{" "}
            {pluralizeText("credit", creditsToRedeem)}{" "}
            {status === "Pending" ? "pending" : "used"}
          </p>
          {rewardType !== TripRewardType && (
            <p className="accent-text">
              {getCurrencySymbol(currencyCode)}
              {formatNumber(creditValue * creditsToRedeem)} value
            </p>
          )}
        </div>
      </div>
    );
  }
}
