import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ResetPassActions } from "../../state/resetPass/actions";
import RecoverResetPass from "./RecoverResetPass";

export default connect(
  function mapState(state) {
    return {
      ...state.resetPass,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          doResetPass: ResetPassActions.doRecoverResetPass,
        },
        dispatch,
      ),
    };
  },
)(RecoverResetPass);
