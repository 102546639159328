import React from "react";
import { useSelector } from "react-redux";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";
import Pages from "../../pages";
import { Navigation } from "../../lib";
import {
  ContainerBox,
  Title,
  CourseButton,
} from "./EnrollConfirmationPage.styles";
import { JewishUSelectors } from "../../state/myJewishU/selectors";

function EnrollConfirmationPage() {
  const eduChabadHouse = useSelector(JewishUSelectors.eduChabadHouse);

  const handleClick = () => {
    Navigation.go(
      Pages.locations.locationDetails.path.replace(
        ":locationId",
        eduChabadHouse?.slug || eduChabadHouse?.id,
      ),
    );
  };

  return (
    <ContainerBox>
      <img
        src="/images/ju-trophy.svg"
        alt=""
        height={200}
      />
      <Title>Your enrollment has been submitted and is pending approval.</Title>

      <CourseButton
        onClick={handleClick}
        variant="contained"
      >
        Back to Courses
      </CourseButton>
    </ContainerBox>
  );
}

export default withLayout()(withAppInsights(EnrollConfirmationPage));
