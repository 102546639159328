import GrandTripFAQs from "./GrandTripFAQsPage";
import GrandTrip from "./GrandTripPage.vm";
import TripDetails from "./TripDetailsPage";

export const GrandTripPages = {
  grandTrip: {
    anon: true,
    path: "/trips",
    title: "Grand Trips",
    type: "PAGE_GRAND_TRIPS",
    view: GrandTrip,
  },
  tripFaqs: {
    anon: true,
    path: "/trips/faqs",
    title: "Trip FAQs",
    type: "PAGE_TRIP_FAQS",
    view: GrandTripFAQs,
  },
  tripDetails: {
    anon: true,
    path: "/trips/:id",
    title: "Trip Details",
    type: "PAGE_TRIP_DETAILS",
    view: TripDetails,
  },
};
export default GrandTripPages;

export const GrandTripArea = {
  pages: GrandTripPages,
};
