import React from "react";
import { PageNavLink } from "../../lib";

export default ({
  dropdownMenu,
  dropdownOpen,
  onClick,
  title,
  to,
  toggleDropdown,
}) =>
  !!dropdownMenu ? (
    <li className="mobile-nav-link">
      <div className="flex flex-align-center flex-justify-center mb-48">
        <PageNavLink
          to={to || "/"}
          activeClassName="active-mobile-nav-link"
          onClick={onClick}
        >
          {title}
        </PageNavLink>
        <i
          className="material-icons ml-8"
          style={{ color: "#fff", marginRight: "-8px" }}
          onClick={toggleDropdown}
        >
          {dropdownOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </i>
      </div>
      {dropdownMenu}
    </li>
  ) : (
    <li className="mobile-nav-link" onClick={onClick}>
      <PageNavLink activeClassName="active-mobile-nav-link" to={to || "/"}>
        {title}
      </PageNavLink>
    </li>
  );
