import React from "react";

export default class Checkbox extends React.PureComponent {
  render() {
    const {
      className,
      checked,
      disabled,
      error,
      label,
      labelClassName,
      name,
      onChange,
    } = this.props;

    return (
      <div
        className={`flex custom-checkbox-container ${className || ""} ${
          error ? "error" : ""
        } ${disabled ? "disabled" : ""}`}
      >
        <input
          checked={checked}
          className="custom-checkbox"
          disabled={disabled}
          id={`${name}-checkbox`}
          onChange={() => onChange(name, !checked)}
          type="checkbox"
        />
        <label className={labelClassName} htmlFor={`${name}-checkbox`}>
          {label}
        </label>
      </div>
    );
  }
}
