import { MyJewishUActions } from "../myJewishU/actions";
import { AuthRequest } from "../../lib";

export const ProfileActions = {
  SET_GET_PROFILE_LOADING: "SET_GET_PROFILE_LOADING",
  GET_PROFILE_COMPLETE: "GET_PROFILE_COMPLETE",
  GET_PROFILE_ERROR: "GET_PROFILE_ERROR",

  SET_SUBMIT_PROFILE_LOADING: "SET_SUBMIT_PROFILE_LOADING",
  SUBMIT_PROFILE_ERROR: "SUBMIT_PROFILE_ERROR",
  SUBMIT_PROFILE_COMPLETE: "SUBMIT_PROFILE_COMPLETE",

  UPDATE_PROFILE: "UPDATE_PROFILE",

  getProfile(studentId) {
    return async (dispatch) => {
      dispatch(ProfileActions.setGetProfileLoading(true));

      let error, response, success;
      try {
        response = await AuthRequest.get(`Students/${studentId}`);
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(ProfileActions.getProfileError(error));
        return undefined;
      }

      dispatch(ProfileActions.getProfileComplete(response.data));

      return response.data;
    };
  },
  submitProfile(profile) {
    return async (dispatch) => {
      dispatch(ProfileActions.setSubmitProfileLoading(true));

      let error, response, success;
      try {
        response = await AuthRequest.post("Students", {
          ...profile,
        });
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(ProfileActions.submitProfileError(error));
        return undefined;
      }

      dispatch(ProfileActions.submitProfileComplete());
      dispatch(ProfileActions.updateProfile(response.data));
      const jewishUData = await dispatch(MyJewishUActions.getMyJewishU());

      return { ...response.data, myJewishU: jewishUData?.payload };
    };
  },
  setGetProfileLoading(payload) {
    return {
      type: ProfileActions.SET_GET_PROFILE_LOADING,
      payload,
    };
  },
  getProfileComplete(payload) {
    return {
      type: ProfileActions.GET_PROFILE_COMPLETE,
      payload,
    };
  },
  getProfileError(payload) {
    return {
      type: ProfileActions.GET_PROFILE_ERROR,
      payload,
    };
  },
  setSubmitProfileLoading(payload) {
    return {
      type: ProfileActions.SET_SUBMIT_PROFILE_LOADING,
      payload,
    };
  },
  submitProfileComplete(payload) {
    return {
      type: ProfileActions.SUBMIT_PROFILE_COMPLETE,
      payload,
    };
  },
  submitProfileError(payload) {
    return {
      type: ProfileActions.SUBMIT_PROFILE_ERROR,
      payload,
    };
  },
  updateProfile(payload) {
    return {
      type: ProfileActions.UPDATE_PROFILE,
      payload,
    };
  },
};
