import React from "react";
import { getAddressDisplay } from "../../../../lib";

export default ({
  isMyLocation,
  location: {
    chabadHouseAddress1,
    chabadHouseAddress2,
    chabadHouseCountry,
    chabadHouseCity,
    chabadHouseLogoURL,
    chabadHouseName,
    chabadHousePhone,
    chabadHouseState,
    chabadHouseWebsiteURL,
    chabadHouseZip,
  },
}) => (
  <div style={{ overflowWrap: "break-word" }}>
    {chabadHouseLogoURL && (
      <img
        alt={chabadHouseName}
        src={chabadHouseLogoURL}
        height="140"
        className="mb-16"
        style={{ maxWidth: 140, height: "auto" }}
      />
    )}
    {isMyLocation && (
      <p className="my-location-tag mb-16">
        <i className="material-icons mr-8">place</i> My Location
      </p>
    )}
    <p className="fw-600 mb-8 medium-text line-height-double">
      {chabadHouseName}
    </p>
    <p className="mb-8 line-height-double">
      {getAddressDisplay(
        chabadHouseAddress1,
        chabadHouseAddress2,
        chabadHouseCity,
        chabadHouseState,
        chabadHouseZip,
        chabadHouseCountry,
      )}
    </p>
    <p className="mb-8">{chabadHousePhone}</p>
    <a
      className="link-text"
      href={chabadHouseWebsiteURL}
      rel="noopener noreferrer"
      target="_blank"
    >
      {chabadHouseWebsiteURL.replace(/^https?:\/\//i, "").replace(/\/$/, "")}
    </a>
  </div>
);
