import Home from "./Home.vm";
import NotFound from "./NotFound.vm";

export const MainPages = {
  home: {
    anon: true,
    path: "/",
    title: "JewishU",
    type: "PAGE_HOME",
    view: Home,
  },
  notFound: {
    anon: true,
    path: "/404",
    title: "Sorry, Not Found",
    type: "PAGE_NOT_FOUND",
    view: NotFound,
  },
};
export default MainPages;

export const MainArea = {
  pages: MainPages,
};
