import { createTheme, useMediaQuery, useTheme } from "@mui/material";

export const defaultTheme = createTheme({
  palette: {
    //TODO: get correct color scheme for JU
    primary: { main: "#1326F3", contrastText: "#ffffff" },
    secondary: { main: "##f06262", contrastText: "#00175C" },
    success: { main: "#63C674" },
    warning: { main: "#f2d746" },
  },
  typography: {
    h1: {
      fontFamily: "AvenirBlack",
      fontSize: 58,
    },
    h2: {
      fontFamily: "AvenirBlack",
      fontSize: 35,
      letterSpacing: 0.38,
    },
    h3: {
      fontFamily: "AvenirBlack",
      fontSize: 26,
      fontWeight: "bold",
      letterSpacing: 0.28,
    },
    h4: {
      fontFamily: "AvenirBlack",
      fontSize: 24,
      fontWeight: 800,
      letterSpacing: 0,
    },
    h5: {
      fontFamily: "AvenirBlack",
      fontSize: 20,
      color: "#00175C",
    },
    h6: {
      fontFamily: "AvenirBlack",
      fontSize: 18,
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: "AvenirRoman",
      fontSize: 14,
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});
/**
 * Returns `true` if the screen is sized for mobile. Depends on theme
 * breakpoints, otherwise this function would be defined in `lib/hooks.js`
 */
export function useMobile(size: any = "xs") {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(size));
}
