import { ResetPassActions } from "../resetPass/actions";

export const ResetPassState = {
  name: "resetPass",
  persist: false,
  defaults: {
    error: undefined,
    loading: false,
    success: undefined,
  },
  handlers: {
    [ResetPassActions.RESET_PASS_ERROR]: function (state, action) {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response,
      };
    },
    [ResetPassActions.SET_RESET_PASS_LOADING]: function (state, action) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [ResetPassActions.RESET_PASS_COMPLETE]: function (state, action) {
      return {
        ...state,
        loading: false,
        success: true,
        error: undefined,
      };
    },
  },
};
