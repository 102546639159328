import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { normalizeCountdownValue } from "..";

export function MinuteCountdown(
  props: CircularProgressProps & { value: number },
) {
  const { value } = props;
  const isTimeUp = value === 0;
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        size={70}
        thickness={2}
        variant="determinate"
        color={value > 15 ? "success" : isTimeUp ? "error" : "warning"}
        {...props}
        value={isTimeUp ? 100 : normalizeCountdownValue(value)}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          m: 1,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{ fontWeight: "bold" }}
          color={isTimeUp ? "error" : "inherit"}
        >
          {value}
        </Typography>
        <Typography
          variant="caption"
          component="div"
          color={isTimeUp ? "error" : "text.secondary"}
        >
          seconds
        </Typography>
      </Box>
    </Box>
  );
}
