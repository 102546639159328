import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export { ContainerBox, Title, CourseButton };

const ContainerBox = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 384px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: 8,
    padding: 8,
    "& img": {
      height: 100,
    },
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: "rgba(0,0,0,0.87)",
  fontSize: 20,
  fontWeight: "bold",
  letterSpacing: 0,
  paddingBottom: 8,
  marginTop: 32,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
  },
}));

const CourseButton = styled(Button)(({ theme }) => ({
  marginTop: 16,
  width: 250,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginBottom: 32,
  },
}));
