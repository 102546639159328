import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { MyJewishUActions } from "../../state";
import JewishUEnrollmentPage from "./JewishUEnrollmentPage";

export default connect(
  function mapState(state) {
    return {
      myJewishU: state.myJewishU,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...MyJewishUActions,
        },
        dispatch,
      ),
    };
  },
)(JewishUEnrollmentPage);
