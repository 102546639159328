import {
  Box,
  Grid,
  LinearProgress,
  linearProgressClasses,
  ToggleButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export {
  BorderLinearProgress,
  QuestionnaireBox,
  ButtonToggle,
  Title,
  SubTitle,
  CountdownGrid,
  ImageContainer,
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1326F3",
  },
}));

const QuestionnaireBox = styled(Box)(({ theme }) => ({
  width: 700,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
const ButtonToggle = styled(ToggleButton)(() => ({
  backgroundColor: "white",
  width: "100%",
  color: "rgba(0,0,0,0.87)",
  "&.MuiToggleButton-root:hover": {
    backgroundColor: "#F06262",
    color: "white",
  },
  "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));
const Title = styled(Typography)(({ theme }) => ({
  color: "rgba(0,0,0,0.87)",
  fontSize: 20,
  fontWeight: "bold",
  letterSpacing: 0,
  paddingBottom: 8,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
  },
}));
const SubTitle = styled(Typography)(({ theme }) => ({
  color: "rgba(0,0,0,0.87)",
  fontSize: 16,
  letterSpacing: 0,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
}));
const CountdownGrid = styled(Grid)(() => ({
  justifyContent: "flex-end",
  alignItems: "flex-end",
  display: "flex",
}));
const ImageContainer = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "flex-end",
  display: "flex",
  padding: 16,
  paddingBottom: 24,
  [theme.breakpoints.down("sm")]: {
    padding: 8,
    overflow: "hidden",
  },
}));
