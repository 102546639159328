import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export default ({ maxDate, name, value, onChange }) => (
  <DatePicker
    maxDate={maxDate}
    onChange={(date) => {
      const dateMoment = moment(date);
      onChange(name, dateMoment && dateMoment.isValid() ? dateMoment : "");
    }}
    selected={value ? moment(value).toDate() : null}
    scrollableYearDropdown={true}
    showMonthDropdown={true}
    showYearDropdown={true}
    yearDropdownItemNumber={40}
  />
);
