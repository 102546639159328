import CourseDetailsPage from "./CourseDetailsPage.vm";
import CoursesPage from "./CoursesPage.vm";
import CourseSurveyCompletedPage from "./CourseSurveyCompletedPage.vm";
import MyCoursesPage from "./MyCoursesPage.vm";

export const CoursesPages = {
  courses: {
    anon: true,
    path: "/courses",
    title: "Courses",
    type: "PAGE_COURSES",
    view: CoursesPage,
  },
  myCourses: {
    anon: true,
    path: "/courses/my-courses",
    title: "My Courses",
    type: "PAGE_MY_COURSES",
    view: MyCoursesPage,
  },
  courseSurveyCompleted: {
    anon: false,
    path: "/courses/review-completed",
    title: "Course Review Completed",
    type: "PAGE_COURSE_SURVEY_COMPLETED",
    view: CourseSurveyCompletedPage,
  },
  courseDetails: {
    anon: true,
    path: "/courses/:courseId",
    title: "Course Details",
    type: "PAGE_COURSE_DETAILS",
    view: CourseDetailsPage,
  },
};
export default CoursesPages;

export const CoursesArea = {
  pages: CoursesPages,
};
