import React, { useEffect, useState, useCallback } from "react";
import { Box, Grid, ToggleButtonGroup, Typography } from "@mui/material";
import {
  BorderLinearProgress,
  ButtonToggle,
  SubTitle,
  Title,
  QuestionnaireBox,
  CountdownGrid,
  ImageContainer,
} from "./QuestionnaireEnrollmentStep.styles";
import { MinuteCountdown } from "./MinuteCountdown";
import { stepImages } from "..";

export default function QuestionnaireEnrollmentStep(props: any) {
  const { enroll, courseId } = props;
  const [step, setStep] = useState(0);
  const [counter, setCounter] = useState(60);
  const [options, setOptions]: any = useState(stepImages[0]);
  const quizLength = stepImages.length;
  useEffect(() => {
    if (counter !== 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  useEffect(() => {
    const options = stepImages[step];
    setOptions(options);
  }, [step]);

  const handleChange = useCallback(async () => {
    if (step === quizLength - 1) {
      await enroll(courseId);
    } else {
      setStep(step + 1);
    }
  }, [courseId, enroll, step, quizLength]);

  return (
    <QuestionnaireBox>
      <Grid container sx={{ mb: 3 }}>
        <Grid item md={10}>
          {step === 0 ? (
            <Title>
              Just one final step: Let’s get to know you a little more
            </Title>
          ) : (
            <Title></Title>
          )}
          <SubTitle>
            Which do you prefer? Respond quickly and intuitively.
          </SubTitle>
          <SubTitle>You have 60 seconds to complete!</SubTitle>
        </Grid>
        <CountdownGrid item md={2}>
          <MinuteCountdown value={counter} />
        </CountdownGrid>
      </Grid>
      <BorderLinearProgress
        variant="determinate"
        value={(100 / quizLength) * step}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>
          {step}/{quizLength}
        </Typography>
        <Typography>{Math.round((100 / quizLength) * step)}%</Typography>
      </Box>

      <Grid container pt={2}>
        <Grid item container justifyContent="center" style={{ gap: 8 }}>
          <ImageContainer item xs={5.5}>
            <img
              src={`/images/questionnaire/${options.left.img}.jpg`}
              alt={options.left.label}
            />
          </ImageContainer>
          <ImageContainer item xs={5.5}>
            <img
              src={`/images/questionnaire/${options.right.img}.jpg`}
              alt={options.right.label}
            />
          </ImageContainer>
        </Grid>
        <Grid item xs={12} pb={2}>
          <ToggleButtonGroup exclusive onChange={handleChange} fullWidth>
            <ButtonToggle value={options.left.label}>
              {options.left.label}
            </ButtonToggle>
            <ButtonToggle value={options.right.label}>
              {options.right.label}
            </ButtonToggle>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </QuestionnaireBox>
  );
}
