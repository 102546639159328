import moment from "moment";
import React, { useMemo, useState } from "react";
import Modal from "../../../../components/Modal";
import { formatNumber, PageLink, pluralizeText } from "../../../../lib";
import Pages from "../../../../pages";

const extendedExpirationDate = "12/31/24"; //temporary measure to pause credit expiration for the initial roll-out
const formattedExtendedExpirationDate = "December 31st, 2024";
const extendedExpirationDateHasNotPassed = moment(
  extendedExpirationDate,
).isAfter(moment());

const MyCreditsOverview = (props) => {
  const [showInfoModal, setShowInfoModal] = useState(false);

  const {
    areCreditsAlreadyExpired,
    areCreditsExpiringSoon,
    creditsExpirationDate,
    daysToCreditsExpiration,
    memberSinceDate,
    numOfCreditsAvailable,
    numOfCreditsPendingRedemption,
    numOfCreditsReceived,
    numOfCreditsRedeemed,
    numOfCoursesCompleted,
    shortCreditsExpirationDate,
    student: { graduationYear } = {},
  } = props;

  const timeEnrolled = useMemo(() => {
    let monthsEnrolled = moment().diff(moment(memberSinceDate), "months");
    let yearsEnrolled = 0;

    if (!monthsEnrolled) {
      return "";
    }

    if (monthsEnrolled >= 12) {
      yearsEnrolled = Math.floor(monthsEnrolled / 12);
      monthsEnrolled = monthsEnrolled % 12;
    }

    let timeEnrolledDisplay = "";

    if (yearsEnrolled) {
      timeEnrolledDisplay += `${formatNumber(yearsEnrolled)} ${pluralizeText(
        "year",
        yearsEnrolled,
      )}${monthsEnrolled ? " + " : ""}`;
    }
    if (monthsEnrolled) {
      timeEnrolledDisplay += `${formatNumber(monthsEnrolled)} ${pluralizeText(
        "month",
        monthsEnrolled,
      )}`;
    }

    return timeEnrolledDisplay;
  }, [memberSinceDate]);

  const now = moment();
  const isExtensionDateAfterCreditsExpirationDate = moment(
    extendedExpirationDate,
  ).isAfter(creditsExpirationDate);
  const timeToCreditsExpiration = now
    .add(daysToCreditsExpiration, "days")
    .fromNow();
  const areCreditsExpiringWithinMonth = daysToCreditsExpiration <= 30;
  const areCreditsExpiringToday = daysToCreditsExpiration === 0;
  const isCurrentStudent = graduationYear >= now.year();
  return (
    <div className="credits-section container mb-80">
      <p className="credits-section-title xxl-text mt-24 mb-24 fw-700">
        My credits
      </p>
      {numOfCreditsAvailable > 0 &&
        (areCreditsExpiringSoon ||
          (areCreditsAlreadyExpired && extendedExpirationDateHasNotPassed)) && (
          <div className="medium-text fw-600 mt-8 credit-expiration-container flex flex-justify-space flex-align-center mobile-block">
            <div className="flex flex-align-center">
              <i className="material-icons expiration-warning-icon mr-8">
                warning
              </i>
              <div
                className={`${
                  areCreditsExpiringWithinMonth
                    ? "large-expiration-text"
                    : "med-expiration-text"
                } expiration-text`}
              >
                Heads up! Your credits{" "}
                {areCreditsAlreadyExpired && !extendedExpirationDateHasNotPassed
                  ? `expired `
                  : `are expiring `}
                <span
                  className={
                    isExtensionDateAfterCreditsExpirationDate
                      ? "strike-through error-text"
                      : ""
                  }
                >
                  {areCreditsAlreadyExpired
                    ? `on ${creditsExpirationDate}`
                    : areCreditsExpiringSoon
                    ? `${
                        areCreditsExpiringToday
                          ? "tonight at 11:59pm EST"
                          : `${timeToCreditsExpiration}${
                              areCreditsExpiringWithinMonth
                                ? `, on ${creditsExpirationDate} at 11:59pm EST`
                                : ""
                            }`
                      }`
                    : ""}
                </span>
                {isExtensionDateAfterCreditsExpirationDate ? (
                  <span> on {formattedExtendedExpirationDate}</span>
                ) : (
                  ""
                )}
                .
                {!areCreditsExpiringWithinMonth &&
                  " To keep them active, sign up for another course."}
              </div>
            </div>
            <div className="flex flex-align-center mobile-block text-center">
              <div
                className="link-text ml-8 mr-16 mobile-mt-24 mobile-mb-8"
                onClick={() => setShowInfoModal(true)}
              >
                Learn More
              </div>
              {isCurrentStudent && !areCreditsExpiringWithinMonth && (
                <PageLink className="btn btn-accent" to={Pages.courses.courses}>
                  Browse Courses
                </PageLink>
              )}
            </div>
          </div>
        )}
      <div className="card credits-overview-card">
        <div className="total-credits">
          <p
            className="accent-text-secondary fw-600 text-center"
            style={{ fontSize: "80px" }}
          >
            {formatNumber(numOfCreditsReceived)}
          </p>
          <p className="medium-text fw-600 mt-8 text-center">Total Credits</p>
        </div>
        <div
          className={`credits-overview-grid${
            !timeEnrolled ? " hide-months" : ""
          }`}
        >
          <div className="tablet-flex mobile-flex flex-align-center">
            <img src="/images/icon-credits.svg" alt="" />
            <div>
              <p className="xxl-text fw-600 mt-24 tablet-mt-0">
                {formatNumber(numOfCreditsAvailable)}{" "}
                {pluralizeText("credit", numOfCreditsAvailable)}
              </p>
              <p className="flex flex-align-center small-text accent-text uppercase-text expiration-info-text fw-500 mt-8">
                Available to use
                {(areCreditsExpiringSoon ||
                  (areCreditsAlreadyExpired &&
                    extendedExpirationDateHasNotPassed)) && (
                  <>
                    <br />
                    until{" "}
                    {isExtensionDateAfterCreditsExpirationDate
                      ? extendedExpirationDate
                      : shortCreditsExpirationDate}
                    <i
                      className="material-icons expiration-info-icon pointer"
                      onClick={() => setShowInfoModal(true)}
                    >
                      info
                    </i>
                  </>
                )}
              </p>
            </div>
          </div>
          <div
            className={`tablet-flex mobile-flex ${
              numOfCreditsPendingRedemption > 0
                ? "flex-align-start"
                : "flex-align-center"
            }`}
          >
            <img src="/images/icon-credits-red.svg" alt="" />
            <div>
              <p className="xxl-text fw-600 mt-24 tablet-mt-0">
                {formatNumber(
                  numOfCreditsRedeemed + numOfCreditsPendingRedemption,
                )}{" "}
                {pluralizeText(
                  "credit",
                  numOfCreditsRedeemed + numOfCreditsPendingRedemption,
                )}
              </p>
              <p className="small-text accent-text uppercase-text fw-500 mt-8">
                Cashed out
              </p>
              {numOfCreditsPendingRedemption > 0 && (
                <p className="small-text accent-text uppercase-text fw-500 mt-8">
                  (Includes {formatNumber(numOfCreditsPendingRedemption)}{" "}
                  pending)
                </p>
              )}
            </div>
          </div>
          <div className="tablet-flex mobile-flex flex-align-center">
            <img src="/images/icon-classes.svg" alt="" />
            <div>
              <p className="xxl-text fw-600 mt-24 tablet-mt-0">
                {formatNumber(numOfCoursesCompleted)}{" "}
                {pluralizeText("course", numOfCoursesCompleted)}
              </p>
              <p className="small-text accent-text uppercase-text fw-500 mt-8">
                Completed
              </p>
            </div>
          </div>
          {!!timeEnrolled && (
            <div className="tablet-flex mobile-flex flex-align-center">
              <img src="/images/icon-months.svg" alt="" />
              <div>
                <p className="xxl-text fw-600 mt-24 tablet-mt-0">
                  {timeEnrolled}
                </p>
                <p className="small-text accent-text uppercase-text fw-500 mt-8">
                  Enrolled in JewishU
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={showInfoModal}
        className="expiration-info-modal flex flex-justify-center"
      >
        <div>
          <div className="text-right">
            <i
              className="material-icons link-text-secondary"
              onClick={() => setShowInfoModal(false)}
            >
              close
            </i>
          </div>
          <p className="l-text fw-600 mb-12 text-center uppercase-text">
            Credit Expiration
          </p>
          <p className="accent-text-dark line-height-double mt-24 mb-32">
            Credits expire one year from your last class. Take another course to
            keep your credits active.
          </p>
          {isCurrentStudent && (
            <div className="text-center">
              <PageLink
                className="btn btn-accent"
                to={Pages.courses.courses}
                style={{ width: "360px" }}
              >
                Browse Courses
              </PageLink>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(MyCreditsOverview);
