import { RecoverPassActions } from "../recoverPass/actions";

export const RecoverPassState = {
  name: "recoverPass",
  persist: false,
  defaults: {
    error: undefined,
    loading: false,
    success: undefined,
  },
  handlers: {
    [RecoverPassActions.RECOVER_PASS_ERROR]: function (state, action) {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response,
      };
    },
    [RecoverPassActions.SET_RECOVER_PASS_LOADING]: function (state, action) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [RecoverPassActions.RECOVER_PASS_COMPLETE]: function (state, action) {
      return {
        ...state,
        loading: false,
        success: true,
        error: undefined,
      };
    },
  },
};
