/** @file View helper functions. */

/** Returns an input `onChange` handler that calls `component.setState`,
 * setting the `state[name]` field when the input value changes.
 * @param {React.Component} component The component.
 * @param {string} name Name of the state field to change.
 */
export function handleInputState(component, name) {
  return function inputChanged(e) {
    component.setState({ [name || e.target.name]: e.target.value });
  };
}
/**
 * Returns a function that evaluates the given state field for truthiness
 * and returns one of two values.
 * @param {React.Component} component The component.
 * @param {string} name Name of the field in `component.state` to evaluate.
 * @param {} [whenTruthy] Value to return when the state field is truthy.
 * @param {} [whenFalsey] Value to return when the state field is falsey.
 * @returns {} Either the `whenTruthy` or `whenFalsey` param.
 */
export function ifState(component, name, whenTruthy, whenFalsey) {
  function stateIf() {
    const state = component.state;
    if (!state || !state[name]) return whenFalsey;
    return whenTruthy;
  }
  return stateIf;
}
/**
 * Returns a function that passes the `e.target.value` to the given handler,
 * where `e` is the argument of the outer function. This is mainly for
 * dealing with `FormControl` from 'react-bootstrap' and other such
 * components.
 * @param {function} handler The function to receive the target value.
 * @returns {function} The outer function/event handler.
 * @example
 * onChangeUserName = ViewHelper.targetValue(function(userName) {
 *     this.state.userName = userName
 * });
 */
export function handleTargetValue(handler) {
  function handleTargetValue(e) {
    const value = e.target.value;
    handler(value);
  }
  return handleTargetValue;
}
/**
 * Returns a function that calls `e.preventDefault()` before calling the
 * given handler, where `e` is the argument of the outer function. This is
 * for dealing with any component that has default behavior which must be
 * cancelled, such as handling the click event of a hyperlink (`HTML:a`).
 * @param {function} handler The inner handler to be called.
 * @returns {function} The outer function/event handler.
 * @example onClickLink = ViewHelper.preventDefault(function() { doStuff(); });
 */
export function handlePreventDefault(handler, ...args) {
  if (!handler) {
    handler = noop;
  }
  function handlePreventDefault(e) {
    if (e) {
      e.preventDefault();
    }
    handler(...args);
  }
  return handlePreventDefault;
}

function noop() {}
