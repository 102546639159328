import React from "react";
import { formatNumber, pluralizeText, PageLink } from "../../../../lib";
import Pages from "../../../../pages";
import moment from "moment";

export default class CourseCard extends React.PureComponent {
  render() {
    const {
      course: {
        categoryDisplay,
        courseCreatedOn,
        courseID,
        courseName,
        courseSlug,
        eduCourseChabadHouseEnrollmentID,
        imageURL,
        isCurrentlyOfferedByChabadHouse,
        isJli,
        isUpcomingByChabadHouse,
        levelDisplay,
        numOfClasses,
        numOfShluchimCurrentlyOffering,
        studentCourseScheduleEnrollmentStatusDisplay,
      },
      locationId, //card from course list on location details page
      myLocation,
    } = this.props;

    const isNew = moment(courseCreatedOn).isAfter(
      moment().subtract(3, "months"),
    );

    // from an individual location course list 'course location' is set to the id of that location
    // o/w if the user took the course, it's set to the 'eduCourseChabadHouseEnrollmentID' which is the location that the course was taken at
    // o/w to the user's 'my location'
    const courseLocation =
      locationId ||
      eduCourseChabadHouseEnrollmentID ||
      (myLocation ? myLocation.id : undefined);

    return (
      <div className="course-card card">
        <PageLink
          to={Pages.courses.courseDetails}
          params={{ courseId: courseSlug || courseID }}
          query={{
            loc: courseLocation, //if location is specified, linking to course details for that location, otherwise links to course details for student location
          }}
        >
          <div>
            <div className="gradient-img-container">
              <img src={imageURL} alt={courseName} />
            </div>
            {isNew && <p className="new-course-tag">New</p>}
            <div>
              <p className="uppercase-text xs-text mb-8 fw-500">
                {categoryDisplay}
              </p>
              <p className="fw-600 medium-text inline-block" dir="auto">
                {courseName}
              </p>
              <div className="flex flex-align-center mt-8">
                <p>
                  {formatNumber(numOfClasses) || "No"}{" "}
                  {pluralizeText("class", numOfClasses, "classes")}
                </p>
                <p className="ml-4">
                  {courseLocation // for location-specific course list or all courses list for enrolled student, show if course is currently offered
                    ? isCurrentlyOfferedByChabadHouse
                      ? " | Currently offering"
                      : isUpcomingByChabadHouse
                      ? " | Upcoming"
                      : ""
                    : // for logged-out user or student w/o edu chabad house, show locations offering info
                      !!numOfShluchimCurrentlyOffering &&
                      ` | ${formatNumber(
                        numOfShluchimCurrentlyOffering,
                      )} ${pluralizeText(
                        "location",
                        numOfShluchimCurrentlyOffering,
                      )}`}
                </p>
              </div>
              <div className={!isJli ? "hidden" : "jli-logo"}>
                <img
                  src="/images/jli_logo.svg"
                  alt="jewish learning institute"
                  height="35"
                />
              </div>
            </div>
          </div>
          <div className="course-card-footer flex flex-justify-space">
            <p className="accent-text">{levelDisplay}</p>
            {studentCourseScheduleEnrollmentStatusDisplay && (
              <p
                className={`fw-500 text-${studentCourseScheduleEnrollmentStatusDisplay.toLowerCase()}`}
              >
                {studentCourseScheduleEnrollmentStatusDisplay}
              </p>
            )}
          </div>
        </PageLink>
      </div>
    );
  }
}
