import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { AuthActions } from "../../state";
import Confirm from "./Confirm";

export default connect(
  function mapState(state) {
    return {
      ...state.confirmRegistration,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(AuthActions, dispatch),
    };
  },
)(Confirm);
