import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { loadScript } from "../../../lib";
import { AuthActions } from "../../../state";
import decode from "jwt-decode";
const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

const registerAccessScopes = [
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/userinfo.profile",
].join(" ");

export function GoogleIdentitySignin({ onSubmit, register }) {
  const dispatch = useDispatch();
  const googleButton = useRef(null);

  const requestRegisterAccessToken = useCallback((email) => {
    return new Promise((resolve) => {
      const tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: REACT_APP_GOOGLE_CLIENT_ID,
        scope: registerAccessScopes,
        prompt: "none", // no prompt necessary as we are requesting scopes that are granted by default
        hint: email, // provide email to avoid re-prompting for account selection
        callback: (tokenResponse) => resolve(tokenResponse?.access_token),
        // error_callback: () => resolve(), // skipping optional callback: generates 'Cross-Origin-Opener-Policy policy would block the window.closed call.' error
      });
      tokenClient.requestAccessToken();
    });
  }, []);

  const handleCredentialResponse = useCallback(
    async function (response) {
      if (register) {
        const email = decode(response.credential)?.email;
        const accessToken = await requestRegisterAccessToken(email);
        if (accessToken) {
          await dispatch(
            AuthActions.doGoogleRegistration(response.credential, accessToken),
          );
        }
      } else {
        await dispatch(AuthActions.doGoogleSignin(response.credential));
      }
      onSubmit();
    },
    [dispatch, onSubmit, register, requestRegisterAccessToken],
  );

  const renderGoogleSignin = useCallback(() => {
    const accountId = window.google?.accounts?.id;

    if (accountId) {
      // initialize google account
      accountId.initialize({
        client_id: REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
        auto_select: !register,
        use_fedcm_for_prompt: true,
      });

      // render signin button
      accountId.renderButton(
        googleButton.current,
        // customization attributes
        {
          text: register ? "signup_with" : "signin_with",
          theme: "outline",
          size: "large",
          width: "240px",
        },
      );

      // Google one tap
      accountId.prompt();
    }

    return !!accountId;
  }, [handleCredentialResponse, register]);

  useEffect(() => {
    const src = "https://accounts.google.com/gsi/client";
    // load google script
    loadScript(src)
      .then(() => {
        const rendered = renderGoogleSignin();
        // if not rendered, retry after 1 second (to accommodate edge cases where window.google object may not have been available yet if gsi script was loaded externally)
        if (!rendered) {
          setTimeout(renderGoogleSignin, 1000);
        }
      })
      .catch(console.error);
  }, [renderGoogleSignin]);

  return <div ref={googleButton} />;
}

export default GoogleIdentitySignin;
