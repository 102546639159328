import React from "react";

export default class FadeOutMessage extends React.PureComponent {
  mounted = false;

  componentDidMount() {
    this.mounted = true;
    this.setTimeoutCallback();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(nextProps) {
    if (nextProps.message !== this.props.message) {
      clearTimeout(this.timeoutCB);
      this.setTimeoutCallback();
    }
  }

  timeoutCB = null;
  setTimeoutCallback = () => {
    const { timeout = 5000, onTimeout } = this.props;

    if (onTimeout) {
      this.timeoutCB = setTimeout(() => {
        if (this.mounted) {
          onTimeout();
        }
      }, timeout);
    }
  };

  render() {
    const { className, message, style } = this.props;

    //TODO - CSS - fade in & out styles
    return message ? (
      <span
        className={`${className || "error-text"} ${
          message ? "fade-in" : "fade-out"
        }`}
        style={style}
      >
        {message}
      </span>
    ) : null;
  }
}
