import React, { useCallback } from "react";
import Checkbox from "../../../components/form/Checkbox";
import DateInput from "../../../components/form/DateInput";
import ImageWithCropUpload from "../../../components/ImageWithCropUpload";
import PhoneInput from "../../../components/form/PhoneInput";
import Toggle from "../../../components/form/Toggle";
import ValidatedInput from "../../../components/ValidatedInput";
import { validateEmail } from "../../../lib";

export default function PersonalInfo(props) {
  const onBlurName = (e) => {
    const enteredName = e.target.value;
    const nameParts = enteredName.split(" ").filter((n) => n);
    const capitalizedName = nameParts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
    if (capitalizedName !== enteredName) {
      props.onChangeProfile(e.target.name, capitalizedName);
    }
  };

  const {
    countries,
    onChangeProfile,
    onChangeProfileEvt,
    initialProfile,
    profile: {
      address,
      cell,
      cellCountryID,
      didConsentToMarketingContact,
      didConsentToMarketingQuotes,
      isJewish,
      email,
      person: {
        dob = "",
        firstName = "",
        gender = "",
        hebrewName = "",
        lastName = "",
        profileImageURL = "",
      } = {},
    } = { person: {} },
    profileValidationErrors,
    showFormValidation,
    updateProfileValidation,
  } = props;

  const initialURL = initialProfile?.person?.profileImageURL;
  const uploadPicturePrompt = useCallback((savedPicture) => {
    const changePictureText =
      "Been a while since you updated your profile picture? ";

    return (
      <div className="mb-8">
        <span
          className="accent-text line-height-double block mb-8"
          style={{ textTransform: "none", maxWidth: 580 }}
        >
          {`${savedPicture ? changePictureText : ""} Upload a well-lit,
        high-res headshot to bring your profile up to date.`}
        </span>
      </div>
    );
  }, []);

  const uploadLink = (
    <>
      <span> UPLOAD PROFILE IMAGE</span>
      <span className="ml-4 mobile-ml-0 mobile-mt-8 xs-text">(Optional)</span>
    </>
  );

  return (
    <div className="profile-form-section">
      <p className="section-title">Personal information</p>
      <div className="mb-16">
        <ImageWithCropUpload
          name="person.profileImageURL"
          label={<p className="flex">{uploadPicturePrompt(!!initialURL)}</p>}
          uploadLink={uploadLink}
          uploadedLabel={uploadPicturePrompt(!!initialURL)}
          onChange={onChangeProfile}
          imageName="Profile Image"
          imageType="profile"
          imageURL={profileImageURL}
        />
      </div>
      <div className="profile-form-grid">
        <ValidatedInput
          label="First Name"
          name="person.firstName"
          onBlur={onBlurName}
          onChange={onChangeProfileEvt}
          required={true}
          showRequired={showFormValidation}
          value={firstName}
        />
        <ValidatedInput
          label="Last Name"
          name="person.lastName"
          onBlur={onBlurName}
          onChange={onChangeProfileEvt}
          required={true}
          showRequired={showFormValidation}
          value={lastName}
        />
        <div className="input-container">
          <label>Hebrew Name</label>
          <input
            name="person.hebrewName"
            onBlur={onBlurName}
            onChange={onChangeProfileEvt}
            type="text"
            value={hebrewName}
          />
        </div>
        <ValidatedInput
          input={<DateInput maxDate={new Date()} />}
          label="Birthday"
          name="person.dob"
          onChange={onChangeProfile}
          required={true}
          showRequired={showFormValidation}
          value={dob}
        />
        <ValidatedInput
          label="Email"
          name="email"
          onChange={onChangeProfileEvt}
          required={true}
          showRequired={showFormValidation}
          showValidation={showFormValidation}
          validate={(email) => {
            const isValid = validateEmail(email);
            updateProfileValidation("email", isValid);
            return isValid;
          }}
          validationMessage="Invalid email address"
          value={email}
        />
        <div
          className={`input-container ${
            profileValidationErrors.indexOf("cell") >= 0 ||
            (showFormValidation && !cell)
              ? "error"
              : ""
          }`}
        >
          <label>Phone</label>
          <PhoneInput
            countries={countries || []}
            name="cell"
            countryId={cellCountryID}
            defaultCountryName={address && address.country} //fallback on country associated with student's address
            onChange={(cell, cellCountryID) => {
              onChangeProfile("cell", cell);
              onChangeProfile("cellCountryID", cellCountryID);
            }}
            error={profileValidationErrors.indexOf("cell") >= 0}
            validate={(isValid) => updateProfileValidation("cell", isValid)}
            value={cell}
          />
        </div>
        <ValidatedInput
          className="toggle-container"
          input={
            <Toggle
              options={[
                {
                  value: "Male",
                  display: "Male",
                },
                {
                  value: "Female",
                  display: "Female",
                },
              ]}
            />
          }
          label="Gender"
          labelClassName="uppercase-text xs-text accent-text mb-4 block"
          name="person.gender"
          onChange={onChangeProfile}
          required={true}
          showRequired={showFormValidation}
          value={gender === "Unknown" ? "" : gender}
        />
        <ValidatedInput
          className="toggle-container is-jewish"
          input={
            <Toggle
              options={[
                {
                  value: true,
                  display: "Yes",
                },
                {
                  value: false,
                  display: "No, but interested",
                },
              ]}
            />
          }
          label="Jewish"
          labelClassName="uppercase-text xs-text accent-text mb-4 block"
          name="isJewish"
          onChange={onChangeProfile}
          required={true}
          showRequired={
            showFormValidation && !(isJewish === true || isJewish === false)
          }
          value={isJewish}
        />
      </div>
      <Checkbox
        checked={didConsentToMarketingContact}
        className="mt-16"
        label="Yes!  I would like to be contacted about other Chabad on Campus opportunities"
        name="didConsentToMarketingContact"
        onChange={onChangeProfile}
      />
      <Checkbox
        checked={didConsentToMarketingQuotes}
        className="mt-16"
        label="Chabad on Campus can use my quotes in future publications or marketing materials"
        name="didConsentToMarketingQuotes"
        onChange={onChangeProfile}
      />
    </div>
  );
}
