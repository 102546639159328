import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Layout from "./Layout";
import { AuthActions, ProfileActions } from "../../state";

export default connect(
  function mapState(state) {
    return {
      myJewishU: state.myJewishU,
      ui: state.ui,
      user: state.auth.person,
      studentId: state.auth.studentID,
      profile: state.profile.get.data,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          getProfile: ProfileActions.getProfile,
          doSignout: AuthActions.doSignout,
        },
        dispatch,
      ),
    };
  },
)(Layout);
