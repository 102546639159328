import React from "react";
import Checkbox from "../../components/form/Checkbox";
import CourseCard from "./components/courses/CourseCard";
import CoursesFilters from "./components/courses/CoursesFilters";
import PaginatedList from "../../components/PaginatedList";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";
import {
  formatNumber,
  Navigation,
  PageURL,
  pluralizeText,
  scrollUpToTargetRefAdjusted,
} from "../../lib";

class CoursesPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = this.getStateFromURL();
    this.listContainerRef = React.createRef();
  }

  queryDefaults = {
    cmlc: false,
    c: "",
    p: 1,
    r: 12,
  };

  getStateFromURL = () => {
    const {
      pageRoute: {
        query: { cmlc, c, p, r },
      },
    } = this.props;

    return {
      filters: {
        category: c || this.queryDefaults.c,
        currentlyOfferedAtMyLocation: cmlc || this.queryDefaults.cmlc,
      },
      page: p || this.queryDefaults.p,
      resultsView: r || this.queryDefaults.r,
    };
  };

  matchURLToState = () => {
    const {
      pageRoute: { page: routePage, params, query },
    } = this.props;
    const {
      filters: { category, currentlyOfferedAtMyLocation },
      page,
      resultsView,
    } = this.state;

    Navigation.redirect(
      PageURL.to(routePage, params, {
        ...query,
        cmlc:
          currentlyOfferedAtMyLocation &&
          currentlyOfferedAtMyLocation !== this.queryDefaults.cmlc
            ? currentlyOfferedAtMyLocation
            : undefined,
        c: category && category !== this.queryDefaults.c ? category : undefined,
        p: page && page !== this.queryDefaults.p ? page : undefined,
        r:
          resultsView && resultsView !== this.queryDefaults.r
            ? resultsView
            : undefined,
      }),
    );
  };

  getCourses = () => {
    const { filters, page, resultsView } = this.state;

    this.props.actions.getCourses(page, resultsView, filters);
  };

  paginateCourses = (page = this.state.page) => {
    this.setState({ page }, () => {
      this.matchURLToState();
      this.getCourses();
    });
  };

  filterCourses = (filter, adjustScroll = false) => {
    this.setState(
      {
        filters: { ...this.state.filters, ...filter },
        page: 1,
      },
      () => {
        this.matchURLToState();
        this.getCourses();
      },
    );

    if (adjustScroll) {
      scrollUpToTargetRefAdjusted(this.listContainerRef);
    }
  };

  getCategorizedCourses = (courses) => {
    const categorizedCourses = {};

    courses.forEach((course) => {
      var category = course.isCurrentlyOfferedByChabadHouse
        ? "Currently Offering"
        : course.isUpcomingByChabadHouse
        ? "Upcoming"
        : "Course Catalog";
      if (!categorizedCourses.hasOwnProperty(category)) {
        categorizedCourses[category] = [];
      }
      categorizedCourses[category].push(course);
    });

    return categorizedCourses;
  };

  renderCategorizedCourses = (courses) => {
    const categorizedCourses = this.getCategorizedCourses(courses);

    return Object.keys(categorizedCourses).map((categoryKey, categoryIndex) => (
      <React.Fragment key={categoryIndex}>
        <p className="courses-category-header">{categoryKey}</p>
        {this.renderCourses(categorizedCourses[categoryKey])}
      </React.Fragment>
    ));
  };

  renderCourses = (courses) => {
    const {
      myJewishU: { data: { eduChabadHouse: myLocation } = {} },
    } = this.props;

    return courses.map((course, index) => (
      <CourseCard course={course} myLocation={myLocation} key={index} />
    ));
  };

  render() {
    const {
      data: { results: courses = [], numberOfRows: totalCourses = 0 },
      error,
      loading,
      myJewishU: { data: { eduChabadHouse: myLocation } = {} },
      sys: { eduCourseCategories = [] },
      user,
    } = this.props;
    const {
      filters: { category, currentlyOfferedAtMyLocation },
      page,
      resultsView,
    } = this.state;

    const coursesFilters = (
      <CoursesFilters
        categoryFilter={category}
        courseCategories={eduCourseCategories}
        filterCourses={this.filterCourses}
      />
    );

    return (
      <div className="page container">
        <div className="flex" ref={this.listContainerRef}>
          <div className="tablet-hidden mobile-hidden mr-32">
            {coursesFilters}
          </div>
          <div className="full-width">
            <div className="all-courses-header flex flex-justify-space flex-align-end tablet-block mobile-block mb-24">
              <div>
                <p className="fw-700 xxl-text mb-16">
                  {category
                    ? (
                        eduCourseCategories.find(
                          (c) => c.enumValue === category,
                        ) || {
                          displayValue: "Explore courses",
                        }
                      ).displayValue
                    : "Explore all courses"}
                </p>
                <p>
                  {formatNumber(totalCourses)}{" "}
                  {pluralizeText("course", totalCourses)}
                </p>
              </div>
              <div className="tablet-flex flex-justify-space flex-align-center mt-16 mobile-block">
                <div className="desktop-hidden full-width">
                  {React.cloneElement(coursesFilters, { showResponsive: true })}
                </div>
                {!!user && myLocation && (
                  <Checkbox
                    className="ml-40 mobile-ml-0"
                    checked={currentlyOfferedAtMyLocation}
                    label="Currently offering"
                    labelClassName="flex-align-center medium-text"
                    name="currentlyOfferedAtMyLocation"
                    onChange={(name, value) =>
                      this.filterCourses({ [name]: value })
                    }
                  />
                )}
              </div>
            </div>
            <PaginatedList
              className="all-courses-cards-grid"
              error={error}
              getRecords={this.paginateCourses}
              loaderClass="mt-80"
              loading={loading}
              name="courses"
              page={page}
              records={courses}
              renderRecords={
                !myLocation || currentlyOfferedAtMyLocation
                  ? this.renderCourses // don't categorize courses if the user does not have a JU location or if the list is filtered to currentlyOfferedAtMyLocation
                  : this.renderCategorizedCourses
              }
              resultsView={resultsView}
              totalCount={totalCourses}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withLayout()(withAppInsights(CoursesPage));
