import React from "react";
import Loader from "../../../components/Loader";
import Select from "../../../components/form/Select";
import SelectSearch from "react-select";
import ValidatedInput from "../../../components/ValidatedInput";
import { getYearOptions } from "../../../lib";

export default function CurrentEducationInfo(props) {
  const graduationYears = getYearOptions(2005, new Date().getFullYear() + 15);

  const {
    campuses: {
      loading: campusesLoading,
      error: campusesError,
      data: campuses,
    },
    majors,
    onChangeProfile,
    onChangeProfileEvt,
    profile: {
      campus,
      class: studentClass,
      doubleMajorID,
      graduationYear,
      majorID,
      studentIDNumber,
    } = {},
    showFormValidation,
    studentClasses,
  } = props;

  return (
    <div className="profile-form-section">
      <p className="section-title">Current education</p>
      <div className="profile-form-grid">
        <div className="relative">
          <ValidatedInput
            input={
              <SelectSearch
                classNamePrefix="input-container-select"
                isClearable={true}
                isSearchable={true}
                options={
                  campuses &&
                  campuses
                    .sort((c1, c2) =>
                      c1.name
                        .toLowerCase()
                        .localeCompare(c2.name.toLowerCase()),
                    )
                    .map((ca) => ({
                      label: ca.name,
                      value: ca.id,
                    }))
                }
                placeholder="Select School"
              />
            }
            label="School"
            onChange={(campus) =>
              onChangeProfile(
                "campus",
                campus
                  ? {
                      id: campus.value,
                      name: campus.label,
                    }
                  : null,
              )
            }
            required={true}
            showRequired={showFormValidation}
            value={
              campus && {
                label: campus.name,
                value: campus.id,
              }
            }
          />
          {campusesLoading ? (
            <div className="profile-school-loader">
              <Loader />
            </div>
          ) : (
            !!campusesError && (
              <span className="error-message mt-4 school-error-message">
                We could not retrieve schools information. Please refresh the
                page.
              </span>
            )
          )}
        </div>
        <div className="relative">
          <ValidatedInput
            input={
              <Select
                clearable={true}
                options={studentClasses.map((cl) => ({
                  label: cl.displayValue,
                  value: cl.enumValue,
                }))}
                placeholder="Select Demographic"
                searchable={true}
              />
            }
            label="Demographic"
            name="class"
            onChange={onChangeProfile}
            required={true}
            showRequired={showFormValidation}
            value={studentClass}
          />
        </div>

        <div className="relative">
          <ValidatedInput
            input={
              <Select
                clearable={true}
                options={graduationYears.map((year) => ({
                  value: year,
                  label: year,
                }))}
                placeholder="Select Year"
                searchable={true}
              />
            }
            onChange={onChangeProfile}
            name="graduationYear"
            value={graduationYear}
            label="Graduating Year"
            required={true}
            showRequired={showFormValidation}
          />
        </div>
        <div className="input-container">
          <label>Student ID</label>
          <input
            name="studentIDNumber"
            onChange={onChangeProfileEvt}
            type="text"
            value={studentIDNumber}
          />
        </div>
        <div className="input-container">
          <label>Major</label>
          <Select
            clearable={true}
            name="majorID"
            options={majors.map((major) => ({
              label: major.name,
              value: major.id,
            }))}
            onChange={onChangeProfile}
            placeholder="Select Major"
            searchable={true}
            value={majorID}
          />
        </div>
        <div className="input-container">
          <label>Double Major</label>
          <Select
            clearable={true}
            name="doubleMajorID"
            options={majors.map((major) => ({
              label: major.name,
              value: major.id,
            }))}
            onChange={onChangeProfile}
            placeholder="Select Double Major"
            searchable={true}
            value={doubleMajorID}
          />
        </div>
      </div>
    </div>
  );
}
