import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { AuthActions } from "../../../state";
import AccountPageWrapper from "./AccountPageWrapper";

export default connect(
  function mapState(state) {
    return {
      ...state.auth,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          doSignout: AuthActions.doSignout,
        },
        dispatch,
      ),
    };
  },
)(AccountPageWrapper);
