import { MyJewishUActions } from "./actions";
import { AuthActions } from "../auth/actions";

export const MyJewishUState = {
  name: "myJewishU",
  persist: true, //TODO - confirm we want to persist this data despite the fact that we're retrieving this info on pg load, bec want to make sure we aren't left with gaps...
  defaults: {
    data: {},
    error: undefined,
    loading: false,
    success: undefined,

    enrollment: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    }, //TODO - move myJewishU into defined state obj?
  },
  handlers: {
    [MyJewishUActions.SET_GET_MY_JEWISH_U_LOADING]: function (state, action) {
      return {
        ...state,
        loading: !!action.payload,
      };
    },
    [MyJewishUActions.GET_MY_JEWISH_U_COMPLETE]: function (state, action) {
      return {
        ...state,
        loading: false,
        success: true,
        error: undefined,
        data: action.payload,
      };
    },
    [MyJewishUActions.GET_MY_JEWISH_U_ERROR]: function (state, action) {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response,
        data: {},
      };
    },
    [MyJewishUActions.UPDATE_MY_JEWISH_U]: function (state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },

    [MyJewishUActions.SET_JEWISH_U_ENROLLMENT_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          loading: !!action.payload,
        },
      };
    },
    [MyJewishUActions.JEWISH_U_ENROLLMENT_COMPLETE]: function (state, action) {
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [MyJewishUActions.JEWISH_U_ENROLLMENT_ERROR]: function (state, action) {
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },

    [AuthActions.DO_SIGNOUT]: function (state, action) {
      return MyJewishUState.defaults;
    },
  },
};
