import { AboutArea } from "./about";
import { AccountArea } from "./account";
import { ContactArea } from "./contact";
import { CoursesArea } from "./courses";
import { CreditsArea } from "./credits";
import { GrandTripArea } from "./grandTrip";
import { LocationsArea } from "./locations";
import { MainArea } from "./main";
import { MyJewishUArea } from "./myJewishU";
import { ProfileArea } from "./profile";

export const AppPages = {
  about: AboutArea.pages,
  account: AccountArea.pages,
  contact: ContactArea.pages,
  courses: CoursesArea.pages,
  credits: CreditsArea.pages,
  grandTrip: GrandTripArea.pages,
  locations: LocationsArea.pages,
  main: MainArea.pages,
  myJewishU: MyJewishUArea.pages,
  profile: ProfileArea.pages,
};
export default AppPages;

export const AppArea = {
  areas: [
    AboutArea,
    AccountArea,
    ContactArea,
    CoursesArea,
    CreditsArea,
    GrandTripArea,
    LocationsArea,
    MainArea,
    MyJewishUArea,
    ProfileArea,
  ],
};
